import React, { Component, useState, useEffect } from 'react';
import { Row, Col, InputGroup, Button, Spinner } from 'reactstrap';
import { fetchWebApi } from '../utils/ClientUtil';
import { getExceptionMessage } from '../utils/ExceptionUtil';
import * as T from '../utils/TypeUtil';
import './css/general.css';
import './css/elementTable.css';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import filterFactory, { dateFilter, multiSelectFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTable,
    faSync,
    faPlus,
    faFilterCircleXmark,
    faSave
} from '@fortawesome/free-solid-svg-icons';
import ComponentModal from './ComponentModal';
import { sanitizeJsonObject } from '../user/EntityUtil';
import { EElementTableColFilterType, ElementTablePageOptions, TableColFilterEntry, TableColSortEntry } from './ElementTableUtil';
import { EYesNo } from '../AppEnums';
import { EColor } from '../utils/DataFormatUtil';
import ViewSettingSelector from './ViewSettingSelector';

const { SearchBar, ClearSearchButton } = Search;

const ETableColSort = {
    DESC: 'desc',
    ASC: 'asc'
}

const GetDefaultColDef = (col, validated, getEditable) => {

    return {
        allowFilter: true,
        sort: true,
        editable: false,
        headerAlign: 'center',
        classes: (cell, row, rowIndex, colIndex) => {

            const { dataField, classes: colClasses, editable: canEdit } = col;
            const { errors } = row;
            const editable = canEdit && getEditable();

            if (T.DefaultBool(validated, false) //show validation error
                && editable                     //column is editable
                && T.IsDefined(errors)          //valid error table
            ) {
                if (T.IsDefined(errors[dataField])) {

                    return `${colClasses || ''} invalid-cell`;
                }
            }
            else if (editable) {
                return 'edit-cell';
            }
            return colClasses;
        }
        //headerClasses: 'bg-info',
    };
}

const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
}) => (
    //<ul className="btn-group" role="group">
    <ul className='elementTablePageSizeOptions pagination'>
        {options.map((option) => {
            const isSelect = currSizePerPage === `${option.page}`;
            return (
                <li
                    key={option.text}
                    onClick={() => onSizePerPageChange(option.page)}
                    className={`btn btn-sm elementTablePageSizeItem ${isSelect ? 'active' : ''}`}
                >
                    {option.text}
                </li>
            );
        })}
    </ul>
);
const paginationTotalRenderer = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-2">
        {from} to {to} of {size}
    </span>
);
const defaultPageOptions = {
    page: 1,
    sizePerPage: 5,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true,
    showTotal: true,
    paginationTotalRenderer,
    sizePerPageRenderer,
    sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '20', value: 20 },
        { text: '40', value: 40 },
    ],
};

const CustomToggleList = ({ columns, onColumnToggle: onToggle, toggles: propToggles, onChange }) => {
    const cols = columns.filter(x => !x.isDummyField && !x.alwaysHidden);
    const [toggles, setToggles] = useState({ ...propToggles });

    useEffect(() => {
        if (T.IsFunc(onChange)) {
            onChange(toggles);
        }
    }, [onChange, toggles]);

    const onColumnToggle = (dataField) => {

        const updated = { ...toggles };
        onToggle(dataField);
        updated[dataField] = !updated[dataField];

        setToggles(updated);
    }
    const onToggleAll = (active) => {

        const updated = { ...toggles };
        cols.forEach(column => {
            if (toggles[column.dataField] === active) return;

            onToggle(column.dataField);
            updated[column.dataField] = !updated[column.dataField];
        });
        setToggles(updated);
    }
    const onReset = () => {

        const updated = { ...toggles };
        cols.forEach(column => {
            const visible = toggles[column.dataField];
            if ((column.hidden && visible)
                || !visible) {
                onToggle(column.dataField);
                updated[column.dataField] = !updated[column.dataField];
            }
            setToggles(updated);
        });
    }

    return (
        <Row>
            <Col className="btn-group btn-group-xs btn-group-toggle btn-group-vertical"
                role='group'
                data-toggle="buttons">
                {
                    cols.map(column => ({
                        ...column,
                        toggle: toggles[column.dataField]
                    }))
                        .map(column => (
                            <button key={column.dataField}
                                type='button'
                                className={`btn btn-sm btn-info ${column.toggle ? 'active' : ''}`}
                                data-toggle='button'
                                aria-pressed={column.toggle ? 'true' : 'false'}
                                onClick={() => onColumnToggle(column.dataField)}
                            >
                                {column.text}
                            </button>
                        ))
                }
            </Col>
            <Col className="" >
                <span size='sm' key={'showAll'} className={`btn btn-primary mr-1 me-1`}
                    onClick={() => {
                        onToggleAll(true);
                    }} >Show All</span>
                <span size='sm' key={'hideAll'} className={`btn btn-primary mr-1 me-1`}
                    onClick={() => {
                        onToggleAll(false);
                    }} >Hide All</span>
                <span size='sm' key={'reset'} className={`btn btn-primary`}
                    onClick={() => {
                        onReset();
                    }} >Reset</span>
            </Col>
        </Row>)
};

/**
 * @param {string} name [optional] component name
 * @param columns table column definitions
 * @param buttons [optional] additional button to display on the top left panel
 * @param {ElementTablePageOptions} pageOptions [optional] 
 * @param {false} pagingOff [optional] whether to enable pagination
 * @param defaultSorted [optional] 
 * @param globalFilter [optional]
 * @param {Array<TableColFilterEntry>} defaultFilters [optional]
 * @param onNewIcon [optional]
 * @param onNew [optional] raise when button to create new entry is clicked
 * @param onLoadTableData [optional] raise when fetch all table data,
 * used when onFormatUrl is not set
 * @param onFormatUrl [optional] raise to get the URL to use for remote pagination
 * @param onNormalizeFetchData [optional] raise to normalize server data received via onFormatUrl
 * @param {bool} getOnly [optional] Default is 'POST', used in conjunction remote pagination
 * @param onPageLoaded [optional] raise when remote pagination is completed
 */
export class ElementTable extends Component {
    constructor(props) {
        super(props);

        this.refModal = undefined;
        this.refModalError = undefined;
        this.refColFilters = {};
        this.colIdx = 0;

        this.state = {
            loading: false,
            loadError: undefined,
            colFilters: this.props.defaultFilters,
            colSorts: this.fromColSortEntry(this.props.defaultSorted),
            tableCols: [],
            pageOptions: this.getTablePageOptions(this.props.pageOptions),
        };
        this.name = this.props.name || 'tableElement';
        this.getTablePageOptions = this.getTablePageOptions.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);
        this.handleViewColumns = this.handleViewColumns.bind(this);
        this.loadTablePageData = this.loadTablePageData.bind(this);
        this.resetTableFilter = this.resetTableFilter.bind(this);
        this.reloadTable = this.reloadTable.bind(this);
        this.getDefaultColFilterDef = this.getDefaultColFilterDef.bind(this);
        this.handleSaveSettings = this.handleSaveSettings.bind(this);
        this.getTableEditable = this.getTableEditable.bind(this);

        if (T.IsFunc(props.link)) { props.link(this); }
    }

    getTableEditable() {
        return !this.props.disabled;
    }

    componentDidMount() {
        const { columns, validated, disableLoadOnInit } = this.props;

        let iCol = 0;
        const createCellPrefix = () => {
            return `${this.name}_${iCol++}-`
        };

        const cols = columns.map((col) => {
            return {
                ...GetDefaultColDef(col, validated, this.getTableEditable),
                ...col,
                ...this.getDefaultColFilterDef(col),
                ...{
                    formatExtraData: createCellPrefix()
                }
            };
        });
        this.setState({ tableCols: cols }, () => {
            if (!T.DefaultBool(disableLoadOnInit, false)) {
                this.reloadTable();
            }
        });
    }

    getDefaultColFilterDef = (col) => {
        const { filter: filterType, dataField } = col;
        let filter = undefined;

        const getFilter = (f) => {
            this.refColFilters[dataField] = {
                filterType,
                filter: f
            };
        };
        const id = `${this.name}_col-${this.colIdx++}`;
        const placeholder = 'Enter search text';

        if (T.IsDefined(filterType)) {
            switch (filterType) {
                case EElementTableColFilterType.TEXT:
                    filter = textFilter({ getFilter, id, placeholder })
                    break;
                case EElementTableColFilterType.SELECT:
                    filter = selectFilter({ ...{ getFilter, id, placeholder }, ...col.filterOptions });
                    break;
                case EElementTableColFilterType.MULTI_SELECT:
                    filter = multiSelectFilter({ ...{ getFilter, id, placeholder }, ...col.filterOptions });
                    break;
                case EElementTableColFilterType.DATE:
                    filter = dateFilter({ getFilter, id, placeholder });
                    break;
                default:
                    break;
            }
        }

        return filter ? { filter } : {};
    }

    reloadTable() {
        const { onLoadTableData, onFormatUrl } = this.props;
        if (T.IsFunc(onLoadTableData)) {

            this.props.onLoadTableData();
        }
        else if (T.IsFunc(onFormatUrl)) {
            const { searchText, colSorts, colFilters } = this.state;
            const { pageOptions } = this.state;
            const { page, sizePerPage } = pageOptions;

            this.loadTablePageData(page, sizePerPage, searchText, colSorts, colFilters);
        }
        else {
            console.log(this.name + " onLoadTableData/onFormatUrl NOT FOUND");
        }
    }

    resetTableFilter(done) {
        const { colFilters } = this.state;
        if (T.IsArrayNonEmpty(colFilters)) {

            const updatedFilters = this.props.defaultFilters;
            const { searchText, colSorts } = this.state;
            const { pageOptions } = this.state;
            const { page, sizePerPage } = pageOptions;

            colFilters.forEach((col) => {

                const refFilter = this.refColFilters[col.dataField];
                if (!refFilter) return;

                switch (refFilter.filterType) {
                    case EElementTableColFilterType.MULTI_SELECT:
                        refFilter.filter([]);
                        break;
                    default:
                        refFilter.filter('');
                        break;
                }
            });

            this.setState({ colFilters: [] }, () => {
                this.loadTablePageData(page, sizePerPage, searchText, colSorts, updatedFilters);
                if (T.IsFunc(done)) {
                    done();
                }
            });
        }
    }

    toColFilterEntry(items) {
        const result = T.IsArrayNonEmpty(items)
            ? items.map((x) => ({
                dataField: x.dataField,
                text: x.text
            }))
            : undefined;
        return result;
    }
    fromColFilterEntry(items) {
        const result = T.IsArrayNonEmpty(items)
            ? items.map((x) => new TableColFilterEntry(x.dataField, x.text))
            : undefined;
        return result;
    }

    /**
     * Convert table-specific sorts to list of TableColSortEntry
     * @param {*} items 
     * @returns array of TableColSortEntry or undefined if no sorts
     */
    toColSortEntry(items) {
        const result = T.IsArrayNonEmpty(items)
            ? items.map((x) => new TableColSortEntry(x.dataField, x.order === ETableColSort.DESC))
            : undefined;
        return result;
    }
    /**
     * Convert list of TableColSortEntry to table-specific sorts
     * @param {*} items 
     * @returns array of table-specific sorts
     */
    fromColSortEntry(items) {
        const result = T.IsArrayNonEmpty(items)
            ? items.map((x) => ({
                dataField: x.dataField,
                order: x.desc ? ETableColSort.DESC : ETableColSort.ASC
            }))
            : undefined;
        return result;
    }

    /**
     * @param {ElementTablePageOptions} changedOptions
     */
    getTablePageOptions(changedOptions) {

        const { pagingOff } = this.props;
        if (T.DefaultBool(pagingOff, false)) return {};

        return {
            ...defaultPageOptions,
            ...(changedOptions || {}),
        };
    }

    /**
     * Method to handle the manual fetching of data from the server when in 'lazy' mode.
     */
    handleTableChange(type, params) {
        console.log(`${this.name}[${type}]`, params);

        const {
            sortField, sortOrder,
            page, sizePerPage,
            searchText,
            filters
        } = params || {};
        const curSorts = this.state.colSorts || [];

        const pageSorts = T.IsDefined(sortField)
            ? curSorts.filter(x => x.dataField !== sortField)
            : curSorts;
        if (T.IsDefined(sortField)) {
            pageSorts.splice(0, 0, { dataField: sortField, order: sortOrder });
        }

        const pageFilters = [];
        const filterProps = Object.keys(filters || {});
        if (T.IsArrayNonEmpty(filterProps)) {
            for (const field of filterProps) {
                const item = filters[field];
                pageFilters.push({ dataField: field, text: item.filterVal });
            }
        }
        this.loadTablePageData(page, sizePerPage, searchText, pageSorts, pageFilters);
    }

    /**
     * Load data from server
     */
    loadTablePageData(page, pageSize, searchText, sorts, filters) {
        console.log(`loadTablePageData(${this.name})...`);
        const {
            showDeletedItems,
            getOnly,
            onFormatUrl,
            onNormalizeFetchData,
            onPageLoaded
        } = this.props;

        const colFilters = T.IsArray(filters) ? filters : [];

        const includeDeletedItems = T.DefaultBool(showDeletedItems, false);
        if (!includeDeletedItems) {
            colFilters.splice(0,
                0,
                {
                    dataField: 'IsDeleted',
                    text: EYesNo.NO
                });
        }

        const postData = {
            filters: this.fromColFilterEntry(colFilters),
            sorts: this.toColSortEntry(sorts)
        };
        const url = onFormatUrl(page, pageSize, searchText, postData);

        const body = {};
        if (!T.DefaultBool(getOnly, false)) {
            body.method = 'POST';
            body.body = JSON.stringify(postData);
        }

        const newState = {
            loading: true,
            loadError: undefined,
            searchText,
            colSorts: sorts,
            colFilters: postData.filters,
        }
        this.setState(newState, () => {
            fetchWebApi(url, body, { 'Content-Type': 'application/json' })
                .then(result => {
                    sanitizeJsonObject(result);

                    const { totalPage, totalSize, data, metadata } = result;
                    const tableData = T.IsFunc(onNormalizeFetchData) ? onNormalizeFetchData(data, metadata) : data;
                    this.setState({
                        loading: false,
                        pageOptions: new ElementTablePageOptions(true, pageSize, page, totalPage, totalSize),
                        data: tableData
                    }, onPageLoaded);
                })
                .catch(error => {
                    console.error('ElementTable.handleFetchData error: ', url, error);

                    const msg = 'An error prevented the requested data from being loaded.';
                    getExceptionMessage(msg,
                        error,
                        (errorTxt) => {
                            this.setState({
                                loading: false,
                                loadError: errorTxt,
                            }, onPageLoaded);
                        }
                    );
                });
        });
    }

    handleViewColumns(columnToggleProps) {
        if (!this.refModal) return;

        let toggles = { ...columnToggleProps.toggles };
        this.refModal.ComponentDialog('Table Columns',
            <CustomToggleList {...columnToggleProps}
                onChange={(updated) => toggles = updated}
            />,
            () => { // OK
                this.refModal.close();
                // this.setState({ columnVisibilityStatus: toggles });
            },
            false,      // Cancel
            '',         // Apply label
            'Close',    // OK label
            ''          // Cancel label
        );
    }

    handleSaveSettings() {
        const { colSorts, colFilters } = this.state;
        var settings = {
            filters: colFilters,
            sorts: this.toColSortEntry(colSorts),
        };
        return settings;
    }

    renderLeftControlPanel(columnToggleProps) {
        const {
            disabled,
            onNew, onNewIcon,
            buttons,
            onLoadTableData,
            onFormatUrl,
        } = this.props;
        return (
            <div className='btn-group'>
                <Button className="d-inline-block mr-1 me-1"
                    onClick={() => { this.handleViewColumns(columnToggleProps) }}
                    size="sm" title="Show/hide columns" color={EColor.Primary}
                >
                    <FontAwesomeIcon icon={faTable} />
                </Button>
                <Button className="d-inline-block mr-1 me-1"
                    onClick={() => {
                        this.resetTableFilter();
                    }}
                    size="sm"
                    title="Reset all filter"
                    color={EColor.Primary}
                >
                    <FontAwesomeIcon icon={faFilterCircleXmark} />
                </Button>
                {
                    (T.IsFunc(onFormatUrl) || T.IsFunc(onLoadTableData)) &&
                    <Button className="d-inline-block mr-1 me-1"
                        onClick={() => {
                            this.reloadTable();
                        }}
                        size="sm"
                        title="Reload table dataa"
                        color={EColor.Primary}
                    >
                        <FontAwesomeIcon icon={faSync} />
                    </Button>
                }
                {
                    T.IsFunc(onNew) && !disabled &&
                    <Button className="d-inline-block mr-1 me-1"
                        onClick={() => onNew()}
                        size="sm"
                        title="Add new table entry"
                        color={EColor.Primary}
                    >
                        <FontAwesomeIcon icon={T.IsDefined(onNewIcon) ? onNewIcon : faPlus} />
                    </Button>
                }
                {buttons}
            </div>
        );
    }

    renderSearch(props) {
        return (
            <InputGroup>
                <SearchBar {...props} tableId={`${this.name}_search`} />
                <ClearSearchButton className='elemenTableClearSearchBtn btn-primary btn-sm'
                    {...props}
                />
            </InputGroup>
        );
    }


    render() {
        const {
            keyField, data, defaultSorted,
            selectRowOptions, pagingOff, cellEditOptions,
            headerSection,
            canSaveFilter,
            validated
        } = this.props;
        const {
            loading,
            loadError,
            pageOptions,
            data: fetchData,
            tableCols,
            columnVisibilityStatus
        } = this.state;

        if (!T.IsArrayNonEmpty(tableCols)) {
            return <Spinner />;
        }

        const tablePageOptions = this.getTablePageOptions(pageOptions);

        const cellEdit = T.IsDefined(cellEditOptions) ? cellEditFactory(cellEditOptions) : undefined;

        const tableProps = {
            remote: T.DefaultBool(tablePageOptions.remote, false),
            keyField,
            key: `${this.name}_bootstrapTable`,
            defaultSorted: this.fromColSortEntry(defaultSorted),
            striped: true,
            hover: true,
            condensed: true,
            // bootstrap4: true,
            // ref: "table",
            // data,
            // columns: tableCols,
            headerClasses: 'table-primary',
            selectRow: selectRowOptions,
            filter: filterFactory(),
            cellEdit: cellEdit,
            noDataIndication: () => {
                if (loading) {
                    return (
                        <React.Fragment>
                            <Spinner size={3} />
                            <span className='text-primary'>Loading...</span>
                        </React.Fragment>
                    );
                }
                if (loadError) return <span className='text-danger'>{loadError}</span>;
                return <span className='text-primary'>No elements found</span>;
            },
            pagination: pagingOff ? undefined : paginationFactory(tablePageOptions),
            onTableChange: this.handleTableChange
        };

        return (
            <ToolkitProvider
                bootstrap4={true}
                keyField={keyField}
                key={`${this.name}_bootstrapTableToolkit`}
                data={fetchData || data || []}
                columns={tableCols}
                search
                columnToggle
            >
                {
                    tkProps => {
                        const myProps = { ...tableProps, ...tkProps.baseProps };
                        return (
                            <React.Fragment>
                                <ComponentModal link={(e) => this.refModal = e} />
                                <Row>
                                    <Col className='col-sm-3'>
                                        {this.renderLeftControlPanel(tkProps.columnToggleProps)}
                                    </Col>
                                    <Col className='col-sm-6'>
                                        {
                                            headerSection &&
                                            <React.Fragment>{headerSection}</React.Fragment>
                                        }
                                    </Col>
                                    <Col className='col-sm-3'>
                                        <Row>
                                            <Col>{this.renderSearch(tkProps.searchProps)}</Col>
                                        </Row>
                                        {
                                            canSaveFilter &&
                                            <Row>
                                                <Col>
                                                    <ViewSettingSelector name={this.name}
                                                        onSaveSettings={() => this.handleSaveSettings()}
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                </Row>
                                <BootstrapTable {...myProps}
                                ></BootstrapTable>
                            </React.Fragment>
                        );
                    }
                }
            </ToolkitProvider>
        );
    }
}
