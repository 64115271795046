import React from 'react';
import { Input } from 'reactstrap';
import * as T from '../utils/TypeUtil';
import './css/general.css';
import './css/elementTable.css';

import { Type as EditorType } from 'react-bootstrap-table2-editor';

import {
    faCopy
} from '@fortawesome/free-solid-svg-icons';
import { EYesNo } from '../AppEnums';
import { FormatDate, FormatDateTime, GetYear } from '../utils/DataFormatUtil';
import { renderButton } from '../utils/EditorInputUtil';
import { EPolicyFields } from '../user/EntityData';
import { INVALID_BIRTH_YEAR } from '../AppConstants';

export const EElementTableColFilterType = {
    TEXT: 'Text',
    SELECT: 'Select',
    MULTI_SELECT: 'Select',
    DATE: 'Date'
}

export class TableColFilterEntry {
    /**
     * @param {string} dataField name of the prop
     * @param {string} text search text
     */
    constructor(dataField, text) {
        this.dataField = dataField;
        this.text = text;
    }
}

export class TableColSortEntry {
    /**
     * @param {string} dataField name of the prop
     * @param {bool} desc sort order
     */
    constructor(dataField, desc = false) {
        this.dataField = dataField;
        this.desc = desc;
    }
}

export const FormatElementTableCellBoolToYesNo = (cellVal, row) => {
    if (cellVal === true) {
        const styles = { fontWeight: 'bold' };
        return <span style={styles}>{EYesNo.YES}</span>;
    }
    return EYesNo.NO;
}

export const FormatElementTableCellDate = (cellVal, row) => {
    return FormatDate(cellVal);
}

export const FormatElementTableCellDateEffective = (cellVal, row) => {
    const yy = GetYear(cellVal);
    const thisYear = new Date().getFullYear();
    if (yy == thisYear) {
        return <span className='text text-success' style={{ fontWeight: 'bold' }}>{FormatDate(cellVal)}</span>;
    } else if (yy == thisYear + 1) {
        return <span className='text text-primary' style={{ fontWeight: 'bold' }}>{FormatDate(cellVal)}</span>;
    }
    return FormatDate(cellVal);
}

export const FormatElementTableCellDateDob = (cellVal, row) => {
    const yy = GetYear(cellVal);
    if (yy == INVALID_BIRTH_YEAR) {
        return <span className='text text-secondary' style={{ fontStyle: 'italic' }}>{yy}</span>;
    }

    const id = row.Id || row.id;
    const sDate = FormatDate(cellVal);
    return (
        <div>
            <span>{yy}</span>
            {
                cellVal && renderButton(`${id}-btnDobCopy`, `Copy: ${sDate}`,
                    () => { navigator.clipboard.writeText(sDate); },
                    false, 'mr-2 me-2', faCopy)
            }
        </div>
    );
}

export const FormatElementTableCellSsn = (rowId, cellVal, plainCellVal) => {
    let val = plainCellVal;
    if (T.IsDefined(val)) {
        val = val.replaceAll('#', '');
        val = val.replaceAll('-', '');
        val = val.trim()
    }
    return (
        <div>
            <span>{cellVal}</span>
            {
                val && renderButton(`${rowId}-btnSsnCopy`, 'Copy',
                    () => { navigator.clipboard.writeText(T.IsDefined(val) ? val : ''); },
                    false, 'mr-2 me-2', faCopy)
            }
        </div>
    );
}

export const FormatElementTableCellButton = (row, cellVal, name, onClick, icon = undefined) => {
    const val = T.IsFunc(cellVal) ? cellVal() : cellVal;
    if (!T.IsFunc(onClick)) {
        return val;
    }
    const id = row.Id || row.id;
    return (
        <div>
            {
                renderButton(`${id}-btn${name}`, name, () => onClick(row), false, 'mr-2 me-2', icon)
            }
            <span>{val}</span>
        </div>
    );
}

export const FormatElementTableCellDateTime = (cellVal, row) => {
    return FormatDateTime(cellVal);
}

export const ElementTableCellSelectEditor = (options) => {
    const editor = ToElementTableSelectOptions(options);
    editor.type = EditorType.SELECT;
    return editor;
}

export const FormatElementTableCellTextArea = (cellVal, row, rowIndex, formatExtraData) => {
    const val = T.IsArrayNonEmpty(cellVal)
        ? cellVal.join('\n')
        : cellVal || '';
    const tokens = T.IsArrayNonEmpty(cellVal) ? cellVal : val.split('\n');
    const lineCnt = tokens.length < 5 ? tokens.length : 5;

    const keyPrefix = formatExtraData ? `${formatExtraData}_` : '';
    const sId = `${keyPrefix}row${rowIndex}-textarea`;
    return <Input
        value={val}
        type="textarea"
        id={sId}
        key={sId}
        rows={lineCnt}
        readOnly
    />;
}

export const ElementTableYesNoEditor = {
    type: EditorType.CHECKBOX,
    value: `${EYesNo.YES}:${EYesNo.NO}`
}

export const ElementTableCellDateEditor = {
    type: EditorType.DATE
}

export const ToElementTableSelectOptions = (options) => {
    return {
        options: (options || []).map(x => {
            return { value: x.Id, label: x.Name }
        })
    };
}

export class ElementTablePageOptions {

    constructor(remote, pageSize, page, totalPage, totalSize) {
        this.remote = T.DefaultBool(remote, false);
        this.page = page || 1;
        this.sizePerPage = pageSize || 10;
        this.totalPage = totalPage;
        this.totalSize = totalSize;
    }
}
