import React, { Component } from 'react';
import { ElementTable } from '../general/ElementTable';
import {
    FormatElementTableCellDateTime, ElementTablePageOptions,
    FormatElementTableCellButton, FormatElementTableCellBoolToYesNo,
    EElementTableColFilterType,
    ToElementTableSelectOptions,
    TableColSortEntry
} from '../general/ElementTableUtil';
import ComponentModal from '../general/ComponentModal';
import * as T from '../utils/TypeUtil';

import { EAppUserField, EAppUserFieldLabel } from './EntityData';
import '../general/css/general.css';
import { fetchCurrentUser } from '../utils/ClientUtil';
import { URL_USER } from '../AppConstants';
import BootAlert from '../general/BootAlert';
import { InputSecret, InputSelect } from '../utils/EditorInputUtil';
import { faEdit, faKey, faPencil } from '@fortawesome/free-solid-svg-icons';
import { fetchUserRole, saveUserPwd, saveUserRole } from './EntityUtil';
import { ERole, ERoleOptions } from '../AppEnums';
import { displayAppUserEditorDialog, renderOptionsCell } from './EntityEditorUtil';
import authService from '../api-authorization/AuthorizeService';
import { v4 as uuid } from 'uuid';
import { FormatPhone } from '../utils/DataFormatUtil';

/**
 * @param name [optional] component name
 * @param disableLoadOnInit [optional]
 * @param sizePerPage [optional] number of rows per page
 */
export class AppUserTable extends Component {
    constructor(props) {
        super(props);

        this.mount = false;
        this.refModal = undefined;
        this.refModalLg = undefined;
        this.refAlert = undefined;

        this.handleFormatUrl = this.handleFormatUrl.bind(this);

        this.handleBtnSaClicked = this.handleBtnSaClicked.bind(this);
        this.handleBtnEditRoleClick = this.handleBtnEditRoleClick.bind(this);
        this.handleBtnNewEditClick = this.handleBtnNewEditClick.bind(this);
        this.handleBtnKeyClick = this.handleBtnKeyClick.bind(this);

        this.formatTableCellRole = this.formatTableCellRole.bind(this);
        this.renderRowOptions = this.renderRowOptions.bind(this);

        const { link, name, sizePerPage } = this.props;
        this.name = name || 'tableUser';

        this.state = {
            data: [],
            roleOptions: [],
            pageOptions: new ElementTablePageOptions(false, sizePerPage),
            defaultSorted: [
                new TableColSortEntry(EAppUserField.UserName)
            ],
        };

        if (T.IsFunc(link)) {
            link(this);
        }
    }

    reloadTable() {
        this.refTable.reloadTable();
    }

    async populateUserInfo() {
        try {
            const user = await fetchCurrentUser();
            this.setState(user, () => {
                const { loginUserId: id } = user;
                if (id) {
                    fetchUserRole(id, (role, error) => {
                        let options = [];
                        if (role) {
                            const idx = ERoleOptions.findIndex((x) => x.Id === role);
                            if (idx > 0) {
                                options = ERoleOptions.slice(0, idx);
                            }
                        }
                        this.setState({
                            loginUserRole: role,
                            roleOptions: options
                        });
                    });
                }
            });
        }
        catch (error) {
            console.log('populateUserInfo ERROR', error);
            this.setState({
                user: undefined,
                loginUserRole: undefined,
                roleOptions: []
            });
        };
    }

    componentDidMount() {
        this.mount = true;
        this._subscription = authService.subscribe(() => this.populateUserInfo());
        this.populateUserInfo();
    }
    componentWillUnmount() {
        this.mount = false;
        authService.unsubscribe(this._subscription);
    }
    componentDidUpdate(prevProps, prevState) {
        const { parentId, parentType, sizePerPage } = this.props;

        if (prevProps.sizePerPage !== sizePerPage) {
            const { pageOptions } = this.state;
            const updatedPageOptions = {
                ...pageOptions,
                ...{ sizePerPage: sizePerPage }
            };
            this.setState({ pageOptions: updatedPageOptions });
        }
        else if (parentId !== prevProps.parentId || parentType !== prevProps.parentType) {
            this.reloadTable();
        }
    }


    handleFormatUrl(page, pageSize, searchText, postData) {
        this.refAlert.hide();

        const urlParams = [
            `page=${page}`,
            `pageSize=${pageSize}`,
            `filter=${searchText || ''}`,
        ];
        return `${URL_USER}/paged?${urlParams.join('&')}`;
    }

    getColumns() {
        const cols = [
            {
                text: EAppUserFieldLabel.Id,
                dataField: EAppUserField.Id,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EAppUserFieldLabel.UserName,
                dataField: EAppUserField.UserName,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EAppUserFieldLabel.Name,
                dataField: EAppUserField.Name,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EAppUserFieldLabel.Role,
                dataField: EAppUserField.Role,
                formatter: this.formatTableCellRole,
                filter: EElementTableColFilterType.SELECT, filterOptions: ToElementTableSelectOptions(ERoleOptions),
            },
            {
                text: EAppUserFieldLabel.NPN,
                dataField: EAppUserField.NPN,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EAppUserFieldLabel.Email,
                dataField: EAppUserField.Email,
                headerStyle: { width: '30%' },
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EAppUserFieldLabel.PhoneNumber,
                dataField: EAppUserField.PhoneNumber,
                formatter: (cellVal, row) => FormatPhone(cellVal),
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EAppUserFieldLabel.EmailConfirmed,
                dataField: EAppUserField.EmailConfirmed,
                formatter: FormatElementTableCellBoolToYesNo,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EAppUserFieldLabel.LockoutEnd,
                dataField: EAppUserField.LockoutEnd,
                hidden: true,
                formatter: FormatElementTableCellDateTime,
            },
        ];

        cols.push(
            {
                text: '',
                dataField: 'options',
                isDummyField: true,
                editable: false,
                sort: false,
                allowFilter: false,
                formatter: (cellVal, row) => {
                    return this.renderRowOptions(row);
                },
            });
        return cols;
    }

    formatTableCellRole(cellVal, row) {
        const { roleOptions } = this.state;
        if (T.IsArrayNonEmpty(roleOptions)
            && roleOptions.find((x) => x.Id === cellVal)) {
            return FormatElementTableCellButton(row, cellVal, 'Edit', this.handleBtnEditRoleClick, faPencil);
        }
        return <span key={`${row[EAppUserField.Id]}-roleReadOnly`}>{cellVal}</span>;
    }

    handleBtnSaClicked() {

        let dlgModal;
        let sVal = '';
        this.refModal.ComponentDialog('Secret',
            <React.Fragment>
                <ComponentModal link={(e) => dlgModal = e} />
                <InputSecret
                    key={`${this.name}-input`}
                    required
                    maxLength={32}
                    onInputChange={(newVal) => {
                        sVal = newVal;
                    }}
                />
            </React.Fragment>,
            () => { //ok
                if (T.stringIsNullOrEmpty(sVal)) {
                    dlgModal.Error('Please enter a valid secret');
                    return;
                }
                dlgModal.Loading('Executing...');

                saveUserRole(dlgModal, undefined, ERole.Superuser, sVal,
                    (hasError) => {
                        if (!hasError) {
                            this.refModal.close();
                        }
                    }
                );
            },
            () => { //cancel
                this.refModal.close();
            });
    }

    handleBtnEditRoleClick(element) {
        const eId = element[EAppUserField.Id];
        const eName = element[EAppUserField.UserName];
        const eRole = element[EAppUserField.Role];

        let dlgModal;
        let selectedRole = eRole;
        this.refModal.ComponentDialog(`Select new user role [${eName}]`,
            <React.Fragment>
                <ComponentModal link={(e) => dlgModal = e} />
                <InputSelect
                    name={`${this.name}-input`}
                    value={selectedRole}
                    options={this.state.roleOptions}
                    onInputChange={(newVal) => {
                        selectedRole = newVal;
                    }}
                />
            </React.Fragment>,
            () => { //ok
                if (T.stringIsNullOrEmpty(selectedRole)) {
                    dlgModal.Error('Please enter a valid role');
                    return;
                }
                if (selectedRole === eRole) {
                    dlgModal.Error('Please enter a different role');
                    return;
                }
                dlgModal.Loading('Executing...');

                saveUserRole(dlgModal, eId, selectedRole, undefined,
                    (hasError) => {
                        if (!hasError) {
                            this.refModal.close();
                            this.refTable.reloadTable();
                        }
                    }
                );
            },
            () => { //cancel
                this.refModal.close();
            });
    }

    handleBtnNewEditClick(element) {

        const { onElementChanged } = this.props;
        const isNew = !T.IsDefined(element);
        const eId = isNew ? undefined : element[EAppUserField.Id];

        displayAppUserEditorDialog(this.refModalLg, eId,
            (changed) => {
                if (!changed) return;

                //reload the table in case changes affect sorting/filter
                this.refTable.reloadTable();

                if (T.IsDefined(onElementChanged)) {
                    onElementChanged(changed);
                }
            }
        );
    }

    handleBtnKeyClick(element) {

        const eId = element[EAppUserField.Id];
        const eUsername = element[EAppUserField.UserName];

        let dlgModal;
        let sVal = '';
        this.refModal.ComponentDialog(`Set user password [${eUsername}]`,
            <React.Fragment>
                <ComponentModal link={(e) => dlgModal = e} />
                <InputSecret
                    key={`${this.name}-input`}
                    required
                    maxLength={32}
                    onInputChange={(newVal) => {
                        sVal = newVal;
                    }}
                />
            </React.Fragment>,
            () => { //ok
                if (T.stringIsNullOrEmpty(sVal)) {
                    dlgModal.Error('Password cannot be empty');
                    return;
                }
                dlgModal.Loading('Saving...');

                saveUserPwd(dlgModal, eId, sVal,
                    (hasError) => {
                        if (!hasError) {
                            this.refModal.close();
                        }
                    }
                );
            },
            () => { //cancel
                this.refModal.close();
            });
    }

    renderRowOptions(element) {

        const eId = element[EAppUserField.Id];

        const options = [
            {
                name: 'Edit',
                onClick: () => this.handleBtnNewEditClick(element),
                faIcon: faEdit
            },
            {
                name: 'Edit password',
                onClick: () => this.handleBtnKeyClick(element),
                faIcon: faKey
            },
        ];
        return renderOptionsCell(`${this.name}-${eId}`, options);
    }

    render() {
        const {
            pageOptions, defaultSorted, data
        } = this.state;
        const sId = `${this.name}_eTable`;

        const buttons = [
            // renderButton(`${this.name}-sa`, 'sa',
            //     this.handleBtnSaClicked,
            //     false,
            //     'd-inline-block mr-1 me-1', faUserGear)
        ];

        return (
            <React.Fragment>
                <ComponentModal link={e => this.refModalLg = e} size='lg' />
                <ComponentModal link={e => this.refModal = e} />
                <BootAlert canToggle={false} link={(e) => this.refAlert = e} />
                <span>{this.name}</span>
                <ElementTable
                    keyField="Id"
                    id={sId}
                    key={sId}
                    name={sId}
                    data={data}
                    disableLoadOnInit={this.props.disableLoadOnInit}
                    link={(c) => { this.refTable = c; }}
                    columns={this.getColumns()}
                    options={{ noDataText: 'No elements found' }}
                    pageOptions={pageOptions}
                    defaultSorted={defaultSorted}
                    onFormatUrl={this.handleFormatUrl}
                    onNormalizeFetchData={(rows, metadata) => {

                        rows.forEach((x) => {
                            x._uid = uuid();
                        });
                        return rows;
                    }}
                    buttons={buttons}
                    onNew={this.handleBtnNewEditClick}
                ></ElementTable>
            </React.Fragment>
        );
    }
}
