import React, { Component } from 'react';
import { ElementTable } from '../general/ElementTable';
import {
    FormatElementTableCellBoolToYesNo, FormatElementTableCellDateTime,
    ElementTablePageOptions,
    ToElementTableSelectOptions,
    FormatElementTableCellDate,
    EElementTableColFilterType,
    TableColSortEntry
} from '../general/ElementTableUtil';
import ComponentModal from '../general/ComponentModal';
import * as T from '../utils/TypeUtil';

import { ETaskFields, ETaskFieldLabels, NewTask } from './EntityData';
import { renderOptionsCell, displayTaskEditorDialog } from './EntityEditorUtil';
import '../general/css/general.css';

import { faCheck, faEdit, faEye, faTrash, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { URL_PERSON, URL_POLICY, URL_TASK } from '../AppConstants';
import BootAlert from '../general/BootAlert';
import { EYesNo, EYesNoOptions } from '../AppEnums';
import { saveTask } from './EntityUtil';
import { buildInputCheckBoxInlineRaw, buildInputLabel, InputSelect } from '../utils/EditorInputUtil';
import { Col, Row } from 'reactstrap';
import { fetchAssigneeOptions } from './TaskEditor';
import { ETextEditorMode, TextEditor } from '../general/TextEditor';

/**
 * applicable when displaying tasks created by or assigned to an individual
 * @param policyId [optional] ID of the displaying policy,
 * applicable when display tasks related to a policy
 * @param userId [optional] ID of the displaying user
 * applicable when display tasks related to a policy or user
 * @param name [optional] component name
 * @param canGoToPolicy [optional] whether the user can go to policy when view task details. Default is true
 * @param sizePerPage [optional] number of rows per page
 * @param onElementChanged [optional] raises when row data has changed
 * @param onPersonChanged [optional] raises when person data has changed
 * @param canDelete [optional] whether user can delete a row in the table
 * @param selectRowOptions [optional]
 */
export class TaskTable extends Component {
    constructor(props) {
        super(props);

        this.mount = false;
        this.refModal = undefined;
        this.refModalError = undefined;
        this.refAlert = undefined;
        this.refTable = undefined;

        this.renderOptions = this.renderOptions.bind(this);
        this.handleFormatUrl = this.handleFormatUrl.bind(this);
        this.handleNewEditButtonClick = this.handleNewEditButtonClick.bind(this);
        this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
        this.handleTransitionStateRequest = this.handleTransitionStateRequest.bind(this);
        this.handleAssignUserRequest = this.handleAssignUserRequest.bind(this);
        this.handleBeforeSaveTableCell = this.handleBeforeSaveTableCell.bind(this);
        this.renderHeaderSection = this.renderHeaderSection.bind(this);

        this.cellEditOptions = {
            mode: 'click',
            blurToSave: true,
            beforeSaveCell: this.handleBeforeSaveTableCell
        };
        this.columns = this.getColumns();

        const { name, sizePerPage, assignedToMe, defaultSorted } = this.props;
        this.name = name || 'tableTask';

        this.state = {
            data: [],
            includeCompleted: false,
            assignedToMe: assignedToMe,
            pageOptions: new ElementTablePageOptions(false, sizePerPage),
            defaultSorted: defaultSorted || [
                new TableColSortEntry(ETaskFields.Name)
            ],
        };

        if (T.IsFunc(props.link)) {
            props.link(this);
        }
    }

    reloadTable() {
        this.refTable.reloadTable();
    }

    componentDidMount() {
    }
    componentDidUpdate(prevProps, prevState) {
        const { sizePerPage } = this.props;

        if (prevProps.sizePerPage !== sizePerPage) {
            const { pageOptions } = this.state;
            const updatedPageOptions = {
                ...pageOptions,
                ...{ sizePerPage: sizePerPage }
            };
            this.setState({ pageOptions: updatedPageOptions });
        }
    }

    getColumns() {

        const { policyId, userId } = this.props;
        const hidePolicy = T.IsDefined(policyId);
        const hideUser = T.IsDefined(userId);

        const cols = [
            {
                text: ETaskFieldLabels.Id,
                dataField: ETaskFields.Id,
                hidden: true,
                alwaysHidden: true,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ETaskFieldLabels.Name,
                dataField: ETaskFields.Name,
                filter: EElementTableColFilterType.TEXT,
                headerStyle: { width: '60%' },
            },
            // {
            //     text: ETaskFieldLabels.Note,
            //     dataField: ETaskFields.Note,
            //     filter: EElementTableColFilterType.TEXT,
            // },
            {
                text: ETaskFieldLabels.PolicyName,
                dataField: ETaskFields.PolicyName,
                hidden: hidePolicy,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ETaskFieldLabels.PolicyAcaFfmId,
                dataField: ETaskFields.PolicyAcaFfmId,
                hidden: hidePolicy,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ETaskFieldLabels.TargetUserLastName,
                dataField: ETaskFields.TargetUserLastName,
                hidden: hideUser || hidePolicy,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ETaskFieldLabels.TargetUserFirstName,
                dataField: ETaskFields.TargetUserFirstName,
                hidden: hideUser || hidePolicy,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ETaskFieldLabels.TargetUserMiddleName,
                dataField: ETaskFields.TargetUserMiddleName,
                hidden: hideUser || hidePolicy,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ETaskFieldLabels.TargetUserSuffix,
                dataField: ETaskFields.TargetUserSuffix,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ETaskFieldLabels.TargetUserNickName,
                dataField: ETaskFields.TargetUserNickName,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ETaskFieldLabels.TargetUserDOB,
                dataField: ETaskFields.TargetUserDOB,
                formatter: FormatElementTableCellDate,
                hidden: hideUser || hidePolicy,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ETaskFieldLabels.TargetUserSsn4,
                dataField: ETaskFields.TargetUserSsn4,
                hidden: hideUser || hidePolicy,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ETaskFieldLabels.Assignee,
                dataField: ETaskFields.Assignee,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ETaskFieldLabels.CreatedBy,
                dataField: ETaskFields.CreatedBy,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: ETaskFieldLabels.CreatedOn,
                dataField: ETaskFields.CreatedOn,
                filter: EElementTableColFilterType.TEXT,
                formatter: FormatElementTableCellDateTime
            },
            {
                text: ETaskFieldLabels.IsOpen,
                dataField: ETaskFields.IsOpen,
                filter: EElementTableColFilterType.SELECT, filterOptions: ToElementTableSelectOptions(EYesNoOptions),
                formatter: FormatElementTableCellBoolToYesNo,
                // editor: ElementTableYesNoEditor,
                // editable: true
            },
            {
                text: ETaskFieldLabels.CompletedOn,
                dataField: ETaskFields.CompletedOn,
                filter: EElementTableColFilterType.TEXT,
                formatter: FormatElementTableCellDateTime
            },
            {
                text: '',
                dataField: 'options',
                isDummyField: true,
                sort: false,
                allowFilter: false,
                formatter: (cellVal, row) => {
                    return this.renderOptions(row);
                },
            },
        ];

        return cols;
    }

    handleBeforeSaveTableCell(oldVal, newVal, row, col, done) {

        newVal = T.IsDefined(newVal) ? newVal : undefined;
        if (col.dataField === ETaskFields.IsOpen) {
            newVal = newVal === EYesNo.YES;
        }

        oldVal = T.IsDefined(oldVal) ? oldVal : undefined;
        if (oldVal === newVal) {
            done(false);
            return;
        }

        if (col.dataField === ETaskFields.IsOpen) {
            this.handleTransitionStateRequest(row, newVal, done);
            return;
        }
    }

    handleNewEditButtonClick(e) {

        const { loginUserId, policyId, userId, canGoToPolicy, canGoToContact, onElementChanged } = this.props;
        const isEdit = T.IsDefined(e);

        displayTaskEditorDialog(this.refModal, `${this.name}_editorTask`,
            isEdit ? e.Id : undefined,
            isEdit ? undefined : NewTask(undefined, undefined, policyId, userId, loginUserId),
            canGoToPolicy,
            canGoToContact,
            (updated) => {
                if (!updated) return;

                //reload the table in case changes affect sorting/filter
                this.refTable.reloadTable();

                if (T.IsDefined(onElementChanged)) {
                    onElementChanged(updated, this);
                }
            }
        );
    }

    handleDeleteButtonClick() {
        this.refModal.Alert('Operation is not yet supported');
    }

    handleAssignUserRequest(element) {

        const taskName = element[ETaskFields.Name];
        let assigneeId = element[ETaskFields.AssigneeId];
        let comment = undefined;
        const { onElementChanged } = this.props;

        fetchAssigneeOptions((options, error) => {

            if (T.IsDefined(error)) {

                this.refModalError.Error('Failed to retrieve assignees.', error);
                return;
            }
            options.unshift({ Id: undefined, Name: '' });

            const body = (
                <React.Fragment>
                    {buildInputLabel('Assignee', true)}
                    <div>
                        <InputSelect
                            name={`${this.name}-input`}
                            value={assigneeId || ''}
                            options={options}
                            onInputChange={(newVal) => {
                                assigneeId = newVal;
                            }}
                        />
                    </div>
                    <div>
                        {buildInputLabel('Comment')}
                        <TextEditor label='Comment'
                            mode={ETextEditorMode.TEXTAREA}
                            keyPrefix='modal-input-text'
                            useValueFromInputChanged={true}
                            length={1000}
                            lineCnt={5}
                            onInputChanged={(evt, field, fieldVal) => {
                                comment = fieldVal
                            }}
                        />
                    </div>
                </React.Fragment>
            );
            this.refModal.ComponentDialog(`Assign - ${taskName}`, body,
                () => { //OK
                    // if (!T.IsDefined(assigneeId)) {
                    //     this.refModalError.Error('Assignee cannot be empty.');
                    //     return;
                    // }
                    const e = { ...element };
                    e[ETaskFields.AssigneeId] = assigneeId || undefined;
                    saveTask(this.refModalError, e, comment, (result) => {
                        if (T.IsDefined(result)) {
                            this.refTable.reloadTable();
                            if (T.IsDefined(onElementChanged)) {
                                onElementChanged(e, this);
                            }
                        }
                        this.refModal.close();
                    });
                });
        });
    }

    handleTransitionStateRequest(element, isOpen, callback) {

        const taskName = element[ETaskFields.Name];
        const title = isOpen ? 'Reopen task' : 'Mark task as Completed';
        const { onElementChanged } = this.props;

        let comment;
        this.refModal.InputTextDialog(`${title} - ${taskName}`, '', 'Comment',
            (val) => { //onValueChanged
                comment = val;
            },
            () => { //onOK
                const task = { ...element };
                task[ETaskFields.IsOpen] = isOpen;

                saveTask(this.refModalError, task, comment,
                    (result) => {
                        if (T.IsDefined(result)) {
                            this.refTable.reloadTable();

                            if (T.IsFunc(callback)) callback(true);
                        }
                        else {
                            if (T.IsFunc(callback)) callback(false);
                        }

                        this.refModal.close();
                        if (T.IsDefined(onElementChanged)) {
                            onElementChanged(result, this);
                        }
                    });
            },
            () => {
                this.refModal.close();
                if (T.IsFunc(callback)) callback(false);
            }, //onCancel
            { inputMaxLen: 1000, inputRequired: true, mode: ETextEditorMode.TEXTAREA }
        );
    }

    handleFormatUrl(page, pageSize, searchText, postData) {
        this.refAlert.hide();

        const { policyId, userId } = this.props;
        const { includeCompleted, assignedToMe } = this.state;
        const urlParams = [
            `page=${page}`,
            `pageSize=${pageSize}`,
            `filter=${searchText || ''}`,
            `includeCompleted=${T.DefaultBool(includeCompleted, false)}`,
            `assignedToMe=${T.DefaultBool(assignedToMe, false)}`,
        ];

        const url = T.IsDefined(policyId)
            ? `${URL_POLICY}/${policyId}/tasks`
            : T.IsDefined(userId)
                ? `${URL_PERSON}/${userId}/tasks`
                : URL_TASK;
        return `${url}/paged?${urlParams.join('&')}`;
    }

    renderOptions(element) {
        const { canDelete } = this.props;
        const isOpen = element[ETaskFields.IsOpen];

        const options = [
            {
                name: isOpen ? 'Edit' : 'View',
                onClick: () => this.handleNewEditButtonClick(element),
                faIcon: isOpen ? faEdit : faEye
            },
            {
                name: isOpen ? 'Mark as Completed' : 'Reopen',
                onClick: () => this.handleTransitionStateRequest(element, !isOpen),
                faIcon: isOpen ? faCheck : faEdit
            },
            {
                name: 'Assign To',
                onClick: () => this.handleAssignUserRequest(element),
                faIcon: faUserEdit,
                disabled: !isOpen,
            }
        ];
        if (canDelete) {
            options.push(
                {
                    name: 'Delete',
                    onClick: () => this.handleDeleteButtonClick(element),
                    faIcon: faTrash
                });
        }
        return renderOptionsCell(`${this.name}-${element.Id}`, options);
    }

    renderHeaderSection() {
        const { includeCompleted, assignedToMe } = this.state;
        const sectionPrefix = `${this.name}_tTable_hd`;
        return (
            <React.Fragment>
                <Row>
                    <Col className='col-sm-6'>
                        {
                            buildInputCheckBoxInlineRaw(sectionPrefix,
                                {
                                    name: 'assignedToMe',
                                    label: 'Assigned to me ONLY',
                                    value: assignedToMe
                                },
                                (evt, propName, propVal) => {
                                    this.setState({ assignedToMe: propVal }, this.refTable.reloadTable);
                                })
                        }
                    </Col>
                    <Col className='col-sm-6'>
                        {
                            buildInputCheckBoxInlineRaw(sectionPrefix,
                                {
                                    name: 'includeCompleted',
                                    label: 'Include Completed Tasks',
                                    value: includeCompleted
                                },
                                (evt, propName, propVal) => {
                                    this.setState({ includeCompleted: propVal }, this.refTable.reloadTable);
                                })
                        }
                    </Col>
                </Row>
            </React.Fragment >
        );
    }

    render() {
        const { selectRowOptions } = this.props;
        const { pageOptions, defaultSorted } = this.state;
        const headerSection = this.renderHeaderSection();
        const sId = `${this.name}_eTable`;

        return (
            <React.Fragment>
                <ComponentModal size="xl" link={e => this.refModal = e} />
                <ComponentModal link={e => this.refModalError = e} />
                <BootAlert canToggle={false} link={(e) => this.refAlert = e} />
                <span>{this.name}</span>
                <ElementTable
                    keyField="Id"
                    id={sId}
                    key={sId}
                    name={sId}
                    data={this.state.data}
                    link={(c) => { this.refTable = c; }}
                    columns={this.columns}
                    options={{ noDataText: 'No elements found' }}
                    pageOptions={pageOptions}
                    defaultSorted={defaultSorted}
                    selectRowOptions={selectRowOptions}
                    cellEditOptions={this.cellEditOptions}
                    headerSection={headerSection}
                    onFormatUrl={this.handleFormatUrl}
                    onNew={this.handleNewEditButtonClick}
                ></ElementTable>
            </React.Fragment>
        );
    }
}
