import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { renderOptionsCell } from '../user/EntityEditorUtil';
import * as T from '../utils/TypeUtil';
import { buildInputSelectRaw, renderFetchStatus } from '../utils/EditorInputUtil';
import { displayError } from '../utils/ExceptionUtil';
import { fetchViewSettings, saveViewSettings } from '../user/EntityUtil';
import ComponentModal from './ComponentModal';
import { EColor } from '../utils/DataFormatUtil';
import { v4 as uuid } from 'uuid';

const ViewSettingSelector = forwardRef((props, ref) => {
    const NAME_MAX_LEN = 50;
    const [filterOptions, setFilterOptions] = useState([]);
    const [selectedFilterId, setSelectedFilterId] = useState();
    const refModal = useRef(undefined);
    const [loading, setLoading] = useState(false);
    const [loadError, setLoadError] = useState();
    const { name, onSaveSettings } = props;

    useEffect(() => {
        if (!T.IsDefined(name)) {
            return;
        }
        //load settings
        setLoading(true);

        fetchViewSettings(name, (result, errorMsg) => {
            setLoading(false);
            if (result) {
                const selected = T.IsArrayNonEmpty(result) ? result.find(x => x.IsDefault) : undefined;
                const { Id: selectedId } = selected || {};
                setFilterOptions(result);
                setSelectedFilterId(selectedId);
            } else {
                setLoadError(errorMsg);
            }
        });
    }, []); //run on first render

    const handleBtnSaveClicked = (dlg, isSaveAs, filter) => {
        var item = filter;
        if (!item) {
            var settings = onSaveSettings();
            const { filters, sorts } = settings;

            if (T.IsArrayEmpty(sorts)
                && (T.IsArrayEmpty(filters)
                    || (filters.length == 1 && filters[0].dataField === 'IsDeleted'))) {
                dlg.Alert('Nothing to save');
                return;
            }
            const orig = selectedFilterId && T.IsArrayNonEmpty(filterOptions)
                ? filterOptions.find(x => x.Id === selectedFilterId)
                : undefined;

            var defaultName = '';
            if (T.IsArrayNonEmpty(filters)) {
                const f = filters.filter(x => x.dataField !== "IsDeleted");
                const fields = f.map(x => `${x.dataField}=${x.text}`);
                defaultName = fields.join('&');

                const len = defaultName.length;
                defaultName = defaultName.substring(0, len < NAME_MAX_LEN ? NAME_MAX_LEN : len);
            }

            item = {
                Id: isSaveAs ? uuid() : (selectedFilterId || uuid()),
                Type: name,
                Name: orig
                    ? (isSaveAs ? `Copy of ${orig.Name}` : orig.Name)
                    : defaultName,
                IsDefault: true,
                Data: JSON.stringify(settings),
            };
            if (orig) {
                item = { ...orig, ...item };
            } else {
                item.CreatedById = undefined;
            }
        }

        var dlgTitle = undefined;
        if (!selectedFilterId) {
            dlgTitle = 'Save filter';
        } else if (isSaveAs) {
            dlgTitle = 'Save filter as';
        }

        const postData = () => {
            saveViewSettings(item,
                (result) => {
                    dlg.close();
                },
                (error) => {
                    displayError(dlg, 'Error saving filter settings',
                        error,
                        handleBtnSaveClicked(dlg, isSaveAs, item));
                });

        }
        if (!dlgTitle) {
            postData();
            return;
        }

        //prompt user to enter a name for the filter
        dlg.InputTextDialog(dlgTitle, item.Name, 'Filter name',
            (value) => {
                item.Name = value;
            },
            postData,
            () => { //cancel
                dlg.close();
            },
            { inputMaxLen: NAME_MAX_LEN, inputRequired: true }
        );
    }

    return (
        <div className="form-group input-group mb-2">
            <ComponentModal link={(e) => refModal.current = e} />
            {
                buildInputSelectRaw(`${name}_filters`, selectedFilterId, loadError,
                    { name: 'selectedFilter', title: 'Table Filter' },
                    filterOptions,
                    (evt, field, fieldVal) => {
                        setSelectedFilterId(fieldVal);
                    },
                    false, //disabled
                    true //addInvalidOption
                )
            }
            {
                renderOptionsCell(name, [
                    {
                        name: 'Save',
                        faIcon: faSave,
                        onClick: () => { handleBtnSaveClicked(refModal.current) }
                    },
                    {
                        name: 'Save As',
                        onClick: () => { handleBtnSaveClicked(refModal.current, true) },
                        disabled: !selectedFilterId
                    }
                ])
            }
            {renderFetchStatus(loading, loadError, EColor.Danger)}
        </div>
    );
})

export default ViewSettingSelector;