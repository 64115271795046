import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { fetchUserRole } from './user/EntityUtil';
import BootAlert from './general/BootAlert';
import { EColor } from './utils/DataFormatUtil';
import { getExceptionMessage } from './utils/ExceptionUtil';
import { SESSION_TIME_OUT } from './user/UserDashboard';
import { EFetchError } from './utils/ClientUtil';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
        this.populateState = this.populateState.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        try {
            const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);

            const self = this;
            this.setState({
                isAuthenticated,
                userName: user && user.name
            }, () => {
                if (isAuthenticated && user) {
                    const { sub: id } = user;
                    fetchUserRole(id, (role, error) => {
                        self.setState({
                            fetchError: error === EFetchError.Unauthorized ? SESSION_TIME_OUT : error
                        });
                    });
                } else {
                    self.setState({
                        fetchError: SESSION_TIME_OUT
                    });
                }
            });
        } catch (error) {
            getExceptionMessage('Error fetching user authentication status', error,
                (errorTxt) => {
                    this.setState({
                        isAuthenticated: false,
                        userName: undefined,
                        fetchError: errorTxt
                    });
                }
            )
        }
    }

    render() {
        const { isAuthenticated, userName, fetchError } = this.state;
        let msg = 'Welcome';
        if (isAuthenticated) {
            msg += ` ${userName}`;
        }
        return (
            <React.Fragment>
                <h1>{msg}</h1>
                {
                    fetchError &&
                    <BootAlert key="modal_alert_unauth"
                        canToggle={false}
                        icon="warning"
                        visible={true}
                        message={fetchError}
                        color={EColor.Warning}
                    />
                }
            </React.Fragment>
        );
    }
}
