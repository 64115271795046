import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col, Spinner } from 'reactstrap';
import BootAlert from '../general/BootAlert';
import ComponentModal from '../general/ComponentModal';
import {
    buildInputString, buildInputSelect, buildInputNumeric,
    checkRequired,
    renderButton,
    buildInputDate,
    buildInputCheckBoxInline
} from '../utils/EditorInputUtil';
import { EColor, FormatDate, GetBootTextColorClass, GetYear, stringComparer } from '../utils/DataFormatUtil';
import { fetchWebApi } from '../utils/ClientUtil';
import { displayError } from '../utils/ExceptionUtil';
import { renderFetchStatus } from '../utils/EditorInputUtil';
import * as T from '../utils/TypeUtil';
import {
    EPolicyTypeLabels,
    EPolicyState,
    EPrivilege,
    EPolicyCategoryOptions, EPolicyStateOptions, ECommissionTypeOptions, EYesNo, EPolicyType,
} from '../AppEnums';
import { URL_POLICY as URL } from '../AppConstants';

import { faEdit, faPlus, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import '../general/css/general.css';

import {
    fetchPolicyTypes, fetchCarrierOptions,
    fetchUserPrivileges, fetchLoginsWithPrivileges, fetchRelatedUsers, fetchPerson, sanitizeJsonObject, fetchLoginsWithNPNs,
    fetchRenewPolicyDate,
    formatLogins
} from './EntityUtil';
import {
    EElementType,
    DenormalizeAndValidateSsn,
    DenormalizePolicyMember,
    EPersonFields,
    EPolicyFields as EFields,
    EPolicyMemberFields,
    NewPerson,
    NewPolicyMemberDto, NormalizePolicyMember, NormalizeSsn, PolicyFieldInfos as Fields, ESysTaskType, ETaskFields
} from './EntityData';
import { PersonTable, EPersonTableMode } from './PersonTable';
import {
    createSelectRowOptions, displayElementEditor, displayPersonSelectionDialog,
    formatPersonName, renderOptionsCell, validatePersonData
} from './EntityEditorUtil';
import { PolicyNoteTable } from './PolicyNoteTable';
import { TaskTable } from './TaskTable';
import { TabControl } from '../general/TabControl';
import { AttachmentTable } from './AttachmentTable';
import { LogEntryTable } from './LogEntryTable';
import { ETextEditorMode, TextEditor } from '../general/TextEditor';
import BootPanel from '../general/BootPanel';
import { TableColSortEntry } from '../general/ElementTableUtil';

const EOptions = {
    TYPES: 'types',
    CARRIERS: 'carriers',
    REFERRALS: 'referrals',
    PROCESSORS: 'processors',
    MONITORS: 'monitors',
    VERIFIERS: 'verifiers',
    RELATED_USERS: 'relatedUsers',
    AGENTS: 'agents',
}
const ETab = {
    GENERAL: 'General',
    NOTES: 'Notes',
    TASKS: 'Tasks',
    ATTACHMENT: 'Attachments',
    LOGS: 'History'
}

const PROP_NOTE = 'note';
const PROP_OWNER = 'Owner';
const PROP_AUTOGEN_SYS_TASK = 'autogenSysTaskType';
const PROP_AUTOGEN_SYS_TASK_LABEL = 'Create Task to Process Policy';
const ACTION_ADD_NEW_CARRIER = '<< Add New Carrier >>';

/**
 * @param id [optional] ID of the policy, applicable when view/edit existing policy
 * @param ownerId [optional] ID of the policy owner, applicable when creating new policy from scratch
 * @param copyId [optional] ID of the policy to create new, applicable when copying policy
 * @param newProps [optional] Properties of the new policy to overwrite, applicable when copying/creating new policy
 * @param disabled [optional] 
 * @param canChangePolicyOwner [optional]
 * @param onElementChanged [optional] raised when element data changed, not necessarily saved
 * @param onPersonChanged [optional] raised when a person data changed
 * @param onRenewPolicyClick [optional] raised when user wants to renew this policy
 */
export class PolicyEditor extends Component {
    constructor(props) {
        super(props);
        const { name, link } = props;

        this.onFetchStart = this.onFetchStart.bind(this);
        this.onFetchCompleted = this.onFetchCompleted.bind(this);
        this.updateFetchStatus = this.updateFetchStatus.bind(this);
        this.reload = this.reload.bind(this);
        this.handleDeleteMember = this.handleDeleteMember.bind(this);
        this.handleAddNewMember = this.handleAddNewMember.bind(this);
        this.handleAddNewMemberById = this.handleAddNewMemberById.bind(this);
        this.handleNewMemberButtonClicked = this.handleNewMemberButtonClicked.bind(this);
        this.handleInputChanged = this.handleInputChanged.bind(this);
        this.loadPolicyTypes = this.loadPolicyTypes.bind(this);
        this.loadCarriers = this.loadCarriers.bind(this);
        this.fetchRels = this.fetchRels.bind(this);
        this.fetchNpnOptions = this.fetchNpnOptions.bind(this);
        this.fetchElementData = this.fetchElementData.bind(this);
        this.handleMemberTableBeforeSaveTableCell = this.handleMemberTableBeforeSaveTableCell.bind(this);
        this.handleMemberTableAfterSaveTableCell = this.handleMemberTableAfterSaveTableCell.bind(this);
        this.handleTaskElementChanged = this.handleTaskElementChanged.bind(this);
        this.handleAddCarrier = this.handleAddCarrier.bind(this);
        this.onSave = this.onSave.bind(this);
        this.isExistingPolicyId = this.isExistingPolicyId.bind(this);

        this.name = name || 'editorPolicy';

        this.memberTableCellEditOptions = {
            beforeSaveCell: this.handleMemberTableBeforeSaveTableCell,
            afterSaveCell: this.handleMemberTableAfterSaveTableCell
        };

        this.state = this.createInitialState(props);

        this.mount = false;
        this.refAlert = undefined;
        this.refModal = undefined;
        this.refModalXL = undefined;
        this.refMemberTable = undefined;
        this.refTaskTable = undefined;
        this.refAttachmentTable = undefined;

        if (T.IsFunc(link)) {
            link(this);
        }
    }

    isExistingPolicyId(elementId) {
        return T.IsDefined(elementId) && elementId !== T.EmptyGuid;
    }

    handleAddCarrier() {
        displayElementEditor('Carrier', this.refModal, undefined, () => {

        });
    }

    createInitialState({ id }) {

        const options = {};
        Object.values(EOptions).forEach(x => {
            options[x] = {
                options: [],
                loading: false,
                loadError: undefined
            }
        });

        const useTab = false;

        return {
            useTab,
            dataId: this.isExistingPolicyId(id) ? id : undefined,
            data: {},
            dataEffectiveYear: undefined,
            privileges: [],
            [PROP_NOTE]: '',
            [PROP_AUTOGEN_SYS_TASK]: undefined,
            activeTabId: useTab
                ? ETab.GENERAL
                : ETab.NOTES,
            errors: {},
            loading: true,
            loadError: undefined,
            fetchStatus: options
        };
    }

    handleMemberTableBeforeSaveTableCell(oldVal, newVal, row, col, done) {
        //Do nothing, overwrite to prevent table from sending RelType to server
    }

    handleMemberTableAfterSaveTableCell(oldVal, newVal, row, col, done) {
        const { dataField } = col;
        oldVal = T.IsDefined(oldVal) ? oldVal : '';
        newVal = T.IsDefined(newVal) ? newVal : '';
        if (oldVal === newVal) {
            if (T.IsFunc(done)) done(false);
            return;
        }
        const { data } = this.state;
        if (dataField === EPolicyMemberFields.Apply) {
            row[dataField] = newVal === EYesNo.YES;
        }
        else if (dataField === EPersonFields.Ssn4) {
            row[EPersonFields.SsnHash] = newVal; // SSN: PolicyEditor.EditMember
            row[EPersonFields.Ssn4] = T.IsDefined(newVal) ? newVal.slice(7, 12) : '';
        }
        validatePersonData(row, row.errors, row.Id === data[EFields.OwnerId], col.dataField); //user edit
    }

    onFetchStart(name, callback) {
        if (T.IsArrayNonEmpty(name)) {
            const iLast = name.length;
            let i = 0;
            const onDone = () => {
                ++i;
                if (i === iLast) {
                    if (T.IsFunc(callback)) callback();
                } else {
                    this.updateFetchStatus(name[i], true, undefined, undefined, onDone);
                }
            };
            this.updateFetchStatus(name[0], true, undefined, undefined, onDone);
        }
        else {
            this.updateFetchStatus(name, true, undefined, undefined, callback);
        }
    }
    onFetchCompleted(name, options, error, callback) {
        this.updateFetchStatus(name, false, options, error, callback);
    }
    updateFetchStatus(name, loading, options, error, callback) {

        const { fetchStatus } = this.state;
        const updated = {
            ...fetchStatus,
            ...{
                [name]: {
                    loading,
                    options: options || [],
                    error
                }
            }
        };
        this.setState({ fetchStatus: updated },
            () => {
                if (T.IsFunc(callback)) callback();
            });
    }

    componentWillUnmount() {
        this.mount = false;
    }
    componentDidMount() {
        this.mount = true;

        this.reload();
    }

    componentDidUpdate(prevProps, prevState) {
        const { id, copyId, ownerId, link } = this.props;
        const { id: prevId, copyId: prevCopyId, ownerId: prevOwnerId } = prevProps;
        if (id !== prevId
            || copyId !== prevCopyId
            || ownerId !== prevOwnerId) {

            const state = this.createInitialState(this.props);
            if (T.IsFunc(link)) {
                link(this);
            }
            this.setState(state, this.reload);
        }

        const { activeTabId } = this.state;
        const { activeTabId: prevActiveTabId } = prevState;

        if (activeTabId !== prevActiveTabId) {
            switch (activeTabId) {
                case ETab.NOTES:
                    if (this.refNoteTable) this.refNoteTable.reloadTable();
                    break;
                case ETab.TASKS:
                    if (this.refTaskTable) this.refTaskTable.reloadTable();
                    break;
                case ETab.ATTACHMENT:
                    if (this.refAttachmentTable) this.refAttachmentTable.reloadTable();
                    break;
                case ETab.LOGS:
                    if (this.refLogTable) this.refLogTable.reloadTable();
                    break;
                default:
                    break;
            }
        }
    }

    reload() {

        fetchUserPrivileges(undefined, (privileges, error) => {
            this.setState({ privileges });
        });

        this.loadCarriers();

        const loaderNames = [
            EOptions.PROCESSORS,
            EOptions.VERIFIERS,
            EOptions.REFERRALS,
            EOptions.MONITORS,
        ];
        this.onFetchStart(loaderNames, () => {
            fetchLoginsWithPrivileges(
                [
                    EPrivilege.ProcessPolicy,
                    EPrivilege.VerifyPolicy,
                    EPrivilege.CanRefer,
                ],
                (result, error) => {
                    //if (!this.mount) return;

                    Object.keys(result).forEach(key => {
                        result[key] = formatLogins(result[key]);
                    });
                    const processors = result[EPrivilege.ProcessPolicy];

                    this.onFetchCompleted(EOptions.PROCESSORS,
                        processors,
                        error, () => {
                            this.onFetchCompleted(EOptions.VERIFIERS,
                                result[EPrivilege.VerifyPolicy],
                                error, () => {
                                    this.onFetchCompleted(EOptions.REFERRALS,
                                        result[EPrivilege.CanRefer],
                                        error, () => {
                                            const monitors = processors && [...processors];
                                            this.onFetchCompleted(EOptions.MONITORS,
                                                monitors,
                                                error, this.fetchNpnOptions);
                                        });
                                });
                        });
                });
        });
    }

    loadPolicyTypes(category, done) {

        this.onFetchStart(EOptions.TYPES, () => {
            fetchPolicyTypes(category, (result, error) => {
                //if (!this.mount) return;

                const list = (result || []).map((x) => ({
                    Id: x,
                    Name: EPolicyTypeLabels[x]
                }));

                //Add existing type (in case they have been disabled/deleted)
                // const { data } = this.state;
                // var selected = data ? data[EFields.Type] : undefined;
                // if (selected && !list.find(x => x.Id === selected)) {
                //     list.push({ Id: selected, Name: EPolicyTypeLabels[selected], disabled: true });
                // }

                list.sort(stringComparer);
                this.onFetchCompleted(EOptions.TYPES, list, error, done);
            });
        });
    }

    loadCarriers(policyType) {
        policyType = undefined; //TODO: Remove
        this.onFetchStart(EOptions.CARRIERS,
            () => {
                fetchCarrierOptions(policyType, (result, error) => {
                    //if (!this.mount) return;

                    const list = (result || [])
                        .map((x) => { return { Id: x.Id, Name: x.Id } });
                    // list.splice(0, 0, { Id: ACTION_ADD_NEW_CARRIER, Name: ACTION_ADD_NEW_CARRIER });

                    //Add existing carrier (in case they have been disabled/deleted)
                    // const { data } = this.state;
                    // var selected = data ? data[EFields.CarrierId] : undefined;
                    // if (selected && !list.find(x => x.Id === selected)) {
                    //     list.push({ Id: selected, Name: selected, disabled: true });
                    // }

                    list.sort(stringComparer);
                    this.onFetchCompleted(EOptions.CARRIERS, list, error);
                });
            });
    }

    fetchRels(ownerId, done) {

        const { data } = this.state;

        if (!T.IsDefined(ownerId)) {
            if (T.IsFunc(done)) {
                done();
            }
            return;
        }

        //Must be updating existing, so we'll have to wait until policy data is fetched
        //before owner ID is available
        this.onFetchStart(EOptions.RELATED_USERS,
            () => {
                fetchRelatedUsers(ownerId, (rels, error) => {
                    //if (!this.mount) return;

                    this.onFetchCompleted(EOptions.RELATED_USERS, rels, error, () => {

                        const members = data[EFields.Members];

                        //Normalize members to contain rel type
                        var updated = members.map(m => {
                            m = NormalizePolicyMember(ownerId, m, rels);
                            return m;
                        });
                        const dataWithMembers = {
                            ...data,
                            [EFields.Members]: updated //add relType
                        };

                        this.setState({ data: dataWithMembers }, done);
                    });
                })
            });
    }

    fetchNpnOptions() {
        const { dataId } = this.state;

        this.onFetchStart(EOptions.AGENTS,
            () => {
                fetchLoginsWithNPNs((result, error) => {
                    if (!this.mount) return;
                    result = formatLogins(result, true);

                    this.onFetchCompleted(EOptions.AGENTS, result, error, () => {
                        if (!dataId) {
                            this.fetchElementData();
                            return;
                        }
                        fetchRenewPolicyDate(this.refModal, dataId, (bSuccess, date) => {
                            this.setState({ renewDate: date }, this.fetchElementData);
                        });
                    });
                })
            });
    }

    fetchElementData() {
        this.setState({ loading: true, loadError: undefined }, () => {

            const { dataId } = this.state;
            const { ownerId, copyId, newProps } = this.props;
            const isEdit = this.isExistingPolicyId(dataId);

            const url = isEdit
                ? `${URL}/${dataId}`
                : T.IsDefined(copyId)
                    ? `${URL}/${copyId}/copy`
                    : `${URL}/create?ownerId=${T.IsDefined(ownerId) ? ownerId : ''}`;
            const msg = 'Error retrieving policy data.';
            fetchWebApi(url)
                .then((result) => {
                    //if (!this.mount) return;

                    const members = result[EFields.Members];
                    if (url.includes('/copy') || url.includes('/create')) {
                        result = {
                            ...result,
                            ...newProps
                        }
                    }
                    result[EFields.Members] = T.IsArrayNonEmpty(members) ? [...members] : [];

                    this.populateViewData(isEdit ? dataId : undefined,
                        result,
                        () => {
                            this.setState({
                                loading: false
                            });
                        });
                })
                .catch((error) => {
                    //if (!this.mount) return;
                    displayError(this.refAlert, msg, error);
                    this.setState({
                        loading: false,
                        loadError: msg
                    });
                });
        });
    }

    /** Delete the selected member */
    handleDeleteMember(selected) {
        const { data } = this.state;
        const members = data[EFields.Members] || [];

        data[EFields.Members] = members.filter(x => x.Id !== selected.Id); //delete member

        this.setState({ data: { ...data } });
        return true;
    }

    /** Insert a new empty row to Members table */
    handleNewMemberButtonClicked() {
        const { data } = this.state;
        this.refMemberTable.addNewMember(data[EFields.OwnerId]);
    }

    /** Add an existing person to Members table */
    handleAddNewMemberById(personId) {
        fetchPerson(personId, (person, errorMsg, error) => {
            if (T.IsDefined(errorMsg)) {
                displayError(this.refModal, errorMsg, error);
                return;
            }
            this.handleAddNewMember([person]);
        });
    }

    /**
     * Add selected person(s) to the policy
     * @param {*} selections list of person(s) to be added
     * @param {*} done [optional] Func<in success>
     */
    handleAddNewMember(selections, done) {
        const { data, fetchStatus } = this.state;

        const relInfo = fetchStatus[EOptions.RELATED_USERS];
        let rels = T.IsDefined(relInfo) ? relInfo.options : undefined;
        rels = rels || [];

        const ownerId = data[EFields.OwnerId];
        const members = data[EFields.Members] || [];

        const updated = members.slice();
        selections.forEach(x => {

            let member = NewPolicyMemberDto(undefined, data.Id, x);
            member = NormalizePolicyMember(ownerId, member, rels);

            member.errors = {};
            NormalizeSsn(member, true);
            validatePersonData(member, member.errors, member.Id === ownerId); //add new member

            updated.push(member);
        });
        data[EFields.Members] = updated; //add new member

        this.setState({
            data: { ...data }
        }, () => {
            if (T.IsFunc(done)) {
                done(true);
            }
        });
    }

    populateViewData(id, data, callback = undefined) {
        data = data || {};
        sanitizeJsonObject(data);

        const { data: old, fetchStatus } = this.state;

        //Add existing logons (in case they have been disabled/deleted)
        var selectedAgentId = data[EFields.AgentId];
        var selectedRefId = data[EFields.ReferredById];
        var selectedProcId = data[EFields.ProcessedById];
        var selectedMonitorId = data[EFields.MonitoredById];
        var selectedVerId = data[EFields.VerifiedById];

        var referrals = fetchStatus[EOptions.REFERRALS];
        var processors = fetchStatus[EOptions.PROCESSORS];
        var monitors = fetchStatus[EOptions.MONITORS];
        var verifiers = fetchStatus[EOptions.VERIFIERS];
        var agents = fetchStatus[EOptions.AGENTS];

        if (selectedRefId && !referrals.options.find(x => x.Id === selectedRefId)) {
            referrals.options.push({ Id: selectedRefId, Name: data[EFields.ReferredBy], disabled: true });
        }
        if (selectedProcId && !processors.options.find(x => x.Id === selectedProcId)) {
            processors.options.push({ Id: selectedProcId, Name: data[EFields.ProcessedBy], disabled: true });
        }
        if (selectedMonitorId && !processors.options.find(x => x.Id === selectedMonitorId)) {
            monitors.options.push({ Id: selectedMonitorId, Name: data[EFields.MonitoredBy], disabled: true });
        }
        if (selectedVerId && !verifiers.options.find(x => x.Id === selectedVerId)) {
            verifiers.options.push({ Id: selectedVerId, Name: data[EFields.VerifiedBy], disabled: true });
        }
        if (selectedAgentId && !verifiers.options.find(x => x.Id === selectedAgentId)) {
            agents.options.push({ Id: selectedAgentId, Name: data[EFields.AgentId], disabled: true });
        }

        const members = data[EFields.Members] || [];
        members.forEach((m) => {
            m.errors = {};
            NormalizeSsn(m);
            validatePersonData(m, m.errors, m.Id === data[EFields.OwnerId]); //form load
        });

        const isEdit = this.isExistingPolicyId(id);

        this.setState({
            dataId: isEdit ? id : undefined,
            [PROP_AUTOGEN_SYS_TASK]: !isEdit, //enable create-task auto-gen
            dataEffectiveYear: isEdit ? GetYear(data[EFields.EffectiveDate]) : undefined,
            data: data,
            fetchStatus
        }, () => {

            this.fetchRels(data[EFields.OwnerId], () => {

                const onLoadPolicyTypesCompleted = () => {

                    const type = data[EFields.Type];
                    const oldType = old[EFields.Type];
                    if (oldType !== type) {
                        this.loadCarriers(type);
                    }

                    if (T.IsFunc(callback)) callback();
                };

                const policyCat = data[EFields.Category];
                var oldPolicyCat = old[EFields.Category];
                if (oldPolicyCat !== policyCat) {
                    this.loadPolicyTypes(policyCat, onLoadPolicyTypesCompleted);
                    return;
                }
                onLoadPolicyTypesCompleted();
            });
        });
    }

    onSave(callback) {

        this.refModal.Loading('Saving...');
        const { data, note } = this.state;
        const autoGenSysTask = this.state[PROP_AUTOGEN_SYS_TASK];

        const { state } = data[EFields.State];
        switch (state) {
            case EPolicyState.Cancelled:
            case EPolicyState.DoNotRenew:
            case EPolicyState.Terminated:
                console.warn(`Policy is in ${state}. Changes will not be saved!`);
                if (T.IsFunc(callback)) {
                    callback();
                }
                this.refModal.close();
                return;
            default:
                break;
        }
        this.setState({ errors: {}, validated: true });

        try {
            const postData = { ...data };
            const ownerId = postData[EFields.OwnerId];
            const errors = {};

            postData[EFields.Members] = []; //members will be sent separately  

            const members = [];
            const rels = [];
            data[EFields.Members].forEach((m) => {

                // Create copy so changes when denormalizing SSN won't intefere with the display data
                const copy = { ...m };
                DenormalizeAndValidateSsn(copy); // SSN: Validate PolicyMember
                if (T.stringIsNullOrEmpty(copy[EPersonFields.ImmigrationStatus])) {
                    copy[EPersonFields.ImmigrationStatus] = undefined;
                }

                //Denormalize member to break up rel type
                members.push(DenormalizePolicyMember(ownerId, copy, rels));
            });

            this.refModal.Loading('Validating policy data...');

            //VALIDATE: policy data
            Object.keys(Fields).forEach(field => {
                const info = Fields[field];
                const fieldValid = !info.required
                    || checkRequired(errors, postData, field);
                if (fieldValid) {
                    switch (field) {
                        default:
                            break;
                    }
                }
            });

            this.refModal.Loading('Validating policy members...');

            //VALIDATE: policy members
            if (T.IsArrayEmpty(members)    //no member
                || (members.find(x => x[EPolicyMemberFields.Apply] === true) === undefined) //no apply member
            ) {
                errors[EFields.Members] = 'At least one members must apply for coverage.';
            }
            else {
                const errMem = members.filter((x) => Object.keys(x.errors).length > 0);
                if (errMem.length > 0) {
                    errors[EFields.Members] = 'At least one members has invalid data.';
                }
            }

            if (Object.keys(errors).length > 0) {
                this.setState({ errors: errors });
                this.refModal.close();
                return;
            }

            this.refModal.Loading('Sending policy data to server...');

            const body = {
                Policy: postData,
                Members: members,
                Rels: rels,
                Note: T.IsDefined(note) && note.trim().length > 0 ? note.trim() : undefined,
                SysTaskType: autoGenSysTask ? ESysTaskType.CREATE_POLICY : undefined,
            };

            //POST
            const msg = 'Error saving user data.';
            fetchWebApi(URL,
                {
                    method: 'POST',
                    body: JSON.stringify(body)
                },
                { 'Content-Type': 'application/json' })
                .then((result) => {
                    //if (!this.mount) return;

                    this.populateViewData(result.Id,
                        result,
                        () => {
                            this.refModal.Success('Successfully saved.', '',
                                () => {
                                    this.refModal.close();
                                    if (T.IsFunc(callback)) {
                                        callback(result);
                                    }
                                });
                        });
                })
                .catch((error) => {
                    //if (!this.mount) return;
                    this.refModal.close();
                    displayError(this.refModal, msg, error);
                });
        } catch (error) {
            //if (!this.mount) return;

            displayError(this.refModal, 'Error saving data', error);
        }
    }

    handleTaskElementChanged(e, sender) {
        if (sender === this.refTaskTable) {
            if (e[ETaskFields.SystemTaskType] === ESysTaskType.CREATE_POLICY) {
                //task may change the state of the policy
                this.fetchElementData();

                //TODO: Notify parent to refresh
            }
        }
    }

    handleInputChanged(evt, field, value, callback) {
        if (value === ACTION_ADD_NEW_CARRIER) {
            this.handleAddCarrier();
            return;
        }
        if (field === PROP_NOTE
            || field === PROP_AUTOGEN_SYS_TASK
        ) {
            if (this.state[field] !== value) {
                this.setState({ [field]: value });
            }
            if (T.IsFunc(callback)) {
                callback();
            }
            return;
        }

        const { data } = this.state;
        let newData;
        if (field === PROP_OWNER) {
            newData = {};
            newData[EFields.OwnerFirstName] = value[EPersonFields.FirstName];
            newData[EFields.OwnerLastName] = value[EPersonFields.LastName];
            newData[EFields.OwnerMiddleName] = value[EPersonFields.MiddleName];
            newData[EFields.OwnerSuffix] = value[EPersonFields.Suffix];
            newData[EFields.OwnerNickName] = value[EPersonFields.NickName];
            newData[EFields.OwnerPhoneNumber] = value[EPersonFields.PhoneNumber];
            newData[EFields.OwnerDOB] = value[EPersonFields.DOB];
            newData[EFields.OwnerSsn4] = value[EPersonFields.Ssn4];
            newData[EFields.OwnerSsnHash] = value[EPersonFields.SsnHash];
            newData = {
                ...data,
                ...newData
            };
        }
        else {
            if (data[field] === value) return;

            newData = {
                ...data,
                ...{ [field]: value }
            };

            if (field === EFields.OtherAgent) {
                const b = T.DefaultBool(value, false);
                if (!b) {
                    //clear other agent name
                    newData[EFields.OtherAgentName] = '';
                }
            }
        }

        this.setState({
            data: newData
        }, () => {
            switch (field) {
                case EFields.Category:
                    this.loadPolicyTypes(value);
                    break;
                case EFields.Type:
                    this.loadCarriers(value);
                    break;
                default:
                    break;
            }
            if (T.IsFunc(callback)) {
                callback();
            }
        });
    }

    renderPersonChooser(keyPrefix, data, errors, disabled) {
        const { onPersonChanged } = this.props;

        const fieldEntityId = EFields.OwnerId;
        var selected = undefined;
        const btnSelect = disabled
            ? undefined
            : {
                name: 'Select Policy Owner',
                faIcon: faEdit,
                onClick: () => {
                    const selectedId = data[fieldEntityId];
                    const key = `${this.name}_selectOwner`;

                    const selections = [];
                    if (T.IsDefined(selectedId)) {
                        selections.push(selectedId);
                    }
                    const selectOptions = createSelectRowOptions(false, selections, (row, isSelect) => {
                        selected = row;
                    });
                    const handlePersonChanged = (person) => {

                        if (selectedId === person.Id) {

                            this.handleInputChanged(undefined, PROP_OWNER, person,
                                () => {
                                    this.handleInputChanged(undefined, fieldEntityId, person.Id);
                                });
                        }

                        if (T.IsFunc(onPersonChanged)) {
                            onPersonChanged(person);
                        }
                    };
                    const handleAttachmentChanged = () => {
                        // If showing attachments outside of the policy, then refresh is needed
                        // this.refAttachmentTable.reloadTable();
                    }

                    displayPersonSelectionDialog(this.refModalXL, key, 'Select individual as Policy Owner',
                        undefined, //Can specify anyone to be the policy 
                        false, //not disabled
                        selectOptions,
                        (callback) => { // OK

                            if (!T.IsDefined(selected)) {

                                this.refModal.Warning('Please select a policy owner', '',
                                    () => {
                                        this.refModal.close();
                                        callback(false);
                                    });
                                return;
                            }

                            this.handleInputChanged(undefined, PROP_OWNER, selected,
                                () => {

                                    this.handleInputChanged(undefined, fieldEntityId, selected.Id,
                                        () => {
                                            callback(true);
                                        });
                                });
                        },
                        handlePersonChanged,
                        handleAttachmentChanged
                    )
                }
            };

        return buildInputString(keyPrefix, data, errors, Fields[fieldEntityId],
            this.handleInputChanged, true,
            undefined,
            () => { //value formatter
                const id = data[fieldEntityId];
                if (!this.isExistingPolicyId(id)) return;
                const sFirst = data[EFields.OwnerFirstName];
                const sLast = data[EFields.OwnerLastName];
                const sMiddle = data[EFields.OwnerMiddleName];
                const sSuffix = data[EFields.OwnerSuffix];
                return formatPersonName(sFirst, sLast, sMiddle, sSuffix);
            },
            btnSelect
        )
    }

    renderPolicyInfo(keyPrefix, data, disabled, errors, privs, fetchStatus, isNewPolicy) {

        const { canChangePolicyOwner } = this.props;
        const policyTypes = fetchStatus[EOptions.TYPES];
        const carriers = fetchStatus[EOptions.CARRIERS];
        const referrals = fetchStatus[EOptions.REFERRALS];
        const processors = fetchStatus[EOptions.PROCESSORS];
        const verifiers = fetchStatus[EOptions.VERIFIERS];
        const monitors = fetchStatus[EOptions.MONITORS];
        const npns = fetchStatus[EOptions.AGENTS];

        const policyType = data[EFields.Type];
        // const bOwnerReadonly = disabled || !(isNewPolicy || T.DefaultBool(canChangePolicyOwner, false));
        const bOwnerReadonly = disabled;

        return (
            <React.Fragment>
                <Row className='mb-2'>
                    <Col className='col-sm-2'>
                        {
                            buildInputSelect(keyPrefix, data, errors, Fields[EFields.Category],
                                EPolicyCategoryOptions,
                                this.handleInputChanged, disabled, true)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputSelect(keyPrefix, data, errors, Fields[EFields.Type],
                                policyTypes.options,
                                this.handleInputChanged, disabled, true)
                        }
                        {
                            renderFetchStatus(policyTypes.loading, policyTypes.error)
                        }
                    </Col>
                    <Col className='col-sm-4'>
                        {
                            this.renderPersonChooser(keyPrefix, data, errors, bOwnerReadonly)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputDate(keyPrefix, data, errors, Fields[EFields.EffectiveDate],
                                this.handleInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputSelect(keyPrefix, data, errors, Fields[EFields.State],
                                EPolicyStateOptions,
                                this.handleInputChanged, disabled)
                        }
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col className='col-sm-4'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.Designator],
                                this.handleInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputSelect(keyPrefix, data, errors, Fields[EFields.CommissionType],
                                ECommissionTypeOptions,
                                this.handleInputChanged, disabled, true)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputNumeric(keyPrefix, data, errors, Fields[EFields.CommissionPaid],
                                this.handleInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputDate(keyPrefix, data, errors, Fields[EFields.ExpirationDate],
                                this.handleInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.Status],
                                this.handleInputChanged, disabled || true)
                        }
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col className='col-sm-4'>
                        {
                            buildInputSelect(keyPrefix, data, errors, Fields[EFields.CarrierId],
                                carriers.options,
                                this.handleInputChanged, disabled, true)
                        }
                    </Col>
                    <Col className='col-sm-5'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.PlanName],
                                this.handleInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-3'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.PolicyNumber],
                                this.handleInputChanged, disabled)
                        }
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col className='col-sm-2'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.AcaFfmId],
                                this.handleInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.AcaFfmSubscriberId],
                                this.handleInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputNumeric(keyPrefix, data, errors, Fields[EFields.Income],
                                this.handleInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputNumeric(keyPrefix, data, errors, Fields[EFields.Premium],
                                this.handleInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputNumeric(keyPrefix, data, errors, Fields[EFields.NetPremium],
                                this.handleInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.Npn],
                                this.handleInputChanged, disabled || true)
                            // buildInputAutoComplete(keyPrefix, data, errors, Fields[EFields.Npn],
                            //     npns.options || [],
                            //     this.handleInputChanged, disabled)
                        }
                        {/* {
                            renderFetchStatus(npns.loading, npns.error)
                        } */}
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col className='col-sm-2'>
                        {
                            // privs[EPrivilege.ViewReferral] &&
                            <React.Fragment>
                                {
                                    buildInputSelect(keyPrefix, data, errors, Fields[EFields.ReferredById],
                                        referrals.options, this.handleInputChanged,
                                        disabled, // || !privs[EPrivilege.ManageReferral],
                                        true)
                                }
                                {
                                    renderFetchStatus(referrals.loading, referrals.error)
                                }</React.Fragment>
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputSelect(keyPrefix, data, errors, Fields[EFields.MonitoredById],
                                monitors.options, this.handleInputChanged,
                                disabled, // || !privs[EPrivilege.ManageProcessor],
                                true)
                        }
                        {
                            renderFetchStatus(monitors.loading, monitors.error)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputSelect(keyPrefix, data, errors, Fields[EFields.ProcessedById],
                                processors.options, this.handleInputChanged,
                                disabled, // || !privs[EPrivilege.ManageProcessor],
                                true)
                        }
                        {
                            renderFetchStatus(processors.loading, processors.error)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputSelect(keyPrefix, data, errors, Fields[EFields.VerifiedById],
                                verifiers.options, this.handleInputChanged,
                                disabled, // || !privs[EPrivilege.ManageVerifier],
                                true)
                        }
                        {
                            renderFetchStatus(verifiers.loading, verifiers.error)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputSelect(keyPrefix, data, errors, Fields[EFields.AgentId],
                                npns.options, this.handleInputChanged,
                                disabled,
                                true)
                        }
                        {
                            renderFetchStatus(npns.loading, npns.error)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.AOR],
                                this.handleInputChanged, disabled || true)
                        }
                    </Col>
                </Row>
                {
                    policyType === EPolicyType.ACA &&
                    <Row className='mb-2'>
                        <Col className='col-sm-4' />
                        <Col className='col-sm-2'>
                            {
                                buildInputCheckBoxInline(keyPrefix, data, errors, Fields[EFields.PremiumPaid],
                                    this.handleInputChanged, disabled)
                            }
                        </Col>
                        <Col className='col-sm-2'>
                            {
                                buildInputCheckBoxInline(keyPrefix, data, errors, Fields[EFields.DmiAddressed],
                                    this.handleInputChanged, disabled)
                            }
                        </Col>
                        <Col className='col-sm-2'>
                            {
                                buildInputCheckBoxInline(keyPrefix, data, errors, Fields[EFields.ConsentReceived],
                                    this.handleInputChanged, disabled)
                            }
                        </Col>
                        <Col className='col-sm-2'>
                            {
                                buildInputCheckBoxInline(keyPrefix, data, errors, Fields[EFields.OtherAgent],
                                    this.handleInputChanged, disabled)
                            }
                            {
                                buildInputString(keyPrefix, data, errors, Fields[EFields.OtherAgentName],
                                    this.handleInputChanged, disabled || !T.DefaultBool(data[EFields.OtherAgent], false))
                            }
                        </Col>
                        {
                            // isNewPolicy &&
                            // <Col className='col-sm-2'>
                            //     {
                            //         buildInputCheckBoxInline(keyPrefix, this.state, errors, { name: PROP_AUTOGEN_SYS_TASK, label: PROP_AUTOGEN_SYS_TASK_LABEL, required: true },
                            //             this.handleInputChanged)
                            //     }
                            // </Col>
                        }
                    </Row>
                }
            </React.Fragment>
        );
    }

    renderMembers(containerName, disabled, data, errors, validated) {

        const { onPersonChanged } = this.props;
        const ownerId = data[EFields.OwnerId];
        const members = data[EFields.Members] || [];
        const buttons = [];

        const readOnly = T.DefaultBool(disabled, false) || !T.IsDefined(ownerId);
        if (!readOnly) {

            buttons.push(renderButton(`${containerName}-addNew`, 'Add New Member',
                () => {
                    this.handleAddNewMember([NewPerson()]);
                },
                false,
                'd-inline-block mr-1 me-1', faPlus));

            if (members.find(x => x.Id === ownerId) === undefined) {

                buttons.push(renderButton(`${containerName}-addOwner`, 'Add Owner',
                    () => {
                        this.handleAddNewMemberById(ownerId);
                    },
                    false,
                    'd-inline-block mr-1 me-1'));
            }
        }

        const errorMember = errors[EFields.Members];

        const errorMemberDetails = [];
        if (validated) {
            members.filter((x) => Object.keys(x.errors).length > 0).forEach(x => {
                var memFieldErrorMap = x.errors;
                Object.keys(memFieldErrorMap).forEach(field => {
                    const fieldErr = `${field}: ${memFieldErrorMap[field]}`;
                    if (!errorMemberDetails.find(x => x === fieldErr)) {
                        errorMemberDetails.push(fieldErr);
                    }
                });
            })
        }

        return (
            <React.Fragment>
                {
                    errorMember &&
                    <span className={'mb-2 ' + GetBootTextColorClass(EColor.Danger)}>
                        {errorMember}
                    </span>
                }
                {
                    T.IsArrayNonEmpty(errorMemberDetails) &&
                    <div style={{ display: 'grid' }}>
                        {
                            errorMemberDetails.map(x =>
                                <span className={GetBootTextColorClass(EColor.Danger)}>
                                    {x}
                                </span>
                            )
                        }
                    </div>
                }
                <PersonTable name={`${containerName}_members`} displayMode={EPersonTableMode.MEMBER}
                    hideAttachments={true}
                    userId={ownerId}
                    data={members}
                    canDelete
                    disabled={readOnly}
                    link={(e) => this.refMemberTable = e}
                    buttons={buttons}
                    validated={validated}
                    onElementChanged={onPersonChanged}
                    onDelete={this.handleDeleteMember}
                    onAddNew={this.handleAddNewMember}
                    onNew={this.handleNewMemberButtonClicked}
                    onNewIcon={faUserPlus}
                    cellEditOptions={this.memberTableCellEditOptions}
                />
            </React.Fragment>
        );
    }

    buildTabInfos(disabled, currentTabId, data, errors, isEdit, validated) {

        const ownerId = data[EFields.OwnerId];

        const tabs = [];

        if (isEdit) {
            tabs.push(
                {
                    id: ETab.NOTES,
                    title: ETab.NOTES,
                    body: (
                        <PolicyNoteTable name={`${this.name}_policyNotes`}
                            id={data.Id}
                            disabled={disabled || !isEdit}
                            disableLoadOnInit={currentTabId !== ETab.NOTES}
                            link={(e) => this.refNoteTable = e}
                        />
                    )
                });
            tabs.push(
                {
                    id: ETab.TASKS,
                    title: ETab.TASKS,
                    body: (
                        <TaskTable name={`${this.name}_tasks`}
                            policyId={data.Id}
                            userId={data.OwnerId}
                            disabled={disabled || !isEdit}
                            disableLoadOnInit={currentTabId !== ETab.TASKS}
                            link={(e) => this.refTaskTable = e}
                            onElementChanged={this.handleTaskElementChanged}
                            defaultSorted={[
                                new TableColSortEntry(ETaskFields.CreatedOn, true)
                            ]}
                            canGoToPolicy={false}
                            canGoToContact={false}
                        />
                    )
                });
            tabs.push(
                {
                    id: ETab.ATTACHMENT,
                    title: ETab.ATTACHMENT,
                    body: (
                        <AttachmentTable ownerId={ownerId} parentType={EElementType.Policy}
                            policyId={this.state.dataId}
                            name={`${this.name}_files`}
                            link={(e) => this.refAttachmentTable = e}
                            disableLoadOnInit={currentTabId !== ETab.ATTACHMENT}
                        />
                    )
                });
            tabs.push(
                {
                    id: ETab.LOGS,
                    title: ETab.LOGS,
                    body: (
                        <LogEntryTable parentId={data.Id} parentType={EElementType.Policy}
                            name={`${this.name}_logs`}
                            link={(e) => this.refLogTable = e}
                            disableLoadOnInit={currentTabId !== ETab.LOGS}
                        />
                    )
                });
        }

        return tabs;
    }

    buildPolicyActions = (keyPrefix, policyId, isExisting, readOnly) => {
        const {
            onRenewPolicyClick
        } = this.props;
        const {
            renewDate,
            dataEffectiveYear
        } = this.state;

        const nextYear = new Date().getFullYear() + 1;
        const show = dataEffectiveYear && dataEffectiveYear < nextYear;

        const btns = [];
        if (isExisting && show && !readOnly && onRenewPolicyClick) {
            btns.push(
                {
                    name: 'Renew Policy',
                    onClick: () => onRenewPolicyClick(policyId, false)
                });
            btns.push(
                {
                    name: 'Do NOT Renew Policy',
                    onClick: () => onRenewPolicyClick(policyId, true)
                });
        }

        return (
            <React.Fragment>
                {
                    T.IsArrayNonEmpty(btns) &&
                    <Row className='mb-4 mt-4'>
                        <Col className='col-sm-12 mb-2' style={{ textAlign: 'center' }}>
                            {
                                renewDate && <span className='me-2'>Renew Date: {FormatDate(renewDate)}</span>
                            }
                            {
                                renderOptionsCell(`${keyPrefix}-btns`, btns)
                            }
                        </Col>
                    </Row>
                }
            </React.Fragment>
        );
    }

    render() {
        const { dataId, activeTabId } = this.state;
        const {
            disabled
        } = this.props;
        const { data, loading, loadError, errors, validated,
            fetchStatus,
            note,
            useTab,
            privileges: privs
        } = this.state;
        const keyPrefix = `${this.name}-`;

        const isExisting = this.isExistingPolicyId(dataId);
        const cannotEdit = disabled;

        var body = undefined;
        var tabInfos = undefined;
        if (loading) {
            body = <Spinner />
        }
        else if (loadError) {
            body = <BootAlert canToggle={false} icon="errror" visible={true} message={loadError} color={EColor.Danger} />
        }
        else {
            const main = (
                <React.Fragment>
                    {this.renderPolicyInfo(keyPrefix, data, cannotEdit, errors, privs, fetchStatus, !isExisting)}
                    <BootPanel head='Members' cardCname='mb-2' toggle open>
                        {this.renderMembers(`${keyPrefix}members`, cannotEdit, data, errors, validated)}
                    </BootPanel>
                </React.Fragment>
            );
            tabInfos = this.buildTabInfos(cannotEdit, activeTabId, data, errors, isExisting, validated);

            if (useTab) {
                const errorMember = errors[EFields.Members];
                tabInfos.splice(0, 0,
                    {
                        id: ETab.GENERAL,
                        title: ETab.GENERAL,
                        navLinkClassnames: T.IsDefined(errorMember) ? 'invalid' : '',
                        body: main
                    });
            } else {
                body = main;
            }
        }
        return (
            <React.Fragment>
                <ComponentModal link={e => this.refModal = e} />
                <ComponentModal link={e => this.refModalXL = e} size="xl" />
                <BootAlert canToggle={false} link={(e) => this.refAlert = e} />
                {
                    body
                }
                {
                    this.buildPolicyActions(keyPrefix, dataId, isExisting, false)
                }
                <React.Fragment>
                    {
                        T.IsArrayNonEmpty(tabInfos) &&
                        <Row className='mb-2'>
                            <Col style={{ backgroundColor: 'aliceblue' }}>
                                <TabControl tabInfos={tabInfos} activeTabId={activeTabId}
                                    onTabSelectionChanged={(tabId) => {
                                        this.setState({ activeTabId: tabId });
                                    }}
                                />
                            </Col>
                        </Row>
                    }
                    {
                        !cannotEdit && !isExisting &&
                        <BootPanel head='Note' cardCname='mb-2' open>
                            <TextEditor keyPrefix={keyPrefix} name={PROP_NOTE} value={note}
                                error={errors[PROP_NOTE]}
                                mode={ETextEditorMode.TEXTAREA}
                                onInputChanged={this.handleInputChanged}
                                length={1000}
                                lineCnt={5} />
                        </BootPanel>
                    }
                </React.Fragment>
            </React.Fragment>
        );
    }
}

PolicyEditor.propTypes = {
    name: PropTypes.string,
    link: PropTypes.func,
    id: PropTypes.string,
    ownerId: PropTypes.string,
    copyId: PropTypes.string,
    newProps: PropTypes.object,
    disabled: PropTypes.bool,
    canChangePolicyOwner: PropTypes.bool,
    onElementChanged: PropTypes.func,
    onPersonChanged: PropTypes.func,
    onRenewPolicyClick: PropTypes.func,
}