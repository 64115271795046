import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import ComponentModal from '../general/ComponentModal';
import {
    buildInputString, buildInputSelect,
    checkRequired,
    buildInputTextArea,
    renderFetchStatus,
    renderButton
} from '../utils/EditorInputUtil';
import { fetchWebApi } from '../utils/ClientUtil';
import { displayError } from '../utils/ExceptionUtil';
import * as T from '../utils/TypeUtil';
import '../general/css/general.css';

import { EElementType, ETaskFields as EFields, TaskFieldInfos as Fields } from './EntityData';
import { URL_TASK as URL } from '../AppConstants';
import BootPanel from '../general/BootPanel';
import { fetchLogins, saveTask } from './EntityUtil';
import { ERole, USER_SYSTEM } from '../AppEnums';
import { stringComparer } from '../utils/DataFormatUtil';
import { TaskCommentTable } from './TaskCommentTable';
import { AttachmentTable } from './AttachmentTable';
import { LogEntryTable } from './LogEntryTable';
import { TabControl } from '../general/TabControl';
import { formatTaskPerson } from './EntityEditorUtil';
import { ETextEditorMode, TextEditor } from '../general/TextEditor';

const ETab = {
    COMMENT: 'Comments',
    ATTACHMENT: 'Attachments',
    LOGS: 'History'
}

const PROP_NOTE = 'note';
const FETCH_ASSIGNEE = 'fetchAssignee';

export const fetchAssigneeOptions = (callback) => {

    fetchLogins(ERole.Processor, (input, error) => {
        //if (!this.mount) return;
        const { result } = (input || {});
        const filtered = (result || []).filter((x) => x.UserName !== USER_SYSTEM); //remove SYSTEM

        //Convert to option list
        const list = filtered.map((x) => ({
            Id: x.Id,
            Name: `${x.Name} (${x.UserName})`
        }));
        list.sort(stringComparer);
        callback(list, error);
    });
}

/**
 * @param ref [optional]
 * @param name [optional] component name
 * @param disabled [optional]
 * @param data [optional] task to be created/edited
 * @param id [optional] ID of existing task to be edited
 */
export class TaskEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            id: props.id,
            data: props.data || {},
            [PROP_NOTE]: '',
            errors: {},
            activeTabId: ETab.COMMENT,
            fetchAssignee: {
                options: [],
                loading: false,
            }
        };

        this.mount = false;
        this.name = this.props.name || 'editorTask';

        this.refModal = undefined;
        this.refAttachmentTable = undefined;
        this.refLogTable = undefined;


        if (T.IsFunc(props.link)) {
            props.link(this);
        }

        this.reload = this.reload.bind(this);
        this.fetchElementData = this.fetchElementData.bind(this);
        this.handleInputChanged = this.handleInputChanged.bind(this);
        this.validateData = this.validateData.bind(this);
    }

    componentWillUnmount() {
        this.mount = false;
    }

    componentDidMount() {
        this.mount = true;
        this.reload();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({ data: this.props.data || {} });
        }
        const { activeTabId } = this.state;
        const { activeTabId: prevActiveTabId } = prevState;

        if (activeTabId !== prevActiveTabId) {
            switch (activeTabId) {
                case ETab.COMMENT:
                    if (this.refNoteTable) this.refNoteTable.reloadTable();
                    break;
                case ETab.ATTACHMENT:
                    if (this.refAttachmentTable) this.refAttachmentTable.reloadTable();
                    break;
                case ETab.LOGS:
                    if (this.refLogTable) this.refLogTable.reloadTable();
                    break;
                default:
                    break;
            }
        }
    }
    onFetchStart(name, callback) {
        this.updateFetchStatus(name, true, undefined, undefined, callback);
    }
    onFetchCompleted(name, options, error, callback) {
        this.updateFetchStatus(name, false, options, error, callback);
    }
    updateFetchStatus(name, loading, options, error, callback) {
        const fetchStatus = this.state[name];
        const updated = {
            ...fetchStatus,
            ...{
                loading,
                options: options || [],
                error
            }
        };
        this.setState({ [name]: updated },
            () => {
                if (T.IsFunc(callback)) callback();
            });
    }

    reload() {

        this.onFetchStart(FETCH_ASSIGNEE, () => {

            fetchAssigneeOptions((result, error) => {
                //if (!this.mount) return;                
                this.onFetchCompleted(FETCH_ASSIGNEE, result, error, () => {

                    this.fetchElementData();
                });
            });
        });
    }

    fetchElementData() {

        const { id } = this.state;
        if (!T.IsDefined(id) || id === T.EmptyGuid) return;

        const url = `${URL}/${id}`;
        const msg = 'Error retrieving task data.';
        fetchWebApi(url)
            .then((result) => {
                //if (!this.mount) return;
                this.setState({
                    data: result,
                });
            })
            .catch((error) => {
                //if (!this.mount) return;
                displayError(this.refModal, msg, error);
            });
    }

    validateData() {
        this.setState({ errors: {} }); //Clear all errors

        const { data } = this.state;
        const postData = { ...data };
        const errors = {};

        //VALIDATE 
        const keys = Object.keys(Fields);
        keys.forEach(field => {
            const info = Fields[field];
            const fieldValid = !info.required
                || checkRequired(errors, postData, field);
            if (fieldValid) {
            }
        });

        if (Object.keys(errors).length > 0) {
            this.setState({ errors: errors });
            return undefined;
        }
        postData[EFields.AssigneeId] = postData[EFields.AssigneeId] || undefined;
        return postData;
    }

    onSave(callback) {

        const postData = this.validateData();
        if (!postData) {
            return;
        }
        const note = this.state[PROP_NOTE];

        saveTask(this.refModal, postData, note,
            (result) => {
                if (T.IsDefined(result)) {

                    this.setState({
                        id: result.Id,
                        [PROP_NOTE]: '',
                    }, this.reload);
                }
                if (T.IsFunc(callback)) {
                    callback(result);
                }
            }
        );
    }

    handleInputChanged(evt, field, value) {
        if (field === PROP_NOTE) {
            if (this.state[field] !== value) {
                this.setState({ [field]: value });
            }
            return;
        }

        const { data } = this.state;
        if (data[field] === value) return;

        const newData = {
            ...data,
            ...{ [field]: value }
        };

        this.setState({
            data: newData
        }, () => {
        });
    }

    buildTabInfos(commentBody, disabled, data, errors, validated) {
        const { id, activeTabId: tabId } = this.state;

        const tabs = [
            {
                id: ETab.COMMENT,
                title: ETab.COMMENT,
                body: commentBody
            },
            {
                id: ETab.ATTACHMENT,
                title: ETab.ATTACHMENT,
                body: (
                    <AttachmentTable taskId={id} parentType={EElementType.Task}
                        name={`${this.name}_files`}
                        link={(e) => this.refAttachmentTable = e}
                        disableLoadOnInit={tabId !== ETab.ATTACHMENT}
                    />
                )
            },
            {
                id: ETab.LOGS,
                title: ETab.LOGS,
                body: (
                    <LogEntryTable parentId={id} parentType={EElementType.Task}
                        name={`${this.name}_logs`}
                        link={(e) => this.refLogTable = e}
                        disableLoadOnInit={tabId !== ETab.LOGS}
                    // onPersonChanged={(e) => this.handlePersonChanged(e, this.refLogTable)}
                    // onTaskChanged={(e) => this.handleTaskChanged(e, this.refLogTable)}
                    // onPolicyChanged={(e) => this.handlePolicyChanged(e, this.refLogTable)}
                    // onAddressChanged={(e) => this.handleAddressChanged(e, this.refLogTable)}
                    />
                )
            }
        ];

        return tabs;
    }

    buildCommentSection(keyPrefix, note, errors, isReadOnly, isExisting, comments) {
        return <>
            {
                !isReadOnly &&
                <div>
                    <TextEditor keyPrefix={keyPrefix} name={PROP_NOTE} label='Note' value={note}
                        error={errors[PROP_NOTE]}
                        mode={ETextEditorMode.TEXTAREA}
                        onInputChanged={this.handleInputChanged}
                        showLabel={false}
                        length={1000}
                        lineCnt={5} />
                </div>
            }
            {
                isExisting &&
                <React.Fragment>
                    <div>
                        {!isReadOnly && <hr />}
                        <TaskCommentTable name={`${this.name}_taskNotes`}
                            data={comments}
                            disabled={isReadOnly}
                            link={(e) => this.refNoteTable = e}
                        />
                    </div>
                </React.Fragment>
            }
        </>
    }

    render() {
        const { disabled, onGoToPolicyClick, onGoToContactClick } = this.props;
        const { id, data, note, errors, fetchAssignee, validated, activeTabId } = this.state;

        const keyPrefix = `${this.name}-`;
        const isExisting = T.IsDefined(id) && id !== T.EmptyGuid;
        const comments = data[EFields.Comments] || [];
        const isOpen = data[EFields.IsOpen];
        const isReadOnly = !isOpen || disabled;

        const commentSection = this.buildCommentSection(keyPrefix, note, errors, isReadOnly, isExisting, comments);
        const tabInfos = isExisting
            ? this.buildTabInfos(commentSection, disabled, data, errors, validated)
            : undefined;
        const policyId = data[EFields.PolicyId];
        const contactId = data[EFields.UserId] || data[EFields.PolicyOwnerId];
        const showGoToPolicyBtn = policyId && T.IsFunc(onGoToPolicyClick);
        const showGoToContactBtn = contactId && contactId !== T.EmptyGuid && T.IsFunc(onGoToContactClick);

        return (
            <React.Fragment>
                <ComponentModal link={e => this.refModal = e} />
                <Row className='mb-2'>
                    <Col className='col-sm-8'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.Name],
                                this.handleInputChanged, isReadOnly || data[EFields.SystemTaskType])
                        }
                    </Col>
                    <Col className='col-sm-4'>
                        {
                            buildInputSelect(keyPrefix, data, errors, Fields[EFields.AssigneeId],
                                fetchAssignee.options, this.handleInputChanged,
                                isReadOnly,
                                true //add invalid option
                            )
                        }
                        {
                            renderFetchStatus(fetchAssignee.loading, fetchAssignee.error)
                        }
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col className='col-sm-12'>
                        {
                            buildInputTextArea(keyPrefix, data, errors, Fields[EFields.Note],
                                this.handleInputChanged, isReadOnly)
                        }
                    </Col>
                </Row>
                {
                    showGoToPolicyBtn
                    && <Row className='mb-2'>
                        <Col className='col-sm-12 mb-2' style={{ textAlign: 'right' }}>
                            {
                                renderButton(`${keyPrefix}-btnGoToPolicy`, `Go to Policy [${data[EFields.PolicyName]}]`,
                                    () => onGoToPolicyClick(policyId, isReadOnly),
                                    false) //always allow the user go to related policy
                            }
                        </Col>
                    </Row>
                }
                {
                    showGoToContactBtn
                    && <Row className='mb-2'>
                        <Col className='col-sm-12 mb-2' style={{ textAlign: 'right' }}>
                            {
                                renderButton(`${keyPrefix}-btnGoToContact`, `Go to Contact [${formatTaskPerson(data)}]`,
                                    () => onGoToContactClick(contactId, isReadOnly),
                                    false) //always allow the user go to related contact
                            }
                        </Col>
                    </Row>
                }
                {
                    !isExisting &&
                    <BootPanel head='Comments' toggle open>
                        {commentSection}
                    </BootPanel>
                }
                {
                    isExisting &&
                    <Row className='mb-2 mt-2'>
                        <Col style={{ backgroundColor: 'aliceblue' }}>
                            <TabControl tabInfos={tabInfos} activeTabId={activeTabId}
                                onTabSelectionChanged={(tabId) => {
                                    this.setState({ activeTabId: tabId });
                                }}
                            />
                        </Col>
                    </Row>
                }
            </React.Fragment>
        );
    }
}

TaskEditor.propTypes = {
    name: PropTypes.string,
    link: PropTypes.func,
    id: PropTypes.string,
    data: PropTypes.object,
    disabled: PropTypes.bool,
    onGoToPolicyClick: PropTypes.func,
    onGoToContactClick: PropTypes.func
}
