import authService from '../api-authorization/AuthorizeService';
import * as T from './TypeUtil';

const DefaultRequest = { method: 'GET', credentials: 'include' };

const EStatusCode = {
    //Informational
    //Success
    OK: 200,
    NoContent: 204,
    //Client Error
    BadRequest: 400,
    Unauthorized: 401,
    Forbidden: 403,
    NotFound: 404,
    UnsupportedMediaType: 415,
    //Server Error
    InternalServerError: 500
}

export const EFetchError = {
    Unauthorized: '[401] Unauthorized'
}

export class HttpError extends Error {
    constructor(response, message = undefined, serverError = undefined) {
        super(`[${response.status}] ${message ?? response.statusText}`);
        this.name = 'HttpError';
        this.response = response;
        this.serverError = serverError;
    }
}


/**
 * return current user info (e.g. loginUserName, loginUserId)
 */
export const fetchCurrentUser = async () => {
    const user = await authService.getUser();
    return {
        loginUserName: user && user.name,
        loginUserId: user && user.sub
    };
}

export const fetchWebApi = async (url, requestOpts = {}, headerOpts = {}) => {
    const token = await authService.getAccessToken();
    // console.log(`URL=${url}, accessToken=${token}`);

    if (token) {
        headerOpts = {
            ...headerOpts,
            ...{ Authorization: `Bearer ${token}` }
        }
    } else {
        console.warn("TOKEN is not set");
    }

    // Override defaults with provided requestOpts    
    requestOpts = {
        ...requestOpts,
        ...{
            headers: headerOpts
        }
    };
    const initDefaults = { ...DefaultRequest, ...requestOpts };

    // var result = await fetch(url, initDefaults);
    // return result.json();
    return await fetch(url, initDefaults)
        .then((response) => {
            if (response.ok) { //Response is in the 200-299 range
                if (response.status === EStatusCode.NoContent) {
                    return undefined;
                }
                const contentType = response.headers.get('Content-Type');
                if (contentType && contentType.indexOf('application/json') > -1) {
                    return response.json()
                        .catch(() => {
                            return response;
                        });
                }
                return response;
            }
            handleFetchNotOkResponse(response);
        })
        .then((resp) => {
            // check for this specific exception and return the error message
            if (resp
                && resp.ClassName === 'System.Security.Authentication.AuthenticationException'
            ) {
                // error messages were being handled differently by different components.
                throw new Error(resp.Message);
            }
            return resp;
        });
};

export const handleFetchNotOkResponse = (response, msg = undefined) => {

    const serverError = response.json()
        .catch(() => {
            throw new HttpError(response, msg);
        });
    throw new HttpError(response, msg, serverError);
}

export class PollingHelper {
    constructor(callback, interval = 30000 /* 30 Seconds */) {
        this.handle = null;
        this.callback = callback;
        this.interval = interval;
    }

    /**
     * Polls the endpoint immediately, and then continues to poll for the configured interval
     */
    start() {
        if (!this.handle) {
            this.callback();
            this.handle = setInterval(this.callback, this.interval);
        }
    }

    /**
     * Stops the polling of the endpoint
     */
    stop() {
        if (this.handle) {
            clearInterval(this.handle);
            this.handle = null;
        }
    }
}

export function FormatPagedUrl(baseUrl, globalFilter, params) {
    let url = baseUrl;

    const urlParams = [];
    if (T.IsDefined(globalFilter)) {
        url += `?filter=${globalFilter}`;
    }

    // build out url based on filter paramters
    Object.keys(params).forEach((key) => {
        const val = params[key];
        switch (key) {
            case 'page':
                urlParams.push(`page=${val}`);
                break;
            case 'sizePerPage':
                urlParams.push(`sizePerPage=${params}`);
                break;
            default:
                break;
        }
    });

    if (urlParams !== '') url = `${url}/page?${urlParams}`;

    //console.log('Formatted url: ', url);
    return url;
}
