import React, { Component } from 'react';
import { ElementTable } from '../general/ElementTable';
import {
    FormatElementTableCellDate, FormatElementTableCellDateTime, FormatElementTableCellSsn,
    ElementTablePageOptions,
    ToElementTableSelectOptions,
    FormatElementTableCellBoolToYesNo,
    EElementTableColFilterType,
    FormatElementTableCellDateEffective,
    TableColSortEntry,
    FormatElementTableCellDateDob
} from '../general/ElementTableUtil';
import ComponentModal from '../general/ComponentModal';
import { FormatPhoneMasked, FormatPolicyState } from '../utils/DataFormatUtil';
import * as T from '../utils/TypeUtil';

import { EPolicyFields, EPolicyFieldLabels } from './EntityData';
import { renderOptionsCell, displayPolicyEditorDialog } from './EntityEditorUtil';
import '../general/css/general.css';

import { faCopy, faEdit, faFolderOpen, faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import { URL_POLICY } from '../AppConstants';
import BootAlert from '../general/BootAlert';
import { EPolicyCategoryOptions, EPolicyTypeOptions, ERole, ERoleValue, EYesNoOptions } from '../AppEnums';
import { buildInputCheckBoxInlineRaw, buildInputDate, renderButton } from '../utils/EditorInputUtil';
import { Col, Row } from 'reactstrap';

const PROP_EXCLUDE_B4_DATE = 'excludeBeforeDate';

/**
 * @param id [optional] ID of the policy owners, 
 * applicable when displaying policies related to an individual
 * @param name [optional] component name
 * @param disableLoadOnInit [optional]
 * @param sizePerPage [optional] number of rows per page
 * @param role [optional]
 * @param onElementChanged [optional] raises when row data has 
 * @param onPersonChanged [optional] raises when row data has changed
 * @param canChangePolicyOwner [optional] whether the user can modify policy owner
 * @param onOpen [optional] raises the user clicks on button to open the selected row
 * @param canDelete [optional] whether user can delete a row in the table
 * @param selectRowOptions [optional]
 */
export class PolicyTable extends Component {
    constructor(props) {
        super(props);

        this.mount = false;
        this.refAlert = undefined;
        this.refModal = undefined;
        this.refTable = undefined;

        this.renderOptions = this.renderOptions.bind(this);
        this.handleFormatUrl = this.handleFormatUrl.bind(this);
        this.handleNewEditButtonClick = this.handleNewEditButtonClick.bind(this);
        this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
        this.handleViewMembersButtonClick = this.handleViewMembersButtonClick.bind(this);
        this.renderHeaderSection = this.renderHeaderSection.bind(this);

        const { name, sizePerPage, assignedToMe, role } = this.props;
        this.name = name || 'tablePolicy';
        const now = new Date();
        const isAdmin = ERoleValue[role] >= ERole.Administrator;

        this.state = {
            data: [],
            includeRenewed: isAdmin,
            includeCompleted: isAdmin,
            assignedToMe: assignedToMe,
            pageOptions: new ElementTablePageOptions(true, sizePerPage),
            [PROP_EXCLUDE_B4_DATE]: new Date(now.getFullYear() - 1, 0, 1),
            defaultSorted: [
                new TableColSortEntry(EPolicyFields.EffectiveDate, true),
                new TableColSortEntry(EPolicyFields.OwnerLastName),
                new TableColSortEntry(EPolicyFields.OwnerFirstName),
                new TableColSortEntry(EPolicyFields.OwnerMiddleName),
                new TableColSortEntry(EPolicyFields.OwnerNickName),
                new TableColSortEntry(EPolicyFields.OwnerDOB),
            ],
        };

        if (T.IsFunc(props.link)) {
            props.link(this);
        }
    }

    reloadTable() {
        this.refTable.reloadTable();
    }

    componentDidMount() {
    }
    componentDidUpdate(prevProps, prevState) {
        const { sizePerPage } = this.props;

        if (prevProps.sizePerPage !== sizePerPage) {
            const { pageOptions } = this.state;
            const updatedPageOptions = {
                ...pageOptions,
                ...{ sizePerPage: sizePerPage }
            };
            this.setState({ pageOptions: updatedPageOptions });
        }
    }

    getColumns() {

        const { id } = this.props;
        const userView = T.IsDefined(id);

        const cols = [
            {
                text: EPolicyFieldLabels.Id,
                dataField: EPolicyFields.Id,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyFieldLabels.Category,
                dataField: EPolicyFields.Category,
                filter: EElementTableColFilterType.SELECT, filterOptions: ToElementTableSelectOptions(EPolicyCategoryOptions),
            },
            {
                text: EPolicyFieldLabels.Type,
                dataField: EPolicyFields.Type,
                filter: EElementTableColFilterType.SELECT, filterOptions: ToElementTableSelectOptions(EPolicyTypeOptions),
            },
            {
                text: EPolicyFieldLabels.Designator,
                dataField: EPolicyFields.Designator,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyFieldLabels.EffectiveDate,
                dataField: EPolicyFields.EffectiveDate,
                filter: EElementTableColFilterType.TEXT,
                formatter: FormatElementTableCellDateEffective
            },
            {
                text: EPolicyFieldLabels.ExpirationDate,
                dataField: EPolicyFields.ExpirationDate,
                filter: EElementTableColFilterType.TEXT,
                hidden: true,
                formatter: FormatElementTableCellDate
            },
            {
                text: EPolicyFieldLabels.RenewEffectiveDate,
                dataField: EPolicyFields.RenewEffectiveDate,
                filter: EElementTableColFilterType.TEXT,
                formatter: FormatElementTableCellDate
            },
            {
                text: EPolicyFieldLabels.OwnerLastName,
                dataField: EPolicyFields.OwnerLastName,
                hidden: userView,
                alwaysHidden: userView,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyFieldLabels.OwnerFirstName,
                dataField: EPolicyFields.OwnerFirstName,
                hidden: userView,
                alwaysHidden: userView,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyFieldLabels.OwnerDOB,
                dataField: EPolicyFields.OwnerDOB,
                hidden: userView,
                alwaysHidden: userView,
                filter: EElementTableColFilterType.TEXT,
                formatter: FormatElementTableCellDateDob,
            },
            {
                text: EPolicyFieldLabels.OwnerSsn4,
                dataField: EPolicyFields.OwnerSsn4,
                hidden: userView,
                alwaysHidden: userView,
                formatter: (cellVal, row) => {
                    return FormatElementTableCellSsn(row.Id,
                        cellVal,
                        row[EPolicyFieldLabels.OwnerSsnHash]); // SSN Format: PolicyTable
                },
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyFieldLabels.OwnerPhoneNumber,
                dataField: EPolicyFields.OwnerPhoneNumber,
                hidden: userView,
                alwaysHidden: userView,
                filter: EElementTableColFilterType.TEXT,
                formatter: (cellVal, row) => FormatPhoneMasked(row[EPolicyFields.Id], cellVal),
            },
            {
                text: EPolicyFieldLabels.AcaFfmId,
                dataField: EPolicyFields.AcaFfmId,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyFieldLabels.CarrierId,
                dataField: EPolicyFields.CarrierId,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyFieldLabels.PlanName,
                dataField: EPolicyFields.PlanName,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyFieldLabels.PolicyNumber,
                dataField: EPolicyFields.PolicyNumber,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyFieldLabels.State,
                dataField: EPolicyFields.State,
                filter: EElementTableColFilterType.TEXT,
                formatter: (cellVal, row) => {
                    return FormatPolicyState(cellVal);
                }
            },
            {
                text: EPolicyFieldLabels.Status,
                dataField: EPolicyFields.Status,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyFieldLabels.ConsentReceived,
                dataField: EPolicyFields.ConsentReceived,
                filter: EElementTableColFilterType.SELECT, filterOptions: ToElementTableSelectOptions(EYesNoOptions),
                formatter: FormatElementTableCellBoolToYesNo
            },
            {
                text: EPolicyFieldLabels.PremiumPaid,
                dataField: EPolicyFields.PremiumPaid,
                filter: EElementTableColFilterType.SELECT, filterOptions: ToElementTableSelectOptions(EYesNoOptions),
                formatter: FormatElementTableCellBoolToYesNo
            },
            {
                text: EPolicyFieldLabels.DmiAddressed,
                dataField: EPolicyFields.DmiAddressed,
                filter: EElementTableColFilterType.SELECT, filterOptions: ToElementTableSelectOptions(EYesNoOptions),
                formatter: FormatElementTableCellBoolToYesNo
            },
            {
                text: EPolicyFieldLabels.AOR,
                dataField: EPolicyFields.AOR,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyFieldLabels.Npn,
                dataField: EPolicyFields.Npn,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyFieldLabels.DNR,
                dataField: EPolicyFields.DNR,
                hidden: true,
                filter: EElementTableColFilterType.SELECT, filterOptions: ToElementTableSelectOptions(EYesNoOptions),
                formatter: FormatElementTableCellBoolToYesNo
            },
            {
                text: EPolicyFieldLabels.OtherAgent,
                dataField: EPolicyFields.OtherAgent,
                hidden: true,
                filter: EElementTableColFilterType.SELECT, filterOptions: ToElementTableSelectOptions(EYesNoOptions),
                formatter: FormatElementTableCellBoolToYesNo
            },
            // {
            //     text: EPolicyFieldLabels.Premium,
            //     dataField: EPolicyFields.Premium,
            //     filter: EElementTableColFilterType.TEXT,
            // },
            {
                text: EPolicyFieldLabels.ReferredBy,
                dataField: EPolicyFields.ReferredBy,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyFieldLabels.ReferredOn,
                dataField: EPolicyFields.ReferredOn,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
                formatter: FormatElementTableCellDateTime
            },
            {
                text: EPolicyFieldLabels.MonitoredBy,
                dataField: EPolicyFields.MonitoredBy,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyFieldLabels.ProcessedBy,
                dataField: EPolicyFields.ProcessedBy,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyFieldLabels.ProcessedOn,
                dataField: EPolicyFields.ProcessedOn,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
                formatter: FormatElementTableCellDateTime
            },
            {
                text: EPolicyFieldLabels.VerifiedBy,
                dataField: EPolicyFields.VerifiedBy,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyFieldLabels.VerifiedOn,
                dataField: EPolicyFields.VerifiedOn,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
                formatter: FormatElementTableCellDateTime
            },
            {
                text: EPolicyFieldLabels.CreatedBy,
                dataField: EPolicyFields.CreatedBy,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyFieldLabels.CreatedOn,
                dataField: EPolicyFields.CreatedOn,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
                formatter: FormatElementTableCellDateTime
            },
            {
                text: EPolicyFieldLabels.UpdatedBy,
                dataField: EPolicyFields.UpdatedBy,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyFieldLabels.UpdatedOn,
                dataField: EPolicyFields.UpdatedOn,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
                formatter: FormatElementTableCellDateTime
            },
            {
                text: '',
                dataField: 'options',
                isDummyField: true,
                sort: false,
                allowFilter: false,
                formatter: (cellVal, row) => {
                    return this.renderOptions(row);
                },
            },
        ];

        return cols;
    }

    handleNewEditButtonClick(e, copy = false) {

        const { id, onElementChanged, onPersonChanged, canChangePolicyOwner } = this.props;
        const eId = T.IsDefined(e) ? e.Id : undefined;
        const copyId = copy ? eId : undefined;

        displayPolicyEditorDialog(this.refModal, `${this.name}_addEditPolicy`,
            copyId ? undefined : eId,
            id,
            copyId,
            canChangePolicyOwner,
            (updated) => {
                if (!updated) return;

                //reload the table in case changes affect sorting/filter
                this.refTable.reloadTable();

                if (T.IsDefined(onElementChanged)) {
                    onElementChanged(updated);
                }
            },
            (person) => {

                if (T.IsDefined(onPersonChanged)) {
                    onPersonChanged(person);
                }
            }
        );
    }

    handleDeleteButtonClick() {
        this.refModal.Alert('Operation is not yet supported');
    }

    handleViewMembersButtonClick() {
        this.refModal.Alert('Operation is not yet supported');
    }

    handleFormatUrl(page, pageSize, searchText, postData) {
        this.refAlert.hide();

        const { id } = this.props;
        const { includeRenewed, includeCompleted, assignedToMe, defaultSorted } = this.state;
        const b4Date = this.state[PROP_EXCLUDE_B4_DATE];

        const { sorts } = postData;
        if (T.IsArrayEmpty(sorts)) {
            postData.sorts = defaultSorted;
        } else if (T.IsArrayNonEmpty(defaultSorted)) {
            defaultSorted.forEach((x, i) => {
                const { dataField } = x;
                if (!sorts.find(x => x.dataField === dataField)) {
                    sorts.push(x);
                }
            });
        }

        const urlParams = [
            `page=${page}`,
            `pageSize=${pageSize}`,
            `filter=${searchText || ''}`,
            `includeRenewed=${includeRenewed}`,
            `includeCompleted=${T.DefaultBool(includeCompleted, false)}`,
            `assignedToMe=${T.DefaultBool(assignedToMe, false)}`,
            `excludeB4=${T.IsDefined(b4Date) ? new Date(b4Date).toUTCString() : ''}`,
            `ownerId=${id || ''}`
        ];

        const url = `${URL_POLICY}/paged?${urlParams.join('&')}`;
        return url;
    }

    renderOptions(element) {
        const { onOpen, canDelete } = this.props;
        const options = [
            {
                name: 'Edit',
                onClick: () => this.handleNewEditButtonClick(element),
                faIcon: faEdit
            }
        ];

        if (T.IsFunc(onOpen)) {
            options.push(
                {
                    name: 'Open',
                    onClick: () => onOpen(element),
                    faIcon: faFolderOpen
                });
        }
        options.push(
            {
                name: 'Copy',
                onClick: () => this.handleNewEditButtonClick(element, true),
                faIcon: faCopy
            });
        if (canDelete) {
            options.push(
                {
                    name: 'Delete',
                    onClick: () => this.handleDeleteButtonClick(element),
                    faIcon: faTrash
                });
        }
        return renderOptionsCell(`${this.name}-${element.Id}`, options);
    }

    renderHeaderSection() {
        const { includeRenewed, includeCompleted, assignedToMe } = this.state;
        const sectionPrefix = `${this.name}_pTable_hd`;
        const self = this;
        return (
            <React.Fragment>
                <Row>
                    <Col className='col-sm-6' style={{ display: 'inline-flex', textAlign: 'right' }}>
                        <Row>
                            <Col className='col-sm-5' style={{ paddingRight: '0' }}>
                                <span className="form-label">Hide policies before</span>
                            </Col>
                            <Col className='col-sm-5' style={{ paddingRight: '0' }}>
                                {
                                    buildInputDate(sectionPrefix, this.state, undefined,
                                        {
                                            name: PROP_EXCLUDE_B4_DATE,
                                            showLabel: false
                                        },
                                        (evt, propName, propVal) => {
                                            this.setState({ [PROP_EXCLUDE_B4_DATE]: propVal }, this.refTable.reloadTable);
                                        },
                                        false, 'col-sm-4')
                                }
                            </Col>
                            <Col className='col-sm-1' style={{ paddingRight: '0', paddingLeft: '0' }}>
                                {
                                    renderButton(`${sectionPrefix}_clear-dtg`, 'Clear',
                                        () => {
                                            if (!self.state[PROP_EXCLUDE_B4_DATE]) return;
                                            self.setState({ [PROP_EXCLUDE_B4_DATE]: undefined }, this.refTable.reloadTable);
                                        },
                                        false, undefined, faX)
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col className='col-sm-6'>
                        {
                            buildInputCheckBoxInlineRaw(sectionPrefix,
                                {
                                    name: 'includeRenewed',
                                    label: 'Include Renewed Policies',
                                    value: includeRenewed
                                },
                                (evt, propName, propVal) => {
                                    this.setState({ includeRenewed: propVal }, this.refTable.reloadTable);
                                })
                        }
                    </Col>
                </Row >
                <Row>
                    <Col className='col-sm-6'>
                        {
                            buildInputCheckBoxInlineRaw(sectionPrefix,
                                {
                                    name: 'assignedToMe',
                                    label: 'Assigned to me ONLY',
                                    value: assignedToMe
                                },
                                (evt, propName, propVal) => {
                                    this.setState({ assignedToMe: propVal }, this.refTable.reloadTable);
                                })
                        }
                    </Col>
                    <Col className='col-sm-6'>
                        {
                            buildInputCheckBoxInlineRaw(sectionPrefix,
                                {
                                    name: 'includeCompleted',
                                    label: 'Include Completed Policies',
                                    value: includeCompleted
                                },
                                (evt, propName, propVal) => {
                                    this.setState({ includeCompleted: propVal }, this.refTable.reloadTable);
                                })
                        }
                    </Col>
                </Row>
            </React.Fragment >
        );
    }

    render() {
        const { selectRowOptions } = this.props;
        const { pageOptions, defaultSorted } = this.state;
        const headerSection = this.renderHeaderSection();
        const sId = `${this.name}_eTable`;

        return (
            <React.Fragment>
                <ComponentModal size="xxl" link={e => this.refModal = e} />
                <BootAlert canToggle={false} link={(e) => this.refAlert = e} />
                <span>{this.name}</span>
                <ElementTable
                    keyField="Id"
                    id={sId}
                    key={sId}
                    name={sId}
                    data={this.state.data}
                    disableLoadOnInit={this.props.disableLoadOnInit}
                    link={(c) => { this.refTable = c; }}
                    columns={this.getColumns()}
                    options={{ noDataText: 'No elements found' }}
                    pageOptions={pageOptions}
                    defaultSorted={defaultSorted}
                    selectRowOptions={selectRowOptions}
                    headerSection={headerSection}
                    onFormatUrl={this.handleFormatUrl}
                    onNew={this.handleNewEditButtonClick}
                ></ElementTable>
            </React.Fragment>
        );
    }
}
