import React, { Component } from 'react';
import { ElementTable } from '../general/ElementTable';
import {
    FormatElementTableCellDateTime, FormatElementTableCellTextArea,
    ElementTablePageOptions, EElementTableColFilterType,
    TableColSortEntry
} from '../general/ElementTableUtil';
import ComponentModal from '../general/ComponentModal';
import * as T from '../utils/TypeUtil';

import { EPolicyNoteFields, EPolicyNoteFieldLabels, NewPolicyNote } from './EntityData';
import '../general/css/general.css';
import { fetchWebApi } from '../utils/ClientUtil';
import { URL_POLICY_NOTE } from '../AppConstants';
import { displayError } from '../utils/ExceptionUtil';
import BootAlert from '../general/BootAlert';
import { ETextEditorMode } from '../general/TextEditor';

/**
 * @param id [optional] ID of the policy ID, 
 * applicable when displaying policies related to an individual
 * @param name [optional] component name
 * @param ref [optional]
 * @param sizePerPage [optional] number of rows per page
 */
export class PolicyNoteTable extends Component {
    constructor(props) {
        super(props);

        this.mount = false;
        this.refModal = undefined;
        this.refModalError = undefined;
        this.refAlert = undefined;

        this.handleLoadTableData = this.handleLoadTableData.bind(this);
        this.handleNewEditButtonClick = this.handleNewEditButtonClick.bind(this);

        const { link, name, sizePerPage } = this.props;
        this.name = name || 'tablePolicyNote';

        this.state = {
            data: [],
            pageOptions: new ElementTablePageOptions(false, sizePerPage),
            defaultSorted: [
                new TableColSortEntry(EPolicyNoteFields.CreatedOn, true)
            ],
        };

        if (T.IsFunc(link)) {
            link(this);
        }
    }

    reloadTable() {
        this.refTable.reloadTable();
    }

    componentDidMount() {
        this.mount = true;
    }
    componentWillUnmount() {
        this.mount = false;
    }
    componentDidUpdate(prevProps, prevState) {
        const { sizePerPage } = this.props;

        if (prevProps.sizePerPage !== sizePerPage) {
            const { pageOptions } = this.state;
            const updatedPageOptions = {
                ...pageOptions,
                ...{ sizePerPage: sizePerPage }
            };
            this.setState({ pageOptions: updatedPageOptions });
        }
    }


    handleLoadTableData() {

        const { id } = this.props;

        this.refAlert.hide();

        const msg = 'Error retrieving policy notes.';
        fetchWebApi(`${URL_POLICY_NOTE}/list?policyId=${id || ''}`)
            .then((result) => {
                this.setState({ data: result });
            })
            .catch((error) => {
                displayError(this.refAlert, msg, error);
            });
    }

    handleNewEditButtonClick() {
        const { id } = this.props;
        let comment;
        this.refModal.InputTextDialog('Add Note', '', 'Note',
            (val) => { //onValueChanged
                comment = val;
            },
            () => { //onOK
                const body = NewPolicyNote(id, comment);

                const msg = 'Error saving note.';
                fetchWebApi(URL_POLICY_NOTE,
                    {
                        method: 'POST',
                        body: JSON.stringify(body)
                    },
                    { 'Content-Type': 'application/json' })
                    .then((result) => {
                        this.refModalError.Success('Successfully saved.', '',
                            () => {
                                this.refTable.reloadTable();
                                this.refModalError.close();
                                this.refModal.close();
                            });
                    })
                    .catch((error) => {
                        displayError(this.refModalError, msg, error);
                    });
            },
            () => { //onCancel
                this.refModal.close();
            },
            { inputMaxLen: 1000, inputRequired: true, mode: ETextEditorMode.TEXTAREA }
        );
    }

    getColumns() {
        const cols = [
            {
                text: EPolicyNoteFields.Id,
                dataField: EPolicyNoteFields.Id,
                hidden: true,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyNoteFields.Note,
                dataField: EPolicyNoteFieldLabels.Note,
                formatter: FormatElementTableCellTextArea,
                headerStyle: { width: '80%' },
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyNoteFieldLabels.CreatedBy,
                dataField: EPolicyNoteFields.CreatedBy,
                filter: EElementTableColFilterType.TEXT,
            },
            {
                text: EPolicyNoteFieldLabels.CreatedOn,
                dataField: EPolicyNoteFields.CreatedOn,
                formatter: FormatElementTableCellDateTime,
            }
        ];

        return cols;
    }

    render() {
        const { pageOptions, defaultSorted, data } = this.state;
        const sId = `${this.name}_eTable`;

        return (
            <React.Fragment>
                <ComponentModal size="xl" link={e => this.refModal = e} />
                <ComponentModal link={e => this.refModalError = e} />
                <BootAlert canToggle={false} link={(e) => this.refAlert = e} />
                <span>{this.name}</span>
                <ElementTable
                    keyField="Id"
                    id={sId}
                    key={sId}
                    name={sId}
                    data={data}
                    link={(c) => { this.refTable = c; }}
                    columns={this.getColumns()}
                    options={{ noDataText: 'No elements found' }}
                    pageOptions={pageOptions}
                    defaultSorted={defaultSorted}
                    onLoadTableData={this.handleLoadTableData}
                    onNew={this.handleNewEditButtonClick}
                ></ElementTable>
            </React.Fragment>
        );
    }
}
