import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

import ComponentModal from '../general/ComponentModal';
import {
    buildInputString, buildInputSelect, buildInputDate, buildInputPhone,
    buildInputSsn,
    renderFetchStatus,
    buildInputCheckBox
} from '../utils/EditorInputUtil';
import { fetchWebApi } from '../utils/ClientUtil';
import { displayError, getExceptionMessage } from '../utils/ExceptionUtil';
import * as T from '../utils/TypeUtil';
import {
    EAddressType, EGenderOptions, EMartialStatusOptions, EImmigrationStatusOptions
} from '../AppEnums';
import '../general/css/general.css';

import { faEye, faEdit, faCopy, faPlus } from '@fortawesome/free-solid-svg-icons';

import {
    EElementType,
    EPersonFields as EFields, PersonFieldInfos as Fields,
    EUserAddressFields,
    EPersonFields,
    NewUserAddress,
    NormalizeSsn
} from './EntityData';
import { formatPerson, POLICY_OWNER_PHONE_REQUIRED, validatePersonData } from './EntityEditorUtil';
import { AddressEditor } from './AddressEditor';
import { URL_PERSON as URL } from '../AppConstants';
import Spinner from 'reactstrap/lib/Spinner';
import BootAlert from '../general/BootAlert';
import { sanitizeJsonObject } from './EntityUtil';
import BootPanel from '../general/BootPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AttachmentTable } from './AttachmentTable';
import { PersonNoteTable } from './PersonNoteTable';
import { TabControl } from '../general/TabControl';
import { LogEntryTable } from './LogEntryTable';
import { EColor } from '../utils/DataFormatUtil';

export const EPersonEditorMode = {
    DEFAULT: 'DEFAULT',
    MEMBER: 'MEMBER',
}
const ETab = {
    ADDRESSES: 'Addresses',
    NOTES: 'Notes',
    ATTACHMENT: 'Attachments',
    LOGS: 'History'
}

/**
 * @param id ID of the individual to be displayed
 * @param name [optional] name of the component
 * @param onElementChanged [optional] raised when element data changed, not necessarily saved
 * @param onElementLoaded [optional] raised when view data is loaded
 * @param displayMode [optional]
 * @param disabled [optional]
 * @param inline [optional]
 */
export class PersonEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            data: {},
            id: undefined,
            activeTabId: ETab.ADDRESSES,
            errors: {},
            showNewAddressBtn: false
        };

        this.mount = false;
        this.refAlert = undefined;
        this.refModal = undefined;
        this.refNoteTable = undefined;
        this.refAttachmentTable = undefined;
        this.refAddresses = {};
        this.refLogTable = undefined;
        this.name = this.props.name || 'editorPerson';

        if (T.IsFunc(props.link)) {
            props.link(this);
        }

        this.onInputChanged = this.onInputChanged.bind(this);
        this.validateData = this.validateData.bind(this);
        this.handleSsnButtonClicked = this.handleSsnButtonClicked.bind(this);
        this.handleBtnAddNewAddressClicked = this.handleBtnAddNewAddressClicked.bind(this);
        this.handleBtnDeleteAddressClicked = this.handleBtnDeleteAddressClicked.bind(this);
        this.setViewData = this.setViewData.bind(this);
        this.fetchElementData = this.fetchElementData.bind(this);
    }

    reload() {
        this.fetchElementData();
    }

    componentWillUnmount() {
        this.mount = false;
    }

    componentDidMount() {
        this.mount = true;
        this.reload();
    }

    componentDidUpdate(prevProps, prevState) {
        const { activeTabId } = this.state;
        const { activeTabId: prevActiveTabId } = prevState;

        if (activeTabId !== prevActiveTabId) {
            switch (activeTabId) {
                case ETab.NOTES:
                    if (this.refNoteTable) this.refNoteTable.reloadTable();
                    break;
                case ETab.ATTACHMENT:
                    if (this.refAttachmentTable) this.refAttachmentTable.reloadTable();
                    break;
                case ETab.LOGS:
                    if (this.refLogTable) this.refLogTable.reloadTable();
                    break;
                default:
                    break;
            }
        }
    }

    fetchElementData() {

        this.setState({ loading: true, id: undefined }, () => {

            const { id, onElementLoaded } = this.props;
            const isExisting = T.IsDefined(id) && id !== T.EmptyGuid;
            const url = isExisting
                ? `${URL}/${id}`
                : `${URL}/create`;
            const msg = 'Error retrieving client data.';
            fetchWebApi(url)
                .then((result) => {
                    if (!this.mount) return;

                    this.setViewData(result, isExisting ? result.Id : undefined, () => {
                        this.setState({ loading: false }, onElementLoaded);
                    });
                })
                .catch((error) => {
                    if (!this.mount) return;
                    displayError(this.refAlert, msg, error);
                    getExceptionMessage(msg, error, (errorTxt) => {
                        this.setState({
                            loading: false,
                            loadError: errorTxt
                        });
                    });
                });
        });
    }

    setViewData(element, id, callback) {

        sanitizeJsonObject(element);

        const data = element || {};

        //normalize SSN
        const ssnSet = NormalizeSsn(data);

        if (!this.state.showNewAddressBtn) {
            const types = Object.values(EAddressType);
            const addresses = data[EFields.Addresses] || [];
            data[EFields.Addresses] = types.map((type) => {

                return addresses.find((x) => x[EUserAddressFields.Type] === type)
                    || NewUserAddress(type, data.Id);
            });
        }

        this.setState({
            id,
            data,
            editSsn: !ssnSet,
            origSsn4: data[EFields.Ssn4],
            activeTabId: T.IsDefined(id) && id !== T.EmptyGuid ? ETab.NOTES : ETab.ADDRESSES,
        }, () => {
            callback();
        });
    }

    validateData(callback) {

        this.setState({ errors: {} }); //Clear all errors

        const { data } = this.state;
        const postData = { ...data };
        const errors = {};

        // Validate data
        const isOwner = T.DefaultBool(postData[EFields.IsPolicyOwner], false);
        validatePersonData(postData, errors, isOwner);

        // Validate addresses
        const addresses = [];
        Object.keys(this.refAddresses).forEach((addrId) => {

            const editor = this.refAddresses[addrId];
            const addr = editor.validateData();
            if (!addr) {
                errors[addrId] = 'Error';
            }
            else if (T.IsDefined(addr[EUserAddressFields.Address])) {
                addresses.push(addr);
            }
        });
        postData[EFields.Addresses] = addresses;

        // Validate unique Home and Mailing addresses
        Object.values(EAddressType).forEach((type) => {
            const items = addresses.filter((x) => x[EUserAddressFields.Type] === type);
            if (items.length > 1) {
                postData[type] = `Duplicate ${type} addresses found`;
            }
        });

        if (Object.keys(errors).length > 0) {
            this.setState({ errors: errors });
            callback();
            return;
        }
        callback(postData);
    }

    onSave(callback) {

        this.validateData((postData) => {
            if (!postData) {
                return;
            }

            //POST
            const msg = 'Error saving user data.';
            fetchWebApi(URL,
                {
                    method: 'POST',
                    body: JSON.stringify(postData)
                },
                { 'Content-Type': 'application/json' })
                .then((result) => {
                    if (!this.mount) return;
                    this.setViewData(result, result.Id, () => {

                        this.refModal.Success('Successfully saved.', '',
                            () => {
                                this.refModal.close();
                                if (T.IsFunc(callback)) {
                                    callback(result);
                                }
                            });
                    });
                })
                .catch((error) => {
                    if (!this.mount) return;
                    displayError(this.refModal, msg, error);
                });
        });
    }

    onInputChanged(evt, field, value) {
        const { data } = this.state;
        if (data[field] === value) return;

        const newData = {
            ...data,
            ...{ [field]: value }
        };

        this.setState({
            data: newData
        }, () => {
            const { onElementChanged } = this.props;
            if (T.IsFunc(onElementChanged)) {
                onElementChanged(data);
            }
        });
    }

    handleSsnButtonClicked() {
        this.setState({ editSsn: true });
    }

    handleBtnAddNewAddressClicked() {

        const { data } = this.state;
        const addresses = data[EFields.Addresses] || [];
        const updated = [
            NewUserAddress(undefined, this.props.id),
            ...addresses
        ];

        data[EFields.Addresses] = updated;
        this.setState({ data });
    }

    handleBtnDeleteAddressClicked(addressId) {
        this.refModal.Confirm('Are you sure you want to delete this address?', '',
            () => { // OK
                const { data } = this.state;
                const addresses = data[EFields.Addresses] || [];
                const updated = addresses.filter((x) => x[EFields.Id] !== addressId);

                data[EFields.Addresses] = updated;
                this.setState({ data }, () => {
                    delete this.refAddresses[addressId];
                });
                this.refModal.close();
            },
            false, // Cancel
            'Yes',
            'No'
        );
    }

    renderAddressList(mode, disabled, canEditAddress = true) {
        const { data } = this.state;
        const addresses = data[EFields.Addresses] || [];

        return (
            <BootPanel head='Addresses' open>
                {
                    !disabled && canEditAddress &&
                    <Button className="d-inline-block mr-1 me-1 mb-2"
                        onClick={() => this.handleBtnAddNewAddressClicked()}
                        size="sm"
                        title="Add new address"
                        color={EColor.Primary}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                }
                {
                    T.IsArrayNonEmpty(addresses)
                    && addresses.map((addr, idx) => <React.Fragment>
                        {idx > 0 && <hr />}
                        {this.renderAddress(addr, disabled, true, this.handleBtnDeleteAddressClicked)}
                    </React.Fragment>)
                }
            </BootPanel>
        );
    }

    renderAddress(addr, disabled, typeVisible = false, onDelete = undefined) {
        const type = addr[EUserAddressFields.Type];
        const id = addr[EUserAddressFields.Id];
        const key = `${this.name}_address-${id}`;
        const { errors } = this.state;
        const typeError = errors[type];
        const isOptional = !typeVisible;

        const onAddressChanged = (updatedAddr) => {
            const { data } = this.state;
            const addresses = data[EFields.Addresses] || [];
            const old = addresses.find((x) => x[EFields.Id] === id);
            const idx = addresses.indexOf(old);
            if (idx >= 0) {
                addresses[idx] = updatedAddr;

                data[EFields.Addresses] = addresses;
                this.setState({ data });
            }
        };
        const handleBtnClick = T.IsFunc(onDelete)
            ? () => onDelete(id)
            : undefined;

        return (
            <Row key={`personAddr-row-${id}`}>
                <Col className='col-sm-12' key={`personAddr-col-${id}`}>
                    {renderFetchStatus(false, typeError)}
                    <AddressEditor type={type} key={key} name={key}
                        link={e => this.refAddresses[id] = e}
                        data={addr}
                        disabled={disabled}
                        optional={isOptional}
                        onDeleteButtonClicked={handleBtnClick}
                        typeVisible={typeVisible}
                        onChanged={onAddressChanged}
                    />
                </Col>
            </Row>
        );
    }

    renderAddresses(mode, disabled) {
        if (this.state.showNewAddressBtn) return this.renderAddressList();

        const { data } = this.state;
        const addresses = data[EFields.Addresses] || [];
        if (!T.IsArrayNonEmpty(addresses)) return;

        const types = Object.values(EAddressType);

        return types.map((type, idx) => {
            const addr = addresses.find((x) => x[EUserAddressFields.Type] === type);
            const key = `${this.name}_address-${type}`;
            return (
                <React.Fragment key={key + '-container'}>
                    {(idx !== 0) && <hr />}
                    {this.renderAddress(addr, disabled, false)}
                </React.Fragment>
            );
        });
    }

    renderUserInfo(mode, disabled) {
        const { data, errors, editSsn } = this.state;
        const policyOwner = data[EPersonFields.IsPolicyOwner];
        const ssn = data[EPersonFields.SsnHash];

        const keyPrefix = `${this.name}-`;

        const fieldPhone = Fields[EFields.PhoneNumber];
        fieldPhone.required = policyOwner && POLICY_OWNER_PHONE_REQUIRED;

        const ssnBtnOptions = [
            {
                name: disabled ? 'View' : 'Edit',
                faIcon: disabled ? faEye : faEdit,
                onClick: this.handleSsnButtonClicked,
            },
            {
                name: 'Copy',
                faIcon: faCopy,
                onClick: () => { navigator.clipboard.writeText(T.IsDefined(ssn) ? ssn : ''); },
                disabled: !T.IsDefined(ssn)
            }
        ];

        return (
            <React.Fragment>
                <Row className='mb-2'>
                    <Col className='col-sm-4'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.LastName],
                                this.onInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-4'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.FirstName],
                                this.onInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-4'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.MiddleName],
                                this.onInputChanged, disabled)
                        }
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col className='col-sm-2'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.Suffix],
                                this.onInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-3'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.NickName],
                                this.onInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputSelect(keyPrefix, data, errors, Fields[EFields.Gender],
                                EGenderOptions,
                                this.onInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-2'>
                        {
                            buildInputSelect(keyPrefix, data, errors, Fields[EFields.MaritalStatus],
                                EMartialStatusOptions,
                                this.onInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-3'>
                        {
                            buildInputDate(keyPrefix, data, errors, Fields[EFields.DOB],
                                this.onInputChanged, disabled)
                        }
                    </Col>
                </Row>
                {
                    mode === EPersonEditorMode.DEFAULT &&
                    <Row className='mb-2'>
                        <Col className='col-sm-8'>
                            {
                                buildInputString(keyPrefix, data, errors, Fields[EFields.Email],
                                    this.onInputChanged, disabled)
                            }
                        </Col>
                        <Col className='col-sm-4'>
                            {
                                buildInputPhone(keyPrefix, data, errors, fieldPhone,
                                    this.onInputChanged, disabled)
                            }
                        </Col>
                    </Row>
                }
                <Row className='mb-2'>
                    <Col className='col-sm-5'>
                        {
                            buildInputString(keyPrefix, data, errors, Fields[EFields.DriverLicense],
                                this.onInputChanged, disabled)
                        }
                    </Col>
                    <Col className='col-sm-3'>
                        {
                            buildInputSelect(keyPrefix, data, errors, Fields[EFields.ImmigrationStatus],
                                EImmigrationStatusOptions,
                                this.onInputChanged, disabled, true)
                        }
                    </Col>
                    <Col className='col-sm-4'>
                        {
                            (!editSsn)
                                ? buildInputString(keyPrefix, data, errors, Fields[EFields.Ssn4],
                                    undefined, disabled, undefined, undefined,
                                    ssnBtnOptions)
                                : buildInputSsn(keyPrefix, data, errors, Fields[EFields.SsnHash], // SSN: PersonEditor
                                    this.onInputChanged, disabled)
                        }
                    </Col>
                </Row>
                {
                    mode === EPersonEditorMode.DEFAULT &&
                    <Row>
                        <Col className='col-sm-10'>
                            {
                                buildInputString(keyPrefix, data, errors, Fields[EFields.Company],
                                    this.onInputChanged, disabled)
                            }
                        </Col>
                        <Col className='col-sm-2'>
                            {
                                buildInputCheckBox(keyPrefix, data, errors, Fields[EFields.DNC],
                                    this.onInputChanged, disabled)
                            }
                        </Col>
                    </Row>
                }
            </React.Fragment>
        );
    }

    buildTabInfos(disabled, isReadOnly, personId, tabId, addressMode = undefined) {

        const { hideAttachments, hideNotes, hideLogs } = this.props;

        const tabs = [];
        if (addressMode) {

            tabs.push({
                id: ETab.ADDRESSES,
                title: ETab.ADDRESSES,
                body: this.renderAddresses(addressMode, disabled)
            });
        }

        if (!isReadOnly) {
            if (!hideNotes) {
                tabs.push(
                    {
                        id: ETab.NOTES,
                        title: ETab.NOTES,
                        body: (
                            <PersonNoteTable name={`${this.name}_userNotes`}
                                id={personId}
                                elementName={formatPerson(this.state.data)}
                                disabled={disabled}
                                disableLoadOnInit={tabId !== ETab.NOTES}
                                link={(e) => this.refNoteTable = e}
                            />
                        )
                    });
            }

            if (!hideAttachments) {
                tabs.push(
                    {
                        id: ETab.ATTACHMENT,
                        title: ETab.ATTACHMENT,
                        body: (
                            <AttachmentTable ownerId={personId} parentType={EElementType.User}
                                name={`${this.name}_files`}
                                link={(e) => this.refAttachmentTable = e}
                                disableLoadOnInit={tabId !== ETab.ATTACHMENT}
                            />
                        )
                    });
            }

            if (!hideLogs) {
                tabs.push(
                    {
                        id: ETab.LOGS,
                        title: ETab.LOGS,
                        body: (
                            <LogEntryTable parentId={personId} parentType={EElementType.User}
                                name='userdash_logs'
                                link={(e) => this.refLogTable = e}
                                disableLoadOnInit={tabId !== ETab.LOGS}
                            />
                        )
                    });
            }
        }

        return tabs;
    }

    render() {
        const { displayMode, disabled, inline } = this.props;
        const { loading, loadError, id, activeTabId } = this.state;

        const mode = displayMode || EPersonEditorMode.DEFAULT;
        const showAddressTab = !inline;
        const readOnly = !id || id === T.EmptyGuid;
        const tabs = this.buildTabInfos(disabled, readOnly, id, activeTabId, showAddressTab ? mode : undefined)
        const selectedTabId = !showAddressTab && activeTabId === ETab.ADDRESSES ? ETab.NOTES : activeTabId;

        const body = (mode === EPersonEditorMode.MEMBER)
            ? this.renderUserInfo(mode, disabled)
            : inline ?
                (
                    <Row className=''>
                        <Col className='col-sm-7'>
                            {this.renderUserInfo(mode, disabled)}
                        </Col>
                        <Col className='col-sm-5'>
                            {this.renderAddresses(mode, disabled)}
                        </Col>
                    </Row>
                )
                : (
                    <React.Fragment>
                        {this.renderUserInfo(mode, disabled)}
                    </React.Fragment>
                );

        return (
            <React.Fragment>
                {loading && <Spinner />}
                <ComponentModal link={e => this.refModal = e} />
                <BootAlert canToggle={false} link={(e) => this.refAlert = e} />
                {
                    !(loading || loadError) &&
                    body
                }
                {
                    T.IsArrayNonEmpty(tabs) &&
                    <Row className='mt-2'>
                        <Col style={{ backgroundColor: 'aliceblue' }}>
                            <TabControl tabInfos={tabs} activeTabId={selectedTabId}
                                onTabSelectionChanged={(tabId) => {
                                    this.setState({ activeTabId: tabId });
                                }}
                            />
                        </Col>
                    </Row>
                }
            </React.Fragment>
        );
    }
}

PersonEditor.propTypes = {
    name: PropTypes.string,
    link: PropTypes.func,
    id: PropTypes.string,
    data: PropTypes.object,
    disabled: PropTypes.bool,
    onElementChanged: PropTypes.func,
    onElementLoaded: PropTypes.func,
    displayMode: PropTypes.string,
    inline: PropTypes.bool,
    hideAttachments: PropTypes.bool,
    hideNotes: PropTypes.bool,
    hideLogs: PropTypes.bool,
}
