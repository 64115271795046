import React from 'react';
import { Alert } from 'reactstrap';
import * as T from '../utils/TypeUtil';

import './css/general.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faExclamationTriangle,
    faTimesCircle,
    faInfoCircle,
    faQuestionCircle,
    faCheckCircle
} from '@fortawesome/free-solid-svg-icons';

import { v4 as uuid } from 'uuid';
import { EColor } from '../utils/DataFormatUtil';

/**
 * @param ref
 * @param id
 * @param icon Default is the info icon.
 * @param color Default is info. Options: see EColor
 * @param scale Default is 4
 * @param message
 * @param footer
 * @param visible
 * @param canToggle
 * @param onDismiss
 */
export default class BootAlert extends React.Component {
    constructor(props) {
        super(props);

        // Set up the capability for this component to be controlled from a parent component.
        const {
            id, link,
            icon, color, scale,
            message, footer, visible, canToggle, type
        } = this.props;

        if (T.IsFunc(link)) { link(this); }

        const initState =
        {
            id: id || uuid(),
            visible: T.IsDefined(visible) || false,
            canToggle: T.IsDefined(canToggle) ? canToggle : true,
            icon: icon || 'info',
            scale: scale || 4,
            color: color || EColor.Info,
            message: message || '',
            footer: footer || ''
        };
        this.state = initState;

        this.onDismiss = this.onDismiss.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {
            id, link,
            icon, color, scale,
            message, footer, visible,
            canToggle,
        } = this.props;
        const {
            id: prevId, link: prevLink,
            icon: prevIcon, color: prevColor, scale: prevScale,
            message: prevMessage, footer: prevFooter, visible: prevVisible,
            canToggle: prevCanToggle
        } = prevProps;

        if (link !== prevLink && T.IsFunc(link)) {
            link(this);
        }
        const newStates = {};
        if (id !== prevId) newStates.id = id || uuid();
        if (icon !== prevIcon) newStates.icon = icon || 'info';
        if (color !== prevColor) newStates.color = color || EColor.Info;
        if (scale !== prevScale) newStates.scale = scale || 4;
        if (message !== prevMessage) newStates.message = message || '';
        if (footer !== prevFooter) newStates.footer = footer || '';
        if (visible !== prevVisible) newStates.visible = T.IsDefined(visible) || false;
        if (canToggle !== prevCanToggle) newStates.canToggle = T.IsDefined(canToggle) ? canToggle : true;

        if (Object.keys(newStates).length > 0) {
            this.setState(newStates);
        }

    }

    hide() {
        this.setState({ visible: false });
    }

    /// Renders an info message.
    Info(message, note = '') {
        this.setState(
            {
                visible: true,
                icon: 'info',
                color: EColor.Info,
                message: message,
                footer: note
            });
    }

    /// Renders a question
    Question(question, message) {
        this.setState(
            {
                visible: true,
                icon: 'question',
                color: EColor.Primary,
                message: message,
                footer: question
            });
    }

    /// Renders a success message
    Success(message, note = '') {
        this.setState(
            {
                visible: true,
                icon: 'success',
                color: EColor.Success,
                message: message,
                footer: note
            });
    }

    /// Renders a warning message
    Warning(message, warningName) {
        this.setState(
            {
                visible: true,
                icon: 'warning',
                color: EColor.Warning,
                message: message,
                footer: warningName
            });
    }

    /// Renders an error message
    Error(message, errorName) {
        this.setState(
            {
                visible: true,
                icon: 'error',
                color: EColor.Danger,
                message: message,
                footer: errorName
            });
    }

    // Appears like the error message only that the footer is an optional note.
    Danger(message, note = '') {
        this.setState(
            {
                visible: true,
                icon: 'error',
                color: EColor.Danger,
                message: message,
                footer: note
            });
    }

    // Make the icon scale class string
    getScaleClass(scale) { return 'fa-' + scale + 'x'; }

    // Toggles off the alert, and calls external onDismiss function if set in the props
    onDismiss(trigger = true) {
        this.setState({ visible: false });
        if ((typeof (this.props.onDismiss) === 'function') && (trigger === true)) {
            this.props.onDismiss(this.state);
        }
    }

    /// This function determines the currently supported set of icons
    getIcon(name) {
        // Default Icon is the info circle with info color
        let iconObject = { name: faInfoCircle, color: EColor.Info };

        // Recognizing the question icon
        if (name.includes('question') || name.includes('primary')) {
            iconObject = { name: faQuestionCircle, color: EColor.Primary };
        }
        else if (name.includes('success')) {
            iconObject = { name: faCheckCircle, color: EColor.Success };
        }
        // Recognizing the exclamation triangle
        else if (
            name.includes('exclamation') ||
            name.includes('triangle') ||
            name.includes('warning')) {
            iconObject = { name: faExclamationTriangle, color: EColor.Warning };
        }
        // Recognizing the danger circle
        else if (name.includes('danger') ||
            name.includes('error')) {
            iconObject = { name: faTimesCircle, color: EColor.Danger };
        }

        return iconObject;
    }

    render() {
        // Get the current icon object
        let currIcon = this.getIcon(this.state.icon);

        // Initialing footer elemet.
        let footer = (<div className="boot_4_alert_footer"></div>);

        if (this.state.footer !== '') {
            footer = !Array.isArray(this.state.footer)
                ? (
                    <div className="boot_4_alert_footer">
                        <hr />
                        <p className="mb-0">{this.state.footer}</p>
                    </div>
                )
                : (
                    <div className="boot_4_alert_footer">
                        <hr />
                        <ul className="mb-0">
                            {this.state.footer.map(x => <li key={`item-${x}`}>{x}</li>)}
                        </ul>
                    </div>
                );
        }

        return (
            <Alert color={this.state.color} isOpen={this.state.visible} toggle={(this.state.canToggle ? this.onDismiss : undefined)}>
                <div>
                    <p>
                        <FontAwesomeIcon icon={currIcon.name}
                            className={
                                this.classPrefix + ' text-' + currIcon.color + ' ' +
                                this.getScaleClass(this.state.scale)}
                        />
                        {this.state.message}
                    </p>
                </div>
                {footer}
            </Alert>
        );
    }
}