

export const getExceptionMessage = function getErrorMessage(defaultErrorMsg, error, callback) {

    const notifyListener = (msg, innerErr) => {
        console.error(defaultErrorMsg, error);
        if (innerErr) {
            console.error(`${defaultErrorMsg} ServerError`, innerErr);
        }
        callback(msg || defaultErrorMsg);
    };

    if (!error) {
        notifyListener();
    }
    else if (error.name === 'HttpError') {
        if (error.serverError) {
            error.serverError
                .then((err) => {
                    const errMsg = err.message || err.title;
                    notifyListener(errMsg, err);
                })
                .catch((catchErr) => {
                    notifyListener(error.message);
                });
        }
        else {
            notifyListener(error.message);
        }
    }
    else {
        console.error(`${defaultErrorMsg}. Cause=${error.message}`);
        notifyListener(error.message);
    }
};

export const displayError = function displayError(linkAlert, msg, error, onClosed = false) {
    if (linkAlert) {
        getExceptionMessage(msg, error, (errorTxt) => {
            if (errorTxt === msg) {
                linkAlert.Error(errorTxt, '', false, onClosed);
            } else {
                linkAlert.Error(msg, errorTxt, false, onClosed);
            }
        });
    }
};
