import React, { Component } from 'react';
import { Row, Col, Spinner } from 'reactstrap';
import ComponentModal from '../general/ComponentModal';

import * as T from '../utils/TypeUtil';
import { PersonTable } from './PersonTable';
import { PolicyTable } from './PolicyTable';
import { AddressTable } from './AddressTable';
import { PersonWorkspace } from './PersonWorkspace';
import { TabControl } from '../general/TabControl';

import '../general/css/general.css';
import { EElementType, EPersonFields, EPolicyFields, ETaskFields, EUserAddressFields } from './EntityData';
import { renderCloseButton } from '../utils/EditorInputUtil';
import { TaskTable } from './TaskTable';
import { ERole, ERoleValue } from '../AppEnums';
import { LogEntryTable } from './LogEntryTable';
import { AppUserTable } from './AppUserTable';
import authService from '../api-authorization/AuthorizeService';
import { fetchUserRole } from './EntityUtil';
import { EFetchError, fetchCurrentUser } from '../utils/ClientUtil';
import BootAlert from '../general/BootAlert';
import { EColor } from '../utils/DataFormatUtil';
import { SettingTable } from './SettingTable';
import wsConnection from '../../ws/WsConnection';
import { EEvent, subscribe, unsubscribe } from '../../events';
import { CarrierTable } from './CarrierTable';
import { TableColSortEntry } from '../general/ElementTableUtil';

export const SESSION_TIME_OUT = 'Session timed out. Please try logout and then login again!';

const ETab = {
    TASKS: 'Tasks',
    CONTACTS: 'Contacts',
    POLICIES: 'Policies',
    ADDRESSES: 'Addresses',
    LOGS: 'History',
    USERS: 'Users',
    SETTINGS: 'Settings',
    CARRIERS: 'Carriers',
};

export class UserDashboard extends Component {

    constructor(props) {
        super(props);

        this.mount = false;
        this.refClientEditors = {};
        this.refModal = undefined;

        this.refTaskTable = undefined;
        this.refPersonTable = undefined;
        this.refPolicyTable = undefined;
        this.refAddressTable = undefined;
        this.refLogTable = undefined;
        this.refUserTable = undefined;
        this.refSettingTable = undefined;
        this.refCarrierTable = undefined;

        this.state = {
            loading: true,
            activeTabId: ETab.POLICIES,
            prevActiveTabId: undefined,
            isAdmin: false,
            clients: []
        };

        if (T.IsFunc(props.link)) {
            props.link(this);
        }

        this.handleTabSelectionChanged = this.handleTabSelectionChanged.bind(this);

        this.renderTasksTab = this.renderTasksTab.bind(this);
        this.renderContactsTab = this.renderContactsTab.bind(this);
        this.renderPoliciesTab = this.renderPoliciesTab.bind(this);
        this.renderAddressesTab = this.renderAddressesTab.bind(this);
        this.renderAppUserTab = this.renderAppUserTab.bind(this);
        this.renderLogsTab = this.renderLogsTab.bind(this);
        this.renderSettingsTab = this.renderSettingsTab.bind(this);
        this.renderCarriersTab = this.renderCarriersTab.bind(this);

        this.renderClientTab = this.renderClientTab.bind(this);
        this.handleCloseTabClick = this.handleCloseTabClick.bind(this);

        this.handleOpenPolicy = this.handleOpenPolicy.bind(this);
        this.handleOpenPerson = this.handleOpenPerson.bind(this);

        this.reloadClientEditor = this.reloadClientEditor.bind(this);

        this.handlePolicyChanged = this.handlePolicyChanged.bind(this);
        this.handlePersonChanged = this.handlePersonChanged.bind(this);
        this.handleTaskChanged = this.handleTaskChanged.bind(this);
        this.handleAddressChanged = this.handleAddressChanged.bind(this);

        this.refreshElementTable = this.refreshElementTable.bind(this);
        this.handleWsElementChangedEvent = this.handleWsElementChangedEvent.bind(this);
        this.handleWsElementImportedEvent = this.handleWsElementImportedEvent.bind(this);
    }

    componentWillUnmount() {
        this.mount = false;
        authService.unsubscribe(this._subscription);

        wsConnection.stop();
        unsubscribe(EEvent.ELEMENT_CHANGED, this.handleWsElementChangedEvent);
        unsubscribe(EEvent.ELEMENT_IMPORTED, this.handleWsElementChangedEvent);
    }

    componentDidMount() {
        this.mount = true;
        this._subscription = authService.subscribe(() => this.populateUserInfo());

        this.populateUserInfo();

        wsConnection.start();
        subscribe(EEvent.ELEMENT_CHANGED, this.handleWsElementChangedEvent);
        subscribe(EEvent.ELEMENT_IMPORTED, this.handleWsElementChangedEvent);
    }

    componentDidUpdate(prevProps, prevState) {
        const { activeTabId } = this.state;
        const { activeTabId: prevActiveTabId } = prevState;

        if (activeTabId !== prevActiveTabId) {

            switch (activeTabId) {
                case ETab.TASKS:
                    if (this.refTaskTable) this.refTaskTable.reloadTable();
                    break;
                case ETab.CONTACTS:
                    if (this.refPersonTable) this.refPersonTable.reloadTable();
                    break;
                case ETab.POLICIES:
                    if (this.refPolicyTable) this.refPolicyTable.reloadTable();
                    break;
                case ETab.ADDRESSES:
                    if (this.refAddressTable) this.refAddressTable.reloadTable();
                    break;
                case ETab.USERS:
                    if (this.refUserTable) this.refUserTable.reloadTable();
                    break;
                case ETab.SETTINGS:
                    if (this.refSettingTable) this.refSettingTable.reloadTable();
                    break;
                case ETab.CARRIERS:
                    if (this.refCarrierTable) this.refCarrierTable.reloadTable();
                    break;
                case ETab.LOGS:
                    if (this.refLogTable) this.refLogTable.reloadTable();
                    break;
                default:
                    this.reloadClientEditor(activeTabId);
                    break;
            }
        }
    }

    handleWsElementChangedEvent(evt) {
        const { type: eType, id: eId, isDeleted: eDeleted } = evt.detail || {};
        console.log(`handleWsElementChangedEvent [type=${eType}, id=${eId}, isDeleted=${eDeleted}]`);
        this.refreshElementTable(eType);
    }
    handleWsElementImportedEvent(evt) {
        const { type: eType } = evt.detail || {};
        console.log(`handleWsElementImportedEvent [type=${eType}]`);
        this.refreshElementTable(eType);
        this.refreshElementTable(EElementType.AppUserActivity);
    }
    refreshElementTable(eType) {
        const { activeTabId } = this.state;
        switch (eType) {
            case EElementType.AppUser:
                if (activeTabId === ETab.USERS) this.refUserTable.reloadTable();
                break;
            case EElementType.AppUserActivity:
                if (activeTabId === ETab.LOGS) this.refLogTable.reloadTable();
                break;
            case EElementType.Attachment:
                break;
            case EElementType.Policy:
                if (activeTabId === ETab.POLICIES) this.refPolicyTable.reloadTable();
                break;
            case EElementType.PolicyMember:
                break;
            case EElementType.PolicyNote:
                break;
            case EElementType.Task:
                if (activeTabId === ETab.TASKS) this.refTaskTable.reloadTable();
                break;
            case EElementType.TaskComment:
                break;
            case EElementType.User:
                if (activeTabId === ETab.CONTACTS) this.refPersonTable.reloadTable();
                break;
            case EElementType.UserAddress:
                break;
            case EElementType.UserNote:
                break;
            case EElementType.UserUserRel:
                break;
            case EElementType.Setting:
                if (activeTabId === ETab.SETTINGS) this.refSettingTable.reloadTable();
                break;
            case EElementType.Carrier:
                if (activeTabId === ETab.CARRIERS) this.refCarrierTable.reloadTable();
                break;
            default:
                break;
        }
    }

    async populateUserInfo() {
        try {
            const user = await fetchCurrentUser();
            const updated = {
                ...user,
                ...{ loading: true }
            }
            this.setState(updated, () => {
                const { loginUserId: id } = user;
                if (id) {
                    fetchUserRole(id, (role, error) => {
                        this.setState({
                            loading: false,
                            isAdmin: ERoleValue[role] >= ERoleValue.Administrator,
                            loginUserRole: role,
                            loginError: error
                        });
                    });
                }
            });
        } catch (error) {
            console.log('populateUserInfo ERROR', error);
            this.setState({
                loginUserId: undefined,
                loginUserName: undefined,
                loading: false,
                isAdmin: false,
                loginUserRole: undefined,
                loginError: undefined
            });
        }
    }

    handleTabSelectionChanged(tabId) {

        if (!Object.values(ETab).find(x => x === tabId)
            && !this.refClientEditors[tabId]) {
            return;
        }
        const { activeTabId: prevTabId } = this.state;

        this.setState({
            activeTabId: tabId,
            prevActiveTabId: prevTabId
        });
    }

    handleOpenPolicy(element) {
    }

    handleOpenPerson(element) {
        const eId = element[EPersonFields.Id];
        const { clients } = this.state;

        const x = T.IsArrayNonEmpty(clients)
            ? clients.find(x => x[EPersonFields.Id] === eId)
            : undefined;
        if (x) {
            var idx = clients.indexOf(x);
            clients[idx] = element;
        }
        else {
            //not yet open, add and make active
            clients.push(element);
        }

        this.setState({
            activeTabId: eId,
            clients: clients.slice()
        });
    }

    handleCloseTabClick(elementId) {
        const { clients, prevActiveTabId } = this.state;

        const updated = clients.filter(x => x[EPersonFields.Id] !== elementId);
        if (updated.length !== clients.length) {

            delete this.refClientEditors[elementId];
            this.setState(
                {
                    activeTabId: prevActiveTabId,
                    clients: updated
                }
            );
        }
    }

    handlePersonChanged(e, sender = undefined) {
        if (!e || !this.mount) return;
        const eId = e.Id;

        if (sender === this.refPersonTable) {
            this.reloadClientEditor(eId);
        }
        else {
            // this.refPersonTable.reloadTable(); //OTHER TAB data changed
        }
    }

    handlePolicyChanged(e, sender = undefined) {
        if (!e || !this.mount) return;

        if (this.refPolicyTable && this.refPolicyTable === sender) {
            this.reloadClientEditor(e[EPolicyFields.OwnerId]);
        }
        else {
            // this.refPolicyTable.reloadTable(); //OTHER TAB data changed
        }
    }

    handleTaskChanged(e, sender = undefined) {
        if (!e || !this.mount) return;

        if (this.refTaskTable && this.refTaskTable === sender) {
            this.reloadClientEditor(e[ETaskFields.UserId]);
        }
        else {
            // this.refTaskTable.reloadTable(); //OTHER TAB data changed
        }
    }

    handleAddressChanged(e, sender = undefined) {
        if (!e || !this.mount) return;

        if (this.refAddressTable && this.refAddressTable === sender) {
            this.reloadClientEditor(e[EUserAddressFields.UserId]);
        }
        else {
            // this.refAddressTable.reloadTable(); //OTHER TAB data changed
        }
    }

    reloadClientEditor(contactId) {
        const editor = this.refClientEditors[contactId];
        if (editor) {
            editor.reload();
        }
    }

    renderTasksTab(loadOnInit) {
        return (
            <TaskTable canDelete={false} name='userdash_tasks'
                loginUserId={this.state.loginUserId}
                disableLoadOnInit={!loadOnInit}
                assignedToMe={true}
                link={(e) => this.refTaskTable = e}
                canGoToContact={false}
                defaultSorted={[
                    new TableColSortEntry(ETaskFields.CreatedOn, true)
                ]}
                onElementChanged={(e) => this.handleTaskChanged(e, this.refTaskTable)}
            />);
    }

    renderContactsTab(loadOnInit) {
        return (
            <PersonTable canDelete={false} name='userdash_persons'
                loginUserId={this.state.loginUserId}
                disableLoadOnInit={!loadOnInit}
                link={(e) => this.refPersonTable = e}
                onElementChanged={(e) => this.handlePersonChanged(e, this.refPersonTable)}
                onOpen={this.handleOpenPerson}
            />);
    }

    renderPoliciesTab(loadOnInit) {
        return (<PolicyTable canDelete={false} name='userdash_policies'
            canChangePolicyOwner={true}
            assignedToMe={!this.state.isAdmin}
            loginUserId={this.state.loginUserId}
            disableLoadOnInit={!loadOnInit}
            link={(e) => this.refPolicyTable = e}
            onElementChanged={(e) => this.handlePolicyChanged(e, this.refPolicyTable)}
        // onOpen={this.handleOpenPolicy}
        />);
    }

    renderAddressesTab(loadOnInit) {
        return (
            <AddressTable canDelete={false} name='userdash_addresses'
                loginUserId={this.state.loginUserId}
                disableLoadOnInit={!loadOnInit}
                link={(e) => this.refAddressTable = e}
                onElementChanged={(e) => this.handleAddressChanged(e, this.refAddressTable)}
                onOpen={this.handleOpenAddress}
            />);
    }

    renderAppUserTab(loadOnInit) {
        return (
            <AppUserTable name='userdash_users'
                loginUserId={this.state.loginUserId}
                disableLoadOnInit={!loadOnInit}
                link={(e) => this.refUserTable = e}
            />
        );
    }

    renderLogsTab(loadOnInit) {
        return (
            <LogEntryTable name='userdash_logs'
                loginUserId={this.state.loginUserId}
                disableLoadOnInit={!loadOnInit}
                link={(e) => this.refLogTable = e}
            />
        );
    }

    renderSettingsTab(loadOnInit) {
        return (
            <SettingTable name='userdash_settings'
                disableLoadOnInit={!loadOnInit}
                link={(e) => this.refSettingTable = e}
            />
        );
    }

    renderCarriersTab(loadOnInit) {
        return (
            <CarrierTable name='userdash_carriers'
                disableLoadOnInit={!loadOnInit}
                link={(e) => this.refCarrierTable = e}
            />
        );
    }

    renderClientTab(person) {
        const id = person[EPersonFields.Id];
        const first = person[EPersonFields.FirstName];
        const last = person[EPersonFields.LastName];

        const renderTitle = () => {
            return (
                <span style={{ 'verticalAlign': 'middle' }}>
                    {`${last}, ${first}`}
                    {renderCloseButton('', () => { this.handleCloseTabClick(id) }, 'ml-2')}
                </span>
            );
        }
        return {
            id,
            title: renderTitle(),
            body: (
                <PersonWorkspace id={id} link={(e) => this.refClientEditors[id] = e}
                    loginUserId={this.state.loginUserId}
                    name={`userdash_personWS-${id}`}
                    onPersonChanged={(e) => this.handlePersonChanged(e)} />
            ),
        };
    }

    buildTabInfos(isAdmin, currentTabId) {
        const tabs = [
            {
                id: ETab.TASKS,
                title: ETab.TASKS,
                body: this.renderTasksTab(currentTabId === ETab.TASKS),
            },
            {
                id: ETab.POLICIES,
                title: ETab.POLICIES,
                body: this.renderPoliciesTab(currentTabId === ETab.POLICIES),
            },
            {
                id: ETab.CONTACTS,
                title: ETab.CONTACTS,
                body: this.renderContactsTab(currentTabId === ETab.CONTACTS),
            },
            // {
            //     id: ETab.ADDRESSES,
            //     title: ETab.ADDRESSES,
            //     body: this.renderAddressesTab,
            // }
        ];

        if (isAdmin) {
            tabs.push(
                {
                    id: ETab.USERS,
                    title: ETab.USERS,
                    body: this.renderAppUserTab(currentTabId === ETab.USERS),
                });
            tabs.push(
                {
                    id: ETab.CARRIERS,
                    title: ETab.CARRIERS,
                    body: this.renderCarriersTab(currentTabId === ETab.CARRIERS),
                });
            tabs.push(
                {
                    id: ETab.SETTINGS,
                    title: ETab.SETTINGS,
                    body: this.renderSettingsTab(currentTabId === ETab.SETTINGS),
                });
        }
        tabs.push(
            {
                id: ETab.LOGS,
                title: ETab.LOGS,
                body: this.renderLogsTab(currentTabId === ETab.LOGS),
            }
        );

        const { clients } = this.state;
        clients.forEach(x => {
            tabs.push(this.renderClientTab(x));
        });
        return tabs;
    }

    render() {
        const {
            loading,
            activeTabId,
            isAdmin,
            loginUserRole, loginUserName, loginError
        } = this.state;

        const tabInfos = this.buildTabInfos(isAdmin, activeTabId);
        const sRole = loginUserRole ? ` | ${loginUserRole}` : '';
        const sInfo = loginUserName
            ? `${loginUserName}${sRole}`
            : undefined;
        let body = undefined;
        if (!loading) {
            if (loginError) {
                const sErr = loginError === EFetchError.Unauthorized
                    ? SESSION_TIME_OUT
                    : loginError;
                body = (
                    <BootAlert key="modal_alert_unauth"
                        canToggle={false}
                        icon="warning"
                        visible={true}
                        message={sErr}
                        color={EColor.Warning}
                    />
                );
            } else if (loginUserRole && loginUserRole !== ERole.Unknown) {
                body = (
                    <Row className='mb-2'>
                        <Col style={{ backgroundColor: 'aliceblue' }}>
                            <TabControl tabInfos={tabInfos} activeTabId={activeTabId}
                                onTabSelectionChanged={this.handleTabSelectionChanged}
                            />
                        </Col>
                    </Row>
                );
            }
        }

        return (
            <React.Fragment>
                <ComponentModal link={e => this.refModal = e} />
                {sInfo && <span className='text-success'>{sInfo}</span>}
                {loading && <Spinner />}
                {body}
            </React.Fragment>
        );
    }
}
