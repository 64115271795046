export const URL_LOG = 'api/activity';

export const URL_ADDRESS = 'api/address';
export const URL_PERSON = 'api/people';
export const URL_PERSON_NOTE = 'api/peopleNote';

export const URL_POLICY = 'api/policy';
export const URL_POLICY_NOTE = 'api/policyNote';

export const URL_USER = 'api/login';

export const URL_TASK = 'api/task';
export const URL_TASK_NOTE = 'api/taskNote';

export const URL_ATTACHMENT = 'api/attachment';
export const URL_SETTING = 'api/appSetting';
export const URL_VIEW_SETTINGS = 'api/viewSettings';
export const URL_CARRIER = 'api/carrier';

export const INVALID_BIRTH_YEAR = 1600;