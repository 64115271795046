import React, { useState } from 'react';
import {
    Button,
    Input,
    Label,
    FormFeedback,
    InputGroup,
    FormText,
} from 'reactstrap';

import * as T from './TypeUtil';
import { EColor, stringComparer } from './DataFormatUtil';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import InputMask from 'react-input-mask';

import '../general/css/general.css';
import './css/editorInputUtil.css';
import { renderOptionsCell } from '../user/EntityEditorUtil';
import { Autocomplete, TextField } from '@mui/material';

/////////////////////////

export const renderCloseButton = (keyPrefix, onClick,
    className = '', disabled) => {
    return (
        <button className={`btn close ${className}`}
            aria-label='Close'
            onClick={() => onClick()}
            size="sm"
            disabled={disabled}>
            <span aria-hidden='true'>×</span>
        </button>
    );
};

export function renderButton(
    key,
    label,
    onClick,
    disabled = false,
    className = '',
    faIcon = undefined,
) {
    return (
        <Button className={`${className} d-inline-block mr-1 me-1`}
            key={key}
            onClick={() => onClick()}
            size="sm"
            title={label}
            aria-label={label}
            disabled={disabled}
            color={disabled ? EColor.Secondary : EColor.Primary}
        >
            {faIcon ? < FontAwesomeIcon icon={faIcon} /> : label}
        </Button >
    );
}

/////////////////////////
export function objectToSelectOptions(enumType, enumLabels) {
    const v = Object.keys(enumType).map(key => {
        return {
            Id: enumType[key],
            Name: (T.IsDefined(enumLabels) ? enumLabels[key] : undefined)
                || enumType[key]
        };
    });
    return v;
}

export function removeJSONIdentifier(obj) {
    if (!T.IsDefined(obj)) return;

    if (T.IsArray(obj)) {
        obj.forEach((x) => {
            removeJSONIdentifier(x);
        });
        return;
    }
    delete obj.$id;

    Object.keys(obj).forEach((prop) => {
        const x = obj[prop];
        if (T.IsObject(x)) {
            removeJSONIdentifier(x);
        }
    });
}

export function buildFormFeedback(propName, error) {
    return (
        <FormFeedback invalid="true" key={`${propName}-feedback`}>
            {error}
        </FormFeedback>
    );
}

export function buildInputLabel(label, required, unit, forName = undefined, boldText = false) {
    if (T.stringIsNullOrEmpty(label)) return;

    let lb = label;
    if (unit) {
        lb += ` (${unit})`;
    }
    let styles = undefined;
    let isReqd = required === true;
    if (isReqd) {
        lb += '*';
    }
    if (boldText || isReqd) {
        styles = { fontWeight: 'bold' };
    }
    return (<Label for={forName} style={styles}>{lb}</Label>);
}

export function buildInputNumeric(
    keyPrefix,
    instance,
    errors,
    propInfo,
    onInputChanged,
    disabled
) {
    const propName = propInfo.name;
    const required = propInfo.required === true;
    const readOnly = disabled || propInfo.readOnly === true;
    const showLabel = propInfo.showLabel !== false;

    let { min } = propInfo;
    min = !T.IsNum(min) ? '' : min;
    let { max } = propInfo;
    max = !T.IsNum(max) ? '' : max;
    const step = propInfo.step || 1;

    let value = instance[propName] || '';

    const error = errors ? errors[propName] : undefined;
    return (
        <React.Fragment>
            {
                showLabel && buildInputLabel(propInfo.label, required, propInfo.unit, propName)
            }
            <Input
                value={value}
                id={propName}
                name={propName}
                invalid={T.IsDefined(error)}
                required={required}
                key={keyPrefix + propName}
                readOnly={readOnly} disabled={readOnly}
                type='number'
                min={min}
                max={max}
                step={step}
                onChange={(evt) => {
                    let val = evt.target.value;
                    onInputChanged(evt, propName, val);
                }}
            />
            {error && buildFormFeedback(propName, error)}
        </React.Fragment>
    );
}

export function buildInputCheckBox(
    keyPrefix,
    instance,
    errors,
    propInfo,
    onInputChanged,
    disabled
) {
    const propName = propInfo.name;
    const required = propInfo.required === true;
    const readOnly = disabled || propInfo.readOnly === true;
    const showLabel = propInfo.showLabel !== false;

    const value = T.DefaultBool(instance[propName], false);

    const error = errors ? errors[propName] : undefined;
    return (
        <React.Fragment>
            {
                showLabel && buildInputLabel(propInfo.label, false, propInfo.unit, propName, required)
            }
            <Input
                checked={value}
                id={propName}
                name={propName}
                className='form-control'
                invalid={T.IsDefined(error)}
                required={required}
                key={keyPrefix + propName}
                readOnly={readOnly}
                type='checkbox'
                onChange={(evt) => {
                    onInputChanged(evt, propName, evt.target.checked);
                }}
            />
            {error && buildFormFeedback(propName, error)}
        </React.Fragment>
    );
}

export function buildInputCheckBoxInline(
    keyPrefix,
    instance,
    errors,
    { name, label, readOnly },
    onInputChanged,
    disabled
) {
    const value = instance[name];
    const error = errors ? errors[name] : undefined;
    return buildInputCheckBoxInlineRaw(keyPrefix,
        { name, value, label, error, readOnly, disabled, strongLabel: true },
        onInputChanged);
}

export function buildInputCheckBoxInlineRaw(
    keyPrefix,
    { name, value, label, error, strongLabel, readOnly, disabled },
    onInputChanged
) {
    const propName = name;
    const isReadOnly = disabled || readOnly === true;
    const sId = `${keyPrefix}-${propName}`;
    return (
        <React.Fragment>
            <Input
                checked={value}
                id={sId}
                name={propName}
                className='mr-2 me-2'
                invalid={T.IsDefined(error)}
                key={keyPrefix + propName}
                readOnly={isReadOnly}
                disabled={isReadOnly}
                type='checkbox'
                onChange={(evt) => {
                    onInputChanged(evt, propName, evt.target.checked);
                }}
            />
            {buildInputLabel(label, false, undefined, sId, strongLabel)}
        </React.Fragment>
    );
}

// here for selecting input and maybe setting
export function buildInputSelect(
    keyPrefix,
    instance,
    errors,
    { name, label, required, readOnly, showLabel, unit },
    options,
    onInputChanged,
    disabled,
    addInvalidOption = false,
    sortOptions = false
) {
    const error = errors
        ? errors[name]
        : instance.errors
            ? instance.errors[name]
            : undefined; // Errors can be passed in separately or as part of the state
    const propLabel = showLabel !== false ? label : undefined;
    return buildInputSelectRaw(keyPrefix, instance[name], error,
        { name, label: propLabel, required, readOnly, showLabel, unit },
        options,
        onInputChanged,
        disabled,
        addInvalidOption,
        sortOptions
    );
}
export function buildInputSelectRaw(
    keyPrefix,
    value,
    error,
    { name, label, required, readOnly, unit, title },
    options,
    onInputChanged,
    disabled,
    addInvalidOption = false,
    sortOptions = false
) {
    const propName = name;
    const isRequired = required === true;
    const isReadOnly = disabled || readOnly === true;
    if (!options || !Array.isArray(options)) {
        options = [];
    } else {
        options = options.map((x) => {
            let id = x.id
                || x.Id;
            let name = T.DefaultString(x.name, T.EmptyString)
                || T.DefaultString(x.Name, T.EmptyString);

            id = T.IsDefined(id) ? id : name;
            name = T.IsDefined(name) ? name : undefined;

            return {
                id,
                name
            };
        });
        if (sortOptions) {
            options.sort(stringComparer);
        }

        if (addInvalidOption && options.length > 0) {
            options.unshift({ id: undefined, name: '' });
        }
    }

    return (
        <React.Fragment>
            {label && buildInputLabel(label, isRequired, unit, propName)}
            <Input
                className="form-control"
                // className="dropdownFeedbackAdjustment form-control"
                value={value || ''}
                id={propName}
                name={propName}
                type="select"
                key={`${keyPrefix}${propName}`}
                invalid={T.IsDefined(error)}
                required={isRequired}
                disabled={isReadOnly}
                title={title || label}
                onChange={(evt) =>
                    onInputChanged(evt, propName, evt.target.value)
                }
            >
                {
                    options.map((x) => (
                        <option
                            key={`${keyPrefix}${propName}-option-${x.id || x.Id}`}
                            value={x.id || x.Id}
                        >
                            {x.name || x.Name}
                        </option>
                    ))
                }
            </Input>
            {error && buildFormFeedback(propName, error)}
        </React.Fragment>
    );
}

export function buildInputString(
    keyPrefix,
    state,
    errors,
    { name: propName, required, readOnly, showLabel, label, unit, length },
    onInputChanged,
    disabled,
    inputProps,
    valueFormatter = undefined,
    buttons = undefined
) {
    return buildInputStringRaw(
        keyPrefix,
        propName,
        state[propName],
        valueFormatter,
        errors ? errors[propName] : undefined,
        { required, readOnly, showLabel, label, unit, length },
        onInputChanged,
        disabled,
        inputProps,
        buttons);
}

export function buildInputStringRaw(
    keyPrefix,
    name,
    value,
    valueFormatter,
    error,
    { required, readOnly, showLabel, label, unit, length },
    onInputChanged,
    disabled,
    inputProps,
    buttons = undefined
) {
    const isRequired = required === true;
    const isReadOnly = disabled || readOnly === true;
    const labelVisible = showLabel !== false;

    let val = T.IsFunc(valueFormatter)
        ? valueFormatter()
        : value;
    val = T.IsDefined(val) ? val : '';

    const len = `${length}`;

    const inputLabel = (
        <React.Fragment>
            {labelVisible && buildInputLabel(label, isRequired, unit, name)}
        </React.Fragment>);
    const input = (
        <Input
            value={val}
            type="text"
            key={keyPrefix + name + '-input'}
            id={name}
            name={name}
            invalid={T.IsDefined(error)}
            required={required}
            props={inputProps}
            readOnly={isReadOnly} disabled={isReadOnly}
            maxLength={len}
            onChange={(evt) =>
                onInputChanged(evt, name, evt.target.value)
            }
        />);
    const inputFeedback = (
        <React.Fragment>
            {error && buildFormFeedback(name, error)}
        </React.Fragment>);

    if (T.IsDefined(buttons) || T.IsArrayNonEmpty(buttons)) {
        const actions = T.IsArray(buttons) ? buttons : [buttons];
        return (
            <React.Fragment>
                {inputLabel}
                <InputGroup>
                    {input}
                    {
                        renderOptionsCell(keyPrefix + name + '-btn', actions)
                    }
                    {inputFeedback}
                </InputGroup>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {inputLabel}
            {input}
            {inputFeedback}
        </React.Fragment>
    );
}

export function buildInputEmail(
    keyPrefix,
    state,
    errors,
    { name: propName, required, readOnly, showLabel, label, unit, length },
    onInputChanged,
    disabled,
    inputProps,
    valueFormatter = undefined,
    buttons = undefined
) {
    return buildInputEmailRaw(
        keyPrefix,
        propName,
        state[propName],
        valueFormatter,
        errors ? errors[propName] : undefined,
        { required, readOnly, showLabel, label, unit, length },
        onInputChanged,
        disabled,
        inputProps,
        buttons);
}

export function buildInputEmailRaw(
    keyPrefix,
    name,
    value,
    valueFormatter,
    error,
    { required, readOnly, showLabel, label, unit, length },
    onInputChanged,
    disabled,
    inputProps,
    buttons = undefined
) {
    const isRequired = required === true;
    const isReadOnly = disabled || readOnly === true;
    const labelVisible = showLabel !== false;

    let val = T.IsFunc(valueFormatter)
        ? valueFormatter()
        : value;
    val = T.IsDefined(val) ? val : '';

    const len = `${length}`;

    const inputLabel = (
        <React.Fragment>
            {labelVisible && buildInputLabel(label, isRequired, unit, name)}
        </React.Fragment>);
    const input = (
        <Input
            value={val}
            type='email'
            key={keyPrefix + name + '-input'}
            id={name}
            name={name}
            // invalid={T.IsDefined(error)}
            required={required}
            props={inputProps}
            readOnly={isReadOnly} disabled={isReadOnly}
            maxLength={len}
            onChange={(evt) =>
                onInputChanged(evt, name, evt.target.value)
            }
        />);
    const inputFeedback = (
        <React.Fragment>
            {error && buildFormFeedback(name, error)}
        </React.Fragment>);

    if (T.IsDefined(buttons) || T.IsArrayNonEmpty(buttons)) {
        const actions = T.IsArray(buttons) ? buttons : [buttons];
        return (
            <React.Fragment>
                {inputLabel}
                <InputGroup>
                    {input}
                    {
                        renderOptionsCell(keyPrefix + name + '-btn', actions)
                    }
                    {inputFeedback}
                </InputGroup>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {inputLabel}
            {input}
            {inputFeedback}
        </React.Fragment>
    );
}

export function buildInputAutoComplete(
    keyPrefix,
    state,
    errors,
    { name: propName, required, readOnly, showLabel, label, unit, length },
    options,
    onInputChanged,
    disabled
) {
    return buildInputAutoCompleteRaw(
        keyPrefix,
        propName,
        state[propName],
        errors ? errors[propName] : undefined,
        { required, readOnly, showLabel, label, unit, length },
        options,
        onInputChanged,
        disabled);
}

export function buildInputAutoCompleteRaw(
    keyPrefix,
    name,
    value,
    error,
    { required, readOnly, showLabel, label, unit, length },
    options,
    onInputChanged,
    disabled
) {
    const isRequired = T.DefaultBool(required, false);
    const isReadOnly = disabled || T.DefaultBool(readOnly, false);
    const labelVisible = showLabel !== false;

    let val = value;
    val = T.IsDefined(val) ? val : '';

    const inputLabel = (
        <React.Fragment>
            {labelVisible && buildInputLabel(label, isRequired, unit, name)}
        </React.Fragment>);
    const input = (
        <Autocomplete
            id={name}
            name={name}
            key={keyPrefix + name + '-input'}
            options={options}
            clearOnEscape
            freeSolo
            invalid={error}
            readOnly={isReadOnly} disabled={isReadOnly || readOnly}
            required={isRequired}
            inputValue={val}
            onInputChange={(evt, newVal) => {
                onInputChanged(evt, name, newVal);
            }}
            sx={{
                "& .MuiOutlinedInput-root": {
                    padding: "0px!important",
                },
            }}
            renderInput={(params) =>
                <TextField variant="outlined"
                    inputProps={{
                        style: {
                            paddingTop: 4,
                            paddingBottom: 4
                        },
                    }}
                    error={T.IsDefined(error)}
                    {...params}
                />}
        />);
    const inputFeedback = (
        <React.Fragment>
            {error && buildFormFeedback(name, error)}
        </React.Fragment>);

    return (
        <React.Fragment>
            {inputLabel}
            {input}
            {inputFeedback}
        </React.Fragment>
    );
}

export function buildInputTextArea(
    keyPrefix,
    state,
    errors,
    { name: propName, required, readOnly, showLabel, label, unit, length, lineCnt },
    onInputChanged,
    disabled,
    inputProps
) {
    return buildInputTextAreaRaw(
        keyPrefix,
        propName,
        state[propName],
        errors ? errors[propName] : undefined,
        onInputChanged,
        { label, required, readOnly, showLabel, unit },
        length, lineCnt,
        disabled,
        inputProps);
}

/**
 * @param {*} keyPrefix 
 * @param {*} name [required] field name
 * @param {*} value [optional] field value
 * @param {*} error [optional] error string
 * @param {*} onInputChanged 
 * @param {*} optionalProps (label, disabled, required, readOnly, showLabel, unit)
 * @param {*} length [optional] max string length
 * @param {*} lineCnt [optional] Default is 5
 * @param {*} inputProps [optional]
 */
export function buildInputTextAreaRaw(
    keyPrefix,
    name,
    value,
    error,
    onInputChanged,
    { label, required, readOnly, showLabel, unit },
    length = undefined, lineCnt = 5,
    disabled,
    inputProps = undefined
) {
    const isRequired = required === true;
    const isReadOnly = disabled || readOnly === true;
    const labelVisible = !T.stringIsNullOrEmpty(label) && showLabel !== false;
    const val = T.IsDefined(value) ? value : '';

    const len = T.IsNum(length) ? `${length}` : length;

    const inputLabel = (
        <React.Fragment>
            {labelVisible && buildInputLabel(label, isRequired, unit, name)}
        </React.Fragment>);
    const input = (
        <Input
            value={val}
            type="textarea"
            key={keyPrefix + name + '-inputText'}
            id={name}
            name={name}
            invalid={T.IsDefined(error)}
            required={isRequired}
            props={inputProps}
            readOnly={isReadOnly} disabled={isReadOnly}
            maxLength={len}
            rows={lineCnt || 3}
            onChange={(evt) =>
                onInputChanged(evt, name, evt.target.value)
            }
        />);
    const inputFeedback = (
        <React.Fragment>
            {error && buildFormFeedback(name, error)}
        </React.Fragment>);
    return (
        <React.Fragment>
            {inputLabel}
            {input}
            {inputFeedback}
        </React.Fragment>
    );
}

export function buildInputDate(
    keyPrefix,
    state,
    errors,
    { name, label, required, readOnly, showLabel },
    onInputChanged,
    disabled
) {
    const propName = name;
    const isRequired = required === true;
    const isReadOnly = disabled || readOnly === true;

    let value = state[propName];
    value = T.IsDefined(value) ? new Date(value).toISOString().split('T')[0] : '';

    const error = errors ? errors[propName] : undefined;

    return (
        <React.Fragment>
            {
                (showLabel !== false) && buildInputLabel(label, isRequired)
            }
            <Input type="date"
                value={value}
                key={keyPrefix + name + '-inputDate'}
                id={`${keyPrefix}_${propName}`}
                name={name}
                invalid={T.IsDefined(error)}
                required={isRequired}
                readOnly={isReadOnly} disabled={isReadOnly}
                onChange={(evt) =>
                    onInputChanged(evt, name, evt.target.value)
                }
            />
            {
                error &&
                <p style={{ color: '#f44336', fontSize: '80%', marginBottom: '.5rem' }}>
                    {error}
                </p>
            }
        </React.Fragment>
    );
}

export function buildInputSsn(
    keyPrefix,
    state,
    errors,
    { name, label, required, readOnly, showLabel, onBlur },
    onInputChanged,
    disabled
) {
    return buildInputPattern('999-99-9999', '#', keyPrefix, state, errors,
        { name, label, required, readOnly, showLabel, onBlur },
        onInputChanged, disabled);
}

export function buildInputPhone(
    keyPrefix,
    state,
    errors,
    { name, label, required, readOnly, showLabel, onBlur },
    onInputChanged,
    disabled
) {
    return buildInputPattern('999-999-9999', ' ', keyPrefix, state, errors,
        { name, label, required, readOnly, showLabel, onBlur },
        onInputChanged, disabled);
}

export function buildInputZip5(
    keyPrefix,
    state,
    errors,
    { name, label, required, readOnly, showLabel, onBlur },
    onInputChanged,
    disabled
) {
    return buildInputPattern('99999', ' ', keyPrefix, state, errors,
        { name, label, required, readOnly, showLabel, onBlur },
        onInputChanged, disabled);
}

const buildInputPattern = (mask, maskChar,
    keyPrefix,
    state,
    errors,
    { name, label, unit, required, readOnly, showLabel, onBlur },
    onInputChanged,
    disabled
) => {
    const propName = name;
    const isRequired = required === true;
    const isReadOnly = disabled || readOnly === true;
    const labelVisible = showLabel !== false;

    let value = state[propName];
    value = T.DefaultString(value, '');

    const error = errors ? errors[propName] : undefined;
    const invalid = T.IsDefined(error) ? 'is-invalid' : '';
    return (
        <React.Fragment>
            {
                labelVisible && buildInputLabel(label, isRequired, unit)
            }
            <InputMask mask={mask} maskChar={maskChar}
                className={`form-control ${invalid}`}
                value={value}
                disabled={isReadOnly}
                required={isRequired}
                inputRef={(e) => {
                    if (T.IsDefined(e)) {
                        e.onkeydown = (evt) => {
                            if (evt.code === 'Enter' && T.IsFunc(onBlur)) {
                                onBlur();
                            }
                        }
                    }
                }}
                onChange={(evt) =>
                    onInputChanged(evt, propName, evt.target.value)
                }
                onBlur={(evt) => { if (T.IsFunc(onBlur)) onBlur() }}
            />
            {
                error &&
                <p style={{ color: '#f44336', fontSize: '80%', marginBottom: '.5rem' }}>
                    {error}
                </p>
            }
        </React.Fragment>
    );
}

export function InputSecret({ maxLength, value, required, onInputChange }) {
    const [val, setVal] = useState(value || '');
    return (
        <Input
            value={val}
            type="password"
            required={required}
            maxLength={maxLength || 32}
            onChange={(evt) => {
                const newVal = evt.target.value;
                setVal(newVal);
                if (T.IsFunc(onInputChange)) {
                    onInputChange(newVal);
                }
            }}
        />
    );
}

export function InputSelect({ name, options, value, onInputChange }) {
    const [val, setVal] = useState(value || '');
    return (
        <Input
            className="dropdownFeedbackAdjustment form-control"
            value={val}
            type="select"
            onChange={(evt) => {
                const newVal = evt.target.value;
                setVal(newVal);
                if (T.IsFunc(onInputChange)) {
                    onInputChange(newVal);
                }
            }}
        >
            {
                options.map((x) => (
                    <option
                        key={`${name}-option-${x.id || x.Id}`}
                        value={x.id || x.Id}
                    >
                        {x.name || x.Name}
                    </option>
                ))
            }
        </Input>
    );
}

/**
 * Return true if a field/property of object is set, else false
 * @param {any} errors associative array for displaying the error
 * @param {any} instance object to be checked
 * @param {string} fieldName name of the object field/property to be checked
 * @param {string} fieldDisplayName optional display name for the field
 */
export function checkRequired(errors, instance, fieldName, fieldDisplayName) {
    const field = instance[fieldName];

    if (T.IsBool(field)) return true;

    if (!T.IsDefined(field) || (T.IsString(field) && field.trim().length === 0)) {
        var name = !T.IsDefined(fieldDisplayName)
            ? 'This field'
            : fieldDisplayName;
        var msg = `${name} is required`;

        errors[fieldName] = msg;
        return false;
    }
    return true;
}

export function validateNumericField(
    errors,
    instance,
    propInfo,
    fieldDisplayName
) {
    const { name, required, min, max, minExclusive, maxExclusive } = propInfo;

    if (required === true
        && !checkRequired(errors, instance, name, fieldDisplayName)
    ) {
        return false;
    }
    const val = parseFloat(instance[name]);

    if (T.IsNum(min)) {
        const minVal = parseFloat(min);
        if (minExclusive && val <= minVal) {
            errors[name] = `Value must be greater than ${min}`;
            return false;
        }
        if (min < minVal) {
            errors[name] = `Value must be equals or greater than ${min}`;
            return false;
        }
    }
    if (T.IsNum(max)) {
        const maxVal = parseFloat(max);
        if (maxExclusive && val >= maxVal) {
            errors[name] = `Value must be less than ${max}`;
            return false;
        }
        if (max > maxVal) {
            errors[name] = `Value must be equals or less than ${max}`;
            return false;
        }
    }
    return true;
}

// Given a field, return an object containing if the value is valid or invalid.
// If 'validated' is false, then it will be considered as "not yet validated,"
// and both 'valid' and 'invalid' will be false.
export function isRequiredValid(fieldValue, validated) {
    const isValid = T.IsDefined(fieldValue) && fieldValue !== '';

    return {
        valid: isValid && validated,
        invalid: !isValid && validated,
    };
}

export function renderFetchStatus(active, error, errorColor = undefined) {
    return active ? (
        <div>
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
            <FormText
                color={EColor.Secondary}
                style={{ display: 'inline-block' }}
                className="ml-2"
            >
                Loading...
            </FormText>
        </div>
    ) : (
        error
        && <FormText color={errorColor || EColor.Warning}>{error}</FormText>
    );
}
