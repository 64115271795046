import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
    buildInputStringRaw,
    buildInputTextAreaRaw
} from '../utils/EditorInputUtil';
import * as T from '../utils/TypeUtil';
import '../general/css/general.css';

export const ETextEditorMode = {
    TEXT: 'TEXT',
    TEXTAREA: 'TEXTAREA',
};

/**
 * @param {*} keyPrefix 
 * @param {*} name [required] field name
 * @param {*} label [optional] field label
 * @param {*} value [optional] field value
 * @param {*} error [optional] error string
 * @param {ETextEditorMode} mode [optional] editor mode. Default to 'TEXT'
 * @param {*} onInputChanged 
 * @param {*} disabled [optional] Default is 'false'
 * @param {*} required [optional] Default is 'false'
 * @param {*} readOnly [optional] Default is 'false' 
 * @param {*} showLabel [optional] Default is 'true' iff label is set
 * @param {*} unit [optional]
 * @param {*} length [optional] max string length
 * @param {*} lineCnt [optional] Default is 5
 * @param {*} inputProps [optional]
 * @param {boolean} useValueFromInputChanged [optional]
 */
export function TextEditor({ value: inValue, onInputChanged,
    keyPrefix,
    name,
    error,
    required,
    readOnly,
    showLabel, label, unit,
    length, lineCnt,
    inputProps,
    disabled,
    mode,
    useValueFromInputChanged
}) {

    const [input, setInput] = useState(inValue);

    useEffect((() => {
        setInput(inValue);

    }), [inValue]);

    const handleInputChanged = (evt, field, fieldVal) => {
        if (T.IsFunc(onInputChanged)) {
            onInputChanged(evt, field, fieldVal);
        }
        if (T.DefaultBool(useValueFromInputChanged, false)) {
            setInput(fieldVal);
        }
    }

    switch (mode) {
        case ETextEditorMode.TEXTAREA: {
            return buildInputTextAreaRaw(keyPrefix, name, input, error,
                handleInputChanged,
                { label, required, readOnly, showLabel, unit },
                length,
                lineCnt,
                disabled,
                inputProps
            );
        }
        default: {
            return buildInputStringRaw(keyPrefix, name, input,
                undefined, //valueFormatter
                error,
                { label, required, readOnly, showLabel, unit, length },
                handleInputChanged,
                disabled
            );
        }
    }
}

TextEditor.propTypes = {
    keyPrefix: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.string,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    showLabel: PropTypes.bool,
    label: PropTypes.string,
    unit: PropTypes.string,
    length: PropTypes.number,
    /** type of editor. Default is 'TEXT' */
    mode: PropTypes.string,
    lineCnt: PropTypes.number,
    onInputChanged: PropTypes.func,
    inputProps: PropTypes.object,
    disabled: PropTypes.bool
}
